import { useState, useEffect } from "react";
import calendaricon from "./calendar.svg";
const UserDate = () => {
  const [date, SetDate] = useState(new Date());
  useEffect(() => {
    SetDate(new Date());
  }, []);

  return (
    <div className="user-date">
      <p>
        <img src={calendaricon} alt="calendar" />
        {date.getDate()} {date.toLocaleDateString("en-us", { month: "long" })}{" "}
        {date.getFullYear()}
      </p>
    </div>
  );
};

export default UserDate;
