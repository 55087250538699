import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [], // Initial data array
};

const testMasterCountSlice = createSlice({
  name: "testMasterCount",
  initialState,
  reducers: {
    setTestMasterCount: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setTestMasterCount } = testMasterCountSlice.actions;
export default testMasterCountSlice.reducer;
