const NameIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
      <g>
        <g id="Group_6">
          <path
            id="Path_21"
            d="M85,88.1c13.4-0.1,24.2-10.9,24.1-24.3c-0.1-13.4-10.9-24.2-24.3-24.1c-13.3,0.1-24.1,10.8-24.1,24.1
			c-0.1,13.4,10.7,24.2,24,24.3C84.8,88.1,84.9,88.1,85,88.1z M85,47.2c9.2,0,16.7,7.4,16.8,16.6c-0.1,9.3-7.8,16.7-17.1,16.5
			c-9.1-0.1-16.4-7.5-16.5-16.5C68.3,54.6,75.8,47.2,85,47.2z"
          />
          <path
            id="Path_22"
            d="M101.4,90.5c-1.5-0.4-3,0-4.1,1.2L85,105.7L72.7,91.6c-1-1.1-2.6-1.6-4.1-1.2c-17.6,5.8-28.9,20-28.9,36.1
			c0,2.1,1.7,3.9,3.9,3.9h83c2.1,0,3.9-1.7,3.9-3.9C130.4,110.5,119,96.2,101.4,90.5z M47.6,122.9c2.1-11.4,10.2-20.7,21.2-24.3
			L82.3,114c1.3,1.5,3.6,1.7,5.1,0.5c0.2-0.1,0.3-0.3,0.5-0.5l13.5-15.4c10.9,3.7,19,13,21.2,24.3H47.6z"
          />
        </g>
      </g>
    </svg>
  );
};

export default NameIcon;
