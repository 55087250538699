import { Container, Row, Col } from "react-bootstrap";
import DashboardPercentageIcon from "./DashBoardPercentageIcon";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setRegistrationPendingCount } from "../../features/dashboard/RegistrationPendingCountSlice";
import { baseURL } from "../../../src/constants";

const PendingRequests = () => {
  const dispatch = useDispatch();
  const registrationPendingCount = useSelector((state) => state.registrationPending.count);

  useEffect(() => {
    // Fetch the registration count from your API endpoint
    // Replace the following with your actual API call
    const fetchRegistrationPendingCount = async () => {
      try {
        const response = await fetch(`${baseURL}api/registration_pending_count/`);
        const data = await response.json();
        dispatch(setRegistrationPendingCount(data.registration_pending_count));
      } catch (error) {
        console.error("Error fetching registration pending count:", error);
      }
    };

    fetchRegistrationPendingCount();
  }, [dispatch]);

  return (
    <div className="dashboard-box">
      <Container fluid>
        <Row>
          <Col xxl={3} xl={3} lg={3} md={12} sm={12}>
            <DashboardPercentageIcon />
          </Col>
          <Col xxl={9} xl={9} lg={9} md={12} sm={12}>
            <p>Pending Requests:</p>
            <h2>{registrationPendingCount}</h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default PendingRequests;
