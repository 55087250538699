import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0, // Initial count value
};

const registrationPendingCountSlice = createSlice({
  name: "registrationPending",
  initialState,
  reducers: {
    setRegistrationPendingCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { setRegistrationPendingCount } = registrationPendingCountSlice.actions;
export default registrationPendingCountSlice.reducer;
