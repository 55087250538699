import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { setRecentlyActiveUser } from "../../features/dashboard/RecentlyActiveUserSlice";
import { baseURL } from "../../../src/constants";
import DashBoardTickIcon from "./DashBoardTickIcon";

const RecentActiveExaminers = () => {
  const dispatch = useDispatch();
  const recentlyActiveUser = useSelector((state) => state.recentlyActiveUser.data);

  useEffect(() => {
    const fetchRecentUserData = async () => {
      try {
        const response = await fetch(`${baseURL}api/active_users/`);
        const data = await response.json();
        console.log("Received ----", data);
        dispatch(setRecentlyActiveUser(data.active_user_names));
      } catch (error) {
        console.error("Error fetching recently added users:", error);
      }
    };

    fetchRecentUserData();
  }, [dispatch]);

  return (
    <div className="dashboard-box">
      <Container fluid>
        <Row>
          <Col xxl={3} xl={3} lg={3} md={12} sm={12}>
            <DashBoardTickIcon />
          </Col>
          <Col xxl={9} xl={9} lg={9} md={12} sm={12}>
            <p className="fs-6 fw-bold text-uppercase mb-3">Recent Active Audiologist:</p>
            {Array.isArray(recentlyActiveUser) &&
              recentlyActiveUser.map((name, index) => (
                <div key={index} className="d-flex align-items-center mb-3 justify-content-between">
                <div className="col-9">
                  <p className="mb-0" style={{ fontSize: '15px' }}>{name}</p>
                </div>
              </div>
              ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default RecentActiveExaminers;