import { render } from "@testing-library/react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Button, Image, Stack, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PlayButtonIcon from "../tests/PlayButtonIcon";

import {
  FETCH_LEVEL,
  FETCH_LEVELS,
  UPDATE_LEVEL_NAME,
} from "../../features/tests/testSlice";
import QuestionsModal from "./QuestionsModal";
import { BsXLg } from "react-icons/bs";

export function TestLevel(props) {
  const dispatch = useDispatch();
  const l_levels = (state) => state.test.currentLevels;
  const levels = useSelector(l_levels);
  const q_questions = (state) => state.media.questions;
  const questions = useSelector(q_questions);

  const [levelQ, setLevelQ] = useState({
    level_name: "",
    qids: [],
  });

  const qbRef = useRef(null);
  const [qbStatus, setQBStatus] = useState(false);
  function handleAddQuestions(e) {
    e.preventDefault();
    setQBStatus(true);
    qbRef.current.className = "d-block";
  }
  function handleRemoveLevel(id) {
    props?.handleRemoveLevel(id);
  }
  function handleTestNameChange(e) {
    e.preventDefault();
    setLevelQ((levelQ) => ({ ...levelQ, ...{ level_name: e.target.value } }));
    dispatch(UPDATE_LEVEL_NAME({ id: props?.id, level_name: e.target.value }));
  }
  useEffect(() => {
    let currentLevel = dispatch(FETCH_LEVEL());
    //
    if (props?.qbStatus === false && currentLevel !== props?.levelId)
      setQBStatus(false);

    // if(props?.name !== ""){
    //     setLevelQ(levelQ=>({...levelQ, ...{level_name: props?.name}}))
    //    // alert(JSON.stringify(levelQ))
    // }
    if (props?.qids?.length > 0) {
      dispatch(FETCH_LEVELS());
      //alert(JSON.stringify(levels))
    }

    return () => { };
  }, [dispatch, props]);

  return (
    <>
      <li key={props.id} className="mb-4">
        <Form.Group className="margin-bottom-20">
          <Row>
            {/* <Stack direction="horizontal" gap={3}> */}
            {/* <div className="w-75 me-auto "> */}

            {/* </div> */}
            <Col xxl={8} xl={8} lg={8} md={6}>
              {props?.type == 1 && (
                <>
                  <Form.Label>
                    Level {props.id}
                    <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props?.name}
                    onBlur={(e) => {
                      handleTestNameChange(e);
                    }}
                  ></Form.Control>
                </>
              )}

            </Col>
            <Col xxl={2} xl={2} lg={2} md={4} className="addQuestions">
              <label class="invisible form-label">label</label>
              <div>
                <Button
                  type="button"
                  className="test-add-level"
                  onClick={(e) => {
                    handleAddQuestions(e);
                  }}
                >
                  Add Questions
                </Button>
              </div>
            </Col>
            <Col xxl={2} xl={2} lg={2} md={2} className="removeLevelS">
              {props?.showRemoveButton && (
                <>
                  <label class="invisible form-label">label</label>
                  <div>
                    <Button
                      type="button"
                      className="remove-test-level"
                      onClick={(e) => {
                        handleRemoveLevel(props?.id);
                      }}
                    >
                      <BsXLg />
                    </Button>
                  </div>
                </>
              )}
            </Col>

            {/* </Stack> */}
          </Row>
        </Form.Group>
        <Form.Group>
          <div className="border readonly p-2 rounded">
            <Row>
              {levels
                .filter((l) => l.id === props?.id)
                .map((lv) => {
                  return lv.qids.map((q) => {
                    return questions.filter((item)=>item.qtype == props?.type)
                      .filter((ql) => ql.qid === parseInt(q))
                      .map((i) => {
                        return (
                          <>
                            {props?.type == 1 && (
                              <Image
                                src={i?.image}
                                className="img img-responsive p-2"
                                key={i.qid}
                                style={{
                                  minHeight: "150px",
                                  minWidth: "150px",
                                  maxHeight: "150px",
                                  maxWidth: "150px",
                                }}
                              />
                            )}

                            {props?.type == 2 && (
                              <Col xxl={6} lg={6} md={12}>
                                <div className="border audioOnlyQuestionBankSection mb-2">
                                  <PlayButtonIcon />
                                  {i?.audio_title}
                                </div>
                              </Col>
                            )}
                          </>
                        );
                      });
                  });
                })}
            </Row>
          </div>
          <div className="d-none" ref={qbRef}>
            <QuestionsModal
              qbStatus={qbStatus}
              setQBStatus={setQBStatus}
              levelId={props?.id}
              levelName={props?.name || levelQ.level_name}
              type={props?.type}
            />
          </div>
        </Form.Group>
      </li>
    </>
  );
}
