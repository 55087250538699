import {baseURL}  from "../../constants"

export function getAllQuestions() {

  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
 // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
          method: 'POST', 
          headers: {'Content-Type': 'application/json'},          
          body:null
      }
   
    return new Promise( (resolve, reject) =>{
        fetch(baseURL + "question/getall/", requestOptions).then(response => {response.json()
          .then(
           (res) => {                 
              setTimeout(() => resolve({data : res}),500)
       })}
    ).catch((err) => reject({data: err}))})
      
  
}

export function addQuestion(questionObj= {}) {

    //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
   // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
    const requestOptions = {
            method: 'POST', 
            headers: {},          
            body:questionObj
        }
      console.log(JSON.stringify(requestOptions))
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "question/add/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }

  export function deleteQuestion(questionObj= {}) {
    const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(questionObj)
            
        }
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "question/delete/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }


  export function updateQuestion(questionObj= {}) {
    const requestOptions = {
            method: 'POST',
            headers: { },
            body:questionObj
            
        }
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "question/update/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }