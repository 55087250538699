import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTermsModal: false,
  isPrivacyModal: false,
};

export const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    FETCH_CONTENT_TERMS: (state) => {
      state.isTermsModal = true;
    },
    FETCH_CONTENT_TERMS_HIDE: (state) => {
      state.isTermsModal = false;
    },
    FETCH_CONTENT_PRIVACY: (state) => {
      state.isPrivacyModal = true;
    },
    FETCH_CONTENT_PRIVACY_HIDE: (state) => {
      state.isPrivacyModal = false;
    },
  },
});

export const {
  FETCH_CONTENT_TERMS,
  FETCH_CONTENT_TERMS_HIDE,
  FETCH_CONTENT_PRIVACY,
  FETCH_CONTENT_PRIVACY_HIDE,
} = termsSlice.actions;
export default termsSlice.reducer;
