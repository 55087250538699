import React from 'react';
import QRCode from 'qrcode.react';

const PaymentQRCode = ({ upiId, name,registrationAmount }) => {
  const uri = `upi://pay?pa=${upiId}&pn=${name}&cu=INR&am=${registrationAmount}`;

  return (
    <div>
      <QRCode value={uri} size={200} />
      <p>Scan to pay {name}</p>
    </div>
  );
};

export default PaymentQRCode;
