import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0, // Initial count value
};

const registrationApprovedCountSlice = createSlice({
  name: "registrationApproved",
  initialState,
  reducers: {
    setRegistrationApprovedCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { setRegistrationApprovedCount } = registrationApprovedCountSlice.actions;
export default registrationApprovedCountSlice.reducer;
