import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Image,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsCheck2 } from "react-icons/bs";

import QuestionBank from "./QuestionBank";
import { useDispatch, useSelector } from "react-redux";
import { TestLevel } from "./Levels";
import {
  addTestAsync,
  CLEAR_LEVELS,
  SET_LEVEL,
  REMOVE_LEVELS,
  CLEAR_TEST_MSG,
} from "../../features/tests/testSlice";
import { useRef } from "react";
import { isEqual } from "rsuite/esm/utils/dateUtils";

const AddTestForm = () => {
  const dispatch = useDispatch();
  const q_questions = (state) => state.media.questions;
  const questions = useSelector(q_questions);
  const q_level = (state) => state.test.currentLevels;
  const currLevels = useSelector(q_level);
  const t_message = (state) => state.test.message;
  const message = useSelector(t_message);

  const [currTest, setCurrTest] = useState({
    name: "",
    qtype: 1,
    levels: [],
    trial: {},
  });

  const [testName, setTestName] = useState("");

  const [trialQ, setTrialQ] = useState({
    level_name: "trial",
    qids: [],
  });
  function handleSave(e) {
    e.preventDefault();
    const lvls = [];
    currLevels.forEach((element) => {
      let q = [];
      element.qids.forEach((el) => q.push(parseInt(el)));
      // alert(q)
      lvls.push({ level_name: element?.level_name, qid: q });
    });
    // alert(JSON.stringify(lvls))
    const addTest = {
      name: testName,
      qtype: currTest?.qtype,
      levels: lvls,
      trial: {
        qid: [],
        level_name: testName + "_trial",
      },
    };

    //  alert(JSON.stringify(addTest))

    dispatch(addTestAsync(addTest));
    // setTestName("");
    // setCurrTest((currTest) => ({
    //   ...currTest,
    //   ...{ qtype: 1 },
    // }));
    // setLevels([
    //   {
    //     id: 1,
    //     name: "",
    //   },
    // ]);
    console.log("Test added message");
    //setaddsucesssetModalShow(true);
  }

  const testCancelBtn = useNavigate("");
  const testCancelBtnFunc = () => {
    const testCancelBtnUrl = "/test";
    testCancelBtn(testCancelBtnUrl);
  };
  const [levels, setLevels] = useState([
    {
      id: 1,
      name: "",
    },
  ]);
  const [addLevels, setAddLevels] = useState("");
  const addNewLevel = () => {
    const id = levels.length ? levels[levels.length - 1].id + 1 : 1;
    const addLevel = { id };
    const addLevelField = [...levels, addLevel];
    setLevels(addLevelField);
    dispatch(SET_LEVEL(addLevel));
  };
  function handleRemoveLevel(id) {
    setLevels(levels.filter((item) => item.id != id));
    dispatch(REMOVE_LEVELS(id));
  }
  const addLevelHandler = (e) => {
    e.preventDefault();
    addNewLevel(addLevels);
    setAddLevels("");
  };

  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);
  //const addsucesshandleShow = () => setaddsucesssetModalShow(true);

  useEffect(() => {
    dispatch(CLEAR_LEVELS());
    console.log(message);
    if (message?.message === "Test created successfully") {
      setaddsucesssetModalShow(true);
    }
    const timeId = setTimeout(() => {
      if (message?.message === "Test created successfully") {
        setaddsucesssetModalShow(false);
        dispatch(CLEAR_TEST_MSG());
        testCancelBtnFunc();
      }
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, addsucessmodalshow, message]);

  return (
    <>
      {/* Question Bank Modal*/}

      <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addTestSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addTestPopTextAreaOnSuccess">
            <p>Test has been added sucessfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <Form className="addTestForm">
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12}>
            {/* <h2>Add Test</h2> */}
          </Col>
        </Row>
        <Row>
          <Col xxl={6} xl={6} lg={6} md={6}>
            <Form.Group className="margin-bottom-20">
              <Form.Label>
                Test Type<span>*</span>
              </Form.Label>
              <Form.Select
                type="dropdown"
                value={currTest?.qtype}
                onChange={(e) =>
                  setCurrTest((currTest) => ({
                    ...currTest,
                    ...{ qtype: e.target.value },
                  }))
                }
              >
                <option value={1}>Audio with Image</option>
                <option value={2}>Audio Only</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6}>
            <Form.Group className="margin-bottom-20">
              <Form.Label>
                Test Name<span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={testName}
                onChange={(e) => {
                  setTestName(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <ul>
              {/* level Component */}
              {levels.map((level) => (
                <TestLevel
                  id={level?.id}
                  name={level?.name}
                  type={currTest?.qtype}
                  handleRemoveLevel={handleRemoveLevel}
                  showRemoveButton={level?.id > 1 ? true : false}
                />
              ))}
            </ul>
          </Col>
          {currTest?.qtype == 1 && (
            <>
              <Col xxl={12} xl={12} lg={12} className="text-end">
                <Button
                  type="button"
                  className="test-add-level"
                  onClick={addLevelHandler}
                >
                  Add Level
                </Button>
              </Col>
            </>)}

          {/* <Col xxl={12} xl={12} lg={12} md={12}>
            <Form.Group>
              <Form.Label>
                Add Trial Questions<span>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                value={currTest.trial.qids}
                onChange={(e) =>
                  setTrialQ((trialQ) => ({
                    ...{ trialQ },
                    ...{ qids: e.target.value },
                  }))
                }
                required
              ></Form.Control>
            </Form.Group>
          </Col> */}
          <Col xxl={12} xl={12} lg={12} className={currTest?.qtype == 1 ? "text-end":"text-center"}>
            <Button
              type="button"
              className="btn-cancel"
              onClick={testCancelBtnFunc}
            >
              Cancel
            </Button>
            <Button type="button" className="btn-submit" onClick={handleSave}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddTestForm;
