import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, LOG_OUT } from "../../features/login/loginSlice";
import { Form } from "react-bootstrap";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [errMessage, setErrMessage] = useState();
  const errorMsg = useRef();

  const loginStatus = (state) => state.login.status;
  const status = useSelector(loginStatus);
  const msg = (state) => state.login.message;
  const message = useSelector(msg);

  const [validated, setValidated] = useState(false);

  const userLoginSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (credentials.email == "" || credentials.password == "") {
      return;
    }
    console.log(JSON.stringify(credentials));
    dispatch(loginAsync(credentials));
  };

  const registerForm = () => {
    const registrationForm = "/register";
    navigate(registrationForm);
  };

  const resetForm = () => {
    const passwordResetForm = "/forgotpassword";
    navigate(passwordResetForm);
  };

  useEffect(() => {
    if (status) {
      console.log("use Effect");
      errorMsg.current.className = "d-none";

      return navigate("/dashboard");
    } else {
      setErrMessage(message);
      errorMsg.current.className = "d-block";
      dispatch(LOG_OUT());
      navigate("/login");
    }
  }, [status, navigate, message, dispatch]);

  return (
    <>
      <div className="formTitle">
        <p>Are you an</p>
        <h1>Audiologist!</h1>
      </div>
      <div className="d-none" ref={errorMsg}>
        {" "}
        <p className="text-center " style={{ color: "white" }}>
          {" "}
          {errMessage}
        </p>
      </div>
      <Form
        className="loginForm"
        onSubmit={userLoginSubmit}
        noValidate
        validated={validated}
      >
        <Form.Group className="margin-bottom-20">
          <div className="loginInputIcon">
            <Form.Label>
              Email ID <span>*</span>
            </Form.Label>
            <Form.Control
              type="email"
              required
              onChange={(e) =>
                setCredentials((credentials) => ({
                  ...credentials,
                  ...{ email: e.target.value },
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Email is required
            </Form.Control.Feedback>
            {/* <img src={usericon} alt="usericon" /> */}
          </div>
        </Form.Group>
        <Form.Group className="margin-bottom-20">
          <div className="loginInputIcon">
            <Form.Label>
              Password <span>*</span>
            </Form.Label>
            <Form.Control
              type="password"
              required
              onChange={(e) =>
                setCredentials((credentials) => ({
                  ...credentials,
                  ...{ password: e.target.value },
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Password is required
            </Form.Control.Feedback>
          </div>
        </Form.Group>
        <div className="signInButton">
          <button type="submit">LOGIN</button>
        </div>
        <div className="forgotPassWord">
          <p>
            <span onClick={resetForm}>Forgot password?</span>
          </p>
        </div>
        <div className="registerNow">
          <p>
            Don't have an account?{" "}
            <span onClick={registerForm}>Register Here</span>
          </p>
        </div>
      </Form>
    </>
  );
};
export default LoginForm;
