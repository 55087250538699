import { Container, Row, Col } from "react-bootstrap";
import DashBoardTickIcon from "./DashBoardTickIcon";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setRegistrationApprovedCount } from "../../features/dashboard/RegistrationApprovedCountSlice";
import { baseURL } from "../../../src/constants";

const ApprovedExaminers = () => {
  const dispatch = useDispatch();
  const registrationApprovedCount = useSelector((state) => state.registrationApproved.count);

  useEffect(() => {
    // Fetch the registration count from your API endpoint
    // Replace the following with your actual API call
    const fetchRegistrationApprovedCount = async () => {
      try {
        const response = await fetch(`${baseURL}api/registration_approved_count/`);
        const data = await response.json();
        dispatch(setRegistrationApprovedCount(data.registration_approved_count));
      } catch (error) {
        console.error("Error fetching registration approved count:", error);
      }
    };

    fetchRegistrationApprovedCount();
  }, [dispatch]);

  return (
    <div className="dashboard-box">
      <Container fluid>
        <Row>
          <Col xxl={3} xl={3} lg={3} md={12} sm={12}>
            <DashBoardTickIcon />
          </Col>
          <Col xxl={9} xl={9} lg={9} md={12} sm={12}>
            <p>Approved Audiologist:</p>
            <h2>{registrationApprovedCount}</h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ApprovedExaminers;