const ReportIcon = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <path
          id="Path_23"
          d="M110.1,52.2h-8.7v-2.9c0-3.2-2.6-5.8-5.8-5.8l0,0H72.5c-3.2,0-5.8,2.6-5.8,5.8l0,0v2.9H58
	c-3.2,0-5.8,2.6-5.8,5.8l0,0v60.8c0,3.2,2.6,5.8,5.8,5.8h52.1c3.2,0,5.8-2.6,5.8-5.8V58C115.9,54.8,113.3,52.2,110.1,52.2
	L110.1,52.2z M72.5,49.3h23.2v11.6H72.5V49.3z M110.1,118.8H58V58h8.7v8.7h34.8V58h8.7L110.1,118.8z M79.1,102.8h23.7v7.9H79.1
	V102.8z M67.3,102.8h3.9v7.9h-3.9V102.8z M79.1,91h23.7v3.9H79.1V91z M67.3,91h3.9v3.9h-3.9V91z M79.1,75.2h23.7v7.9H79.1V75.2z
	 M67.3,75.2h3.9v7.9h-3.9V75.2z"
        />
      </svg>
    </>
  );
};

export default ReportIcon;
