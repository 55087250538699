import { Row, Col, Form, Button,Modal } from "react-bootstrap";
import { useState } from "react";
import { BsXLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { useEffect } from "react";
import {
  addQuestionAsync,
  CLEAR_MSG,
  getAllQuestionsAsync,
} from "../../features/media/mediaSlice";

const AddAudioOnly = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const msg = (state) => state.media.message;
  const message = useSelector(msg);

  //Audio Only
  const [audioOnlyField, setAudioOnlyField] = useState("");
  const [audioOnlyTitle, setAudioOnlyTitle] = useState("");

  const [audiofields, setAudioFields] = useState([
    {
      id: 1,
    },
  ]);
  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);

  const [addaudioFields, setAddAudioFields] = useState("");
  const addNewAudioFields = () => {
    const id = audiofields.length
      ? audiofields[audiofields.length - 1].id + 1
      : 1;
    const addAudioField = { id };
    const addNewAudioField = [...audiofields, addAudioField];
    setAudioFields(addNewAudioField);
  };

  const audiodeleteBtnHandler = (id) => {
    const audioOnlydeleteBtn = audiofields.filter(
      (audioOnly) => audioOnly.id !== id
    );
    setAudioFields(audioOnlydeleteBtn);
  };
  const [validated, setValidated] = useState(false);

  const audiofieldHandler = (e) => {
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
      e.preventDefault();
    //   e.stopPropagation();
    // }
    setValidated(true);
    if (
      audioOnlyField == "" ||
      audioOnlyTitle == "" 
    ) {
      return;
    }
    var frm = new FormData();
    frm.append("qtype",2);
    frm.append("audio_title", audioOnlyTitle);
    frm.append("audio", audioOnlyField);
    console.log("audio",frm);
    dispatch(addQuestionAsync(frm));
    dispatch(CLEAR_MSG());
    // addNewAudioFields(addaudioFields);
    // setAddAudioFields("");
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setaddsucesssetModalShow(false);
      dispatch(CLEAR_MSG());
    }, 1000);

    if (message === "Question added successfully") {
      setaddsucesssetModalShow(true);
    }

    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, message]);

  return (
    <>
    <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addNameSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addPopTextAreaOnSuccess">
            <p>Media has been added sucessfully</p>
          </div>
        </Modal.Body>
      </Modal>

      <ul>
        {audiofields.map((audioOnly) => (
          <li key={audioOnly.id}>
            <Form className="addMediaFileForm" onSubmit={audiofieldHandler}  noValidate
            validated={validated}>
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group>
                    <Form.Label>
                      Audio Title<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      onChange={(e) => setAudioOnlyTitle(e.target.value)}
                    ></Form.Control>
                     <Form.Control.Feedback type="invalid">
                    Audio Title is required
                  </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={5}>
                  <Form.Group>
                    <Form.Label>
                      Audio<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      required
                      onChange={(e) => setAudioOnlyField(e.target.files[0])}
                    ></Form.Control>
                     <Form.Control.Feedback type="invalid">
                    Audio file is required
                  </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1}>
                  <Form.Group>
                    <Form.Label className="invisible">label</Form.Label>
                    <Button type="submit" className="btn-submit">
                      Add
                    </Button>
                  </Form.Group>
                </Col>
                {/* <Col xxl={2} xl={2} lg={2} md={2}>
                  <Form.Group>
                    <Form.Label className="invisible">label</Form.Label>
                    <Button
                      type="button"
                      className="btn-delete-icon"
                      onClick={() => audiodeleteBtnHandler(audioOnly.id)}
                    >
                      <BsXLg />
                    </Button>
                  </Form.Group>
                </Col> */}
              </Row>
            </Form>
          </li>
        ))}
      </ul>
      {/* <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
        <Button type="button" className="btn-add" onClick={audiofieldHandler}>
          <BsPlusLg />
          Add Field
        </Button>
      </Col> */}
      {/* <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
        <Button type="button" className="btn-submit">
          Save
        </Button>
      </Col> */}
    </>
  );
};

export default AddAudioOnly;
