import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [], // Initial data array
};

const recentlyAddedUserSlice = createSlice({
  name: "recentlyAddedUser",
  initialState,
  reducers: {
    setRecentlyAddedUser: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setRecentlyAddedUser } = recentlyAddedUserSlice.actions;
export default recentlyAddedUserSlice.reducer;
