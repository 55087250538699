const EditIcon = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <path
          d="M39.7,111.5v18.9h18.9l55.7-55.7L95.4,55.8L39.7,111.5z M128.9,60.1c2-2,2-5.1,0-7.1c0,0,0,0,0,0l-11.8-11.8
	c-2-2-5.1-2-7.1,0c0,0,0,0,0,0l-9.2,9.2l18.9,18.9L128.9,60.1z"
        />
      </svg>
    </>
  );
};

export default EditIcon;
