import logo from "../global-images/spetra-logo.svg";
import "../components/passwordreset/reset.css";
import { Container, Row, Col } from "react-bootstrap";
import CodeConfirmationForm from "../components/passwordreset/CodeConfirmationForm";
const Verification = () => {
  return (
    <>
      <div className="codeVerifiyBackground">
        <Container>
          <Row>
            <Col xxl={3} xl={3} lg={3}></Col>
            <Col xxl={6} xl={6} lg={6}>
              <div className="codeVerifiyBox">
                <img src={logo} alt="spetra-logo" className="spetraResetLogo" />
                <CodeConfirmationForm />
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={3}></Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Verification;
