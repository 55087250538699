import { Table, Row, Col, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { BsCheck2 } from "react-icons/bs";
import { BsXLg } from "react-icons/bs";
import "../../components/users/users.css";
import ViewIcon from "../../global-images/ViewIcon";
import ApproveIcon from "../../global-images/ApproveIcon";
import {
  getAllUsersAsync,
  deleteUserAsync,
  CLEAR_USR_MSG,
  activateUserAsync,
} from "../../features/login/loginSlice";
import { format } from "date-fns";
import { userRegStatus, userRole } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tree } from "rsuite";

const RequestLists = () => {
  // const [viewRequests, setViewRequests] = useState(false);
  // const viewRequestshandleClose = () => setViewRequests(false);
  // const viewRequestshandleShow = () => setViewRequests(true);

  // const [approveRequests, setApproveViewRequests] = useState(false);
  // const approveRequestshandleClose = () => setApproveViewRequests(false);
  // const approveRequestshandleShow = () => setApproveViewRequests(true);

  // const [declineRequests, setDeclineRequests] = useState(false);
  // const declineRequestshandleClose = () => setDeclineRequests(false);
  // const declineRequestshandleShow = () => setDeclineRequests(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const p_profiles = (state) => state.login.users;
  const userDetails = useSelector(p_profiles);
  const msg = (state) => state.login.message;
  const message = useSelector(msg);

  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);

  const userViewFunc = (id) => {
    const userViewPath = "/viewUser/" + id;
    navigate(userViewPath);
  };
  const userActivate = (email) => {
    dispatch(activateUserAsync({ email: email }));
    dispatch(CLEAR_USR_MSG());
  };

  // const userEditFunc = (id) => {
  //   const userEditPath = "/updateUser/" + id;
  //   navigate(userEditPath);
  // };

  useEffect(() => {
    dispatch(getAllUsersAsync());
    //console.log(message)
    const timeId = setTimeout(() => {
      setaddsucesssetModalShow(false);
      dispatch(CLEAR_USR_MSG());
    }, 3000);
    //console.log(message)
    if (message === "Activated successfully") {
      setaddsucesssetModalShow(true);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, message]);

  return (
    <>
      {/* view Request popup */}
      <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addUserSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addUserPopTextAreaOnSuccess">
            <p>User has been updated successfully</p>
          </div>
        </Modal.Body>
      </Modal>

      <div className="RequestListsTable">
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>Status</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userDetails
                  .filter((u) => u.status === 2 || u.status === 0)
                  .map((user) => {
                    return (
                      <tr key={user?.id}>
                        <td>{user?.name}</td>
                        <td>{userRole[parseInt(user?.role)]}</td>
                        <td>{user?.email}</td>
                        <td>{userRegStatus.at(parseInt(user?.status))}</td>

                        <td className="tableAction">
                          <span onClick={(e) => userViewFunc(user?.id)}>
                            <ViewIcon />
                          </span>
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              userActivate(user?.email);
                            }}
                          >
                            <ApproveIcon />
                          </span>
                          {/* <span onClick={(e) => userEditFunc(user?.id)}>
                          <EditIcon />
                    </span>
                    <span onClick={declineRequestshandleShow}>
                      <DeclineIcon />
                    </span> */}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RequestLists;
