import { useState, useEffect } from "react";
import timeicon from "./time.svg";

const UserTime = () => {
  const [hours, setHours] = useState(new Date());
  const [minutes, setMinutes] = useState(new Date());

  const hour = () => {
    setHours(new Date());
  };

  let minute = () => {
    setMinutes(new Date());
  };

  useEffect(() => {
    setInterval(hour, 3600000);
    setInterval(minute, 60000);
  }, []);
  return (
    <div className="user-date">
      <p>
        <img src={timeicon} alt="time" />
        {hours.getHours().toString().padStart(2, "0")}:
        {minutes.getMinutes().toString().padStart(2, "0")}
      </p>
    </div>
  );
};

export default UserTime;
