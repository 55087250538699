import "./global.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Name from "./pages/Name";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import PasswordReset from "./pages/PasswordReset";
import { PrivateRoutes } from "./PrivateRoutes";
import Test from "./pages/Test";
import AddName from "./pages/AddName";
import ViewName from "./pages/ViewName";
import UpdateName from "./pages/UpdateName";
import AddTest from "./pages/AddTest";
import UpdateTest from "./pages/UpdateTest";
import Media from "./pages/Media";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import UpdateUser from "./pages/UpdateUser";
import ViewUser from "./pages/ViewUser";
import Tests from "./pages/Tests";
import EarlySpeechTest from "./components/tests/EarlySpeechTest";
import EarlySpeechTrailTests from "./components/tests/EarlySpeechTrialTests";
import PictureIdentificationTest from "./components/tests/PictureIdentificationTest";
import PictureIdentificationTrailTest from "./components/tests/PictureIdentificationTrailTest";
import AudioOnlyTest from "./components/tests/AudioOnlyTest";
import AudioOnlytrailTests from "./components/tests/AudioOnlytrailTests";
import Verification from "./pages/Verification";
import NewPassword from "./pages/NewPassword";
import Results from "./pages/Results";
import Report from "./pages/Report";
import ViewReport from "./pages/ViewReport";
import Help from "./pages/Help";
import SentencePerceptionTrailTest from "./components/tests/SentencePerceptionTrailTest";
import SentencePerceptionTest from "./components/tests/SentencePerceptionTest";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ADD_LINK } from "./features/login/loginSlice";
import UpdateMedia from "./pages/UpdateMedia";

const App = () => {
  const dispatch = useDispatch();
  const r_role = (state) => state.login.role;
  const roles = useSelector(r_role);
  console.log(roles);
  //alert('test')
  // useEffect(()=> {
  //   PrivateRoutes(el => {
  //     el?.role.forEach(element => {
  //       if(element === roles) dispatch(ADD_LINK(el?.name))
  //   })
  // });

  // },[dispatch,roles])
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/forgotpassword" element={<PasswordReset />} />
        <Route path="/verify" element={<Verification />} />
        <Route path="/newPassword" element={<NewPassword />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
        />

        <Route
          path="/test"
          element={
            <PrivateRoutes>
              <Test />
            </PrivateRoutes>
          }
        />
        <Route
          path="/name"
          element={
            <PrivateRoutes name="name">
              <Name />
            </PrivateRoutes>
          }
        />
        <Route
          path="/addName"
          element={
            <PrivateRoutes>
              <AddName />{" "}
            </PrivateRoutes>
          }
        />
        <Route
          path="/viewName/:id"
          element={
            <PrivateRoutes>
              <ViewName />
            </PrivateRoutes>
          }
        />
        <Route
          path="/updateName/:id"
          element={
            <PrivateRoutes>
              <UpdateName />
            </PrivateRoutes>
          }
        />
        <Route
          path="/addTest"
          element={
            <PrivateRoutes>
              <AddTest />
            </PrivateRoutes>
          }
        />
        <Route
          path="/updateTest/:tid"
          element={
            <PrivateRoutes>
              <UpdateTest />
            </PrivateRoutes>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoutes>
              <Users />
            </PrivateRoutes>
          }
        />
        <Route path="/addUser" element={<AddUser />} />
        <Route
          path="/updateUser/:id"
          element={
            <PrivateRoutes>
              <UpdateUser />
            </PrivateRoutes>
          }
        />
        <Route
          path="/viewUser/:id"
          element={
            <PrivateRoutes>
              <ViewUser />
            </PrivateRoutes>
          }
        />
        <Route
          path="/media"
          element={
            <PrivateRoutes>
              <Media />
            </PrivateRoutes>
          }
        />
         <Route
          path="/updateMedia/:type/:qid"
          element={
            <PrivateRoutes>
              <UpdateMedia />
            </PrivateRoutes>
          }
        /> 
        <Route
          path="/tests"
          element={
            <PrivateRoutes>
              <Tests />
            </PrivateRoutes>
          }
        />
        <Route
          path="/tests/earlySpeechTest/:testID/:sessionID"
          element={
            <PrivateRoutes>
              <EarlySpeechTest />
            </PrivateRoutes>
          }
        />
        <Route
          path="/tests/earlySpeechtrailTests"
          element={
            <PrivateRoutes>
              <EarlySpeechTrailTests />
            </PrivateRoutes>
          }
        />

        <Route
          path="/tests/pictureTests/:testID/:sessionID"
          element={
            <PrivateRoutes>
              <PictureIdentificationTest />
            </PrivateRoutes>
          }
        />

        <Route
          path="/tests/pictureTrailTests/:testID"
          element={
            <PrivateRoutes>
              <PictureIdentificationTrailTest />
            </PrivateRoutes>
          }
        />
        <Route
          path="/tests/AudioOnlyTest/:testID/:sessionID"
          element={
            <PrivateRoutes>
              <AudioOnlyTest />
            </PrivateRoutes>
          }
        />

        <Route
          path="/tests/AudioOnlytrailTests/:testID"
          element={
            <PrivateRoutes>
              <AudioOnlytrailTests />
            </PrivateRoutes>
          }
        />
        <Route
          path="/results"
          element={
            <PrivateRoutes>
              <Results />
            </PrivateRoutes>
          }
        />
        <Route
          path="/report"
          element={
            <PrivateRoutes>
              <Report />
            </PrivateRoutes>
          }
        />

        <Route
          path="/viewReport/:patientId"
          element={
            <PrivateRoutes>
              <ViewReport />
            </PrivateRoutes>
          }
        />
        <Route
          path="/help"
          element={
            <PrivateRoutes>
              <Help />
            </PrivateRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
