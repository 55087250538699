import { useEffect, useState } from "react";
import { Row, Col, Table, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { testResultAsync } from "../../features/tests/testSlice";
import { getAllPatientsAsync } from "../../features/patient/patientSlice";
import { calculateAge } from "../../utils";
import { format } from "date-fns";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import AudioCommentsModal from "./AudioCommentsModal";


const ResultsLists = (props) => {
  
  //audio comments
  const [currentAudioTest, setCurrentAudioTest] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e,sessionID) => {
    e.preventDefault();
    setCurrentAudioTest(sessionID);
    setShow(true);
  }

  const dispatch = useDispatch();
  // const testRsults = []
  const t_name = (state) => state.login.profile;
  const LoggedInUser = useSelector(t_name);

  const t_r = (state) => state.test.results;
  const testResults = useSelector(t_r);

  const p_patients = (state) => state.patient.patients;
  const patients = useSelector(p_patients);

  const getAge = (patient_id) => {
    if (!patient_id) return "";
    const dobString = patients.filter((item) => item.id == patient_id)[0]?.dob;
    var today = new Date();
    var birthDate = new Date(dobString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  const getCurrentPatientsResult = (results) => {
    return LoggedInUser?.role == 1
      ? results
      : results.filter((item) => item.created_by == LoggedInUser?.email);
  };
  const getESPTOverAllResult = (array) => {
    let Level1 = true,
      Level2 = true,
      Level3 = true;
    if (array.length > 0) {
      if (array.filter((item) => item.level == "1")[0]) {
        Level1 =
          Math.round(
            array.filter((item) => item.level == "1")[0]?.total_questions
          ) ===
          Math.round(
            array.filter((item) => item.level === "1")[0]?.questions_attended
          );
      } else if (array.filter((item) => item.level == "2")[0]) {
        Level2 =
          Math.round(
            array.filter((item) => item.level == "2")[0]?.total_questions
          ) ===
          Math.round(
            array.filter((item) => item.level === "2")[0]?.questions_attended
          );
      } else if (array.filter((item) => item.level == "3")[0]) {
        Level3 =
          Math.round(
            array.filter((item) => item.level == "3")[0]?.total_questions
          ) ===
          Math.round(
            array.filter((item) => item.level === "3")[0]?.questions_attended
          );
      }
      return Level1 && Level2 && Level3;
    }
    return false;
  };

  useEffect(() => {
    dispatch(testResultAsync());
    dispatch(getAllPatientsAsync());
    // console.log("LoggedInUser",LoggedInUser)
    // console.log("testResults",testResults)
  }, []);
  return (
    <>
     <AudioCommentsModal show={show} hide={handleClose} currentAudioTest={currentAudioTest}/>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12}>
          <Table responsive>
            <thead>
              <tr>
                {/* <th>ID</th> */}
                <th>Name</th>
                <th>Age</th>
                <th>Date of Test</th>
                <th>Test Name</th>
                <th>Score</th>
                <th>Comments</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {(props?.searchTextValue
                ? getCurrentPatientsResult(testResults).filter(
                  (item) =>
                    item.patient_name
                      .toLowerCase()
                      .startsWith(props?.searchTextValue) ||
                    item.test
                      .toLowerCase()
                      .startsWith(props?.searchTextValue) ||
                    item.patient_id
                      .toString()
                      .startsWith(props?.searchTextValue)
                )
                : getCurrentPatientsResult(testResults)
              ).map((result, index) => {
                return (
                  <tr key={index + "_" + result?.test}>
                    {/* <td>{result?.patient_id} </td> */}
                    <td>{result?.patient_name}</td>
                    {/* <td> {getAge(result?.patient_id)}</td> */}
                    <td>
                      {calculateAge(
                        patients.filter(
                          (item) => item.id == result?.patient_id
                        )[0]?.dob
                      )}
                    </td>
                    <td>
                      {format(new Date(result?.date_of_test), "dd MMM yyyy")}
                    </td>
                    <td>{result?.test}</td>
                    <td>
                      {/* Test score - PIT Test A,B */}
                      {result?.test.startsWith("Picture") && (
                        <span>
                          {" "}
                          {result?.test === "Picture Identification Test - A"
                            ? "PIT - A"
                            : "PIT - B"}{" "}
                          {Math.round(result?.levels[0]?.overall_perc)} %{" "}
                        </span>
                      )}
                      {/* Test score - ESPT */}
                      {result?.test.startsWith("Early") && (
                        <>
                          {result?.levels.filter(
                            (item) => item.level == "1"
                          )[0] && (
                              <span>
                                Syllable Categorization{" "}
                                {
                                  result?.levels.filter(
                                    (item) => item.level == "1"
                                  )[0]?.correctly_answered
                                }{" "}
                              </span>
                            )}
                          {result?.levels.filter(
                            (item) => item.level == "2"
                          )[0] && (
                              <span>
                                Bisyllable Word Recognition{" "}
                                {
                                  result?.levels.filter(
                                    (item) => item.level == "2"
                                  )[0]?.correctly_answered
                                }{" "}
                              </span>
                            )}
                          {result?.levels.filter(
                            (item) => item.level == "3"
                          )[0] && (
                              <span>
                                Trisyllable Word Recognition{" "}
                                {
                                  result?.levels.filter(
                                    (item) => item.level == "3"
                                  )[0]?.correctly_answered
                                }{" "}
                              </span>
                            )}
                        </>
                      )}
                      {/* Test score - Sentence Perception test */}
                      {result?.test.startsWith("Sentence") && (
                        <span>
                          {" "}
                          {
                            <span>
                              {result?.levels[0]?.correctly_answered}
                              {" / "}
                              {result?.levels[0]?.total_questions}{" "}
                            </span>
                          }
                        </span>
                      )}
                    </td>
                    <td className="commentIcon">
                      {result?.test === "Sentence Perception Test" ? (
                        <span onClick={(e)=>handleShow(e,result)}>
                          <BsFillChatSquareTextFill />
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    {/* over all status - PIT Test A,B */}
                    {result?.test.startsWith("Picture") && (
                      <td
                        className={
                          Math.round(result?.levels[0]?.total_questions) ===
                            Math.round(result?.levels[0]?.questions_attended)
                            ? "results-status-completed"
                            : "results-status-incomplete"
                        }
                      >
                        <span>
                          {Math.round(result?.levels[0]?.total_questions) ===
                            Math.round(result?.levels[0]?.questions_attended)
                            ? "Completed"
                            : "Incomplete"}
                        </span>
                      </td>
                    )}
                    {/* over all status - ESPT */}
                    {result?.test.startsWith("Early") && (
                      <td
                        className={
                          getESPTOverAllResult(result?.levels)
                            ? "results-status-completed"
                            : "results-status-incomplete"
                        }
                      >
                        <span>
                          {getESPTOverAllResult(result?.levels)
                            ? "Completed"
                            : "Incomplete"}
                        </span>
                      </td>
                    )}
                    {/* over all status - Sentence Perception test */}
                    {result?.test.startsWith("Sentence") && (
                      <td
                        className={
                          Math.round(result?.levels[0]?.total_questions) ===
                            Math.round(result?.levels[0]?.questions_attended)
                            ? "results-status-completed"
                            : "results-status-incomplete"
                        }
                      >
                        <span>
                          {Math.round(result?.levels[0]?.total_questions) ===
                            Math.round(result?.levels[0]?.questions_attended)
                            ? "Completed"
                            : "Incomplete"}
                        </span>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ResultsLists;
