import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftSidebar from "../components/sidebar/LeftSidebar";
import "../components/dashboard/dashboard.css";
import PatientAgeGraph from "../components/dashboard/PatientsAgeGraph";
import TestBlock from "../components/dashboard/TestBlock";
import NumOfRegistrations from "../components/dashboard/NumOfRegistrations";
import ApprovedExaminers from "../components/dashboard/ApprovedExaminers";
import PendingRequests from "../components/dashboard/PendingRequests";
import RecentAddedExaminers from "../components/dashboard/RecentAddedExaminers";
import RecentActiveExaminers from "../components/dashboard/RecentActiveExaminers";
import logo from "../global-images/spetra-logo.svg";
import ToggleMenu from "../global-images/ToggleMenu";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const viewHeight = window.innerHeight;
  console.log(viewHeight);
  const r_role = (state) => state.login.role;

  const roles = useSelector(r_role);
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };
  const contentStyle = {
    marginLeft: sidebarOpen ? "250px" : "0px",
    transition: "margin 0.2s ease",
    borderTopLeftRadius: sidebarOpen ? "40px" : "0px",
  };
  return (
    <div className="spetraLayout">
      <Container fluid>
        <Row>
          <div className="SideBar">
            <LeftSidebar
              isOpen={sidebarOpen}
              toggleSidebar={handleViewSidebar}
            />
          </div>
          <div className="main">
            <div className="mainContentboxshadow" style={contentStyle}>
              <div
                className="dashboard-body"
                style={{
                  minheight: viewHeight,
                  maxheight: viewHeight,
                }}
              >
                <Container fluid>
                  <Row>
                    <Col xxl={3} xl={3} lg={3} md={4}>
                      <span onClick={handleViewSidebar} className="toggleMenu">
                        <ToggleMenu />
                      </span>

                      {(() => {
                        if (!sidebarOpen) {
                          return (
                            <img
                              src={logo}
                              alt="spetra-logo"
                              className="sidebarOpenLogo"
                            />
                          );
                        } else {
                          return "";
                        }
                      })()}
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={4}>
                      <h1>Dashboard</h1>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={4}></Col>
                  </Row>
                </Container>

                <div className="dashboard-section1">
                  <Container fluid>
                    <Row>
                    {roles === 1 ? (
                        <>
                          <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                            <NumOfRegistrations />
                          </Col>
                          <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                            <ApprovedExaminers />
                          </Col>
                          <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                            <PendingRequests />
                          </Col>
                        </>
                      ) : (
                        <Col xxl={4} style={{ textAlign: 'left' }}>
                          <NumOfRegistrations />
                        </Col>
                      )}
                      {/* <Col
                      xxl={4}
                      xl={4}
                      lg={6}
                      md={6}
                      sm={6}
                      style={{ marginTop: "20px" }}
                    >
                      <PatientGroup />
                    </Col>
                    <Col
                      xxl={4}
                      xl={4}
                      lg={6}
                      md={6}
                      sm={6}
                      style={{ marginTop: "20px" }}
                    >
                      <AttendedTest />
                    </Col>
                    <Col
                      xxl={4}
                      xl={4}
                      lg={6}
                      md={6}
                      sm={6}
                      style={{ marginTop: "20px" }}
                    >
                      <PercentageResult />
                    </Col> */}
                    </Row>
                  </Container>
                </div>
                <div className="dashboard-section2">
                  <Container fluid>
                    <Row>
                      <Col xxl={12}>
                        <PatientAgeGraph />
                      </Col>
                    </Row>
                  </Container>
                </div>
                {roles === 1 && (
                <div className="dashboard-section3">
                  <Container fluid>
                    <Row>
                      <Col xxl={4} xl={4} lg={6} md={12}>
                        <TestBlock />
                      </Col>
                      <Col xxl={4} xl={4} lg={6} md={12}>
                        <RecentAddedExaminers />
                      </Col>
                      <Col xxl={4} xl={4} lg={6} md={12}>
                        <RecentActiveExaminers />
                      </Col>
                    </Row>
                  </Container>
                </div>
                )}
              </div>
           
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
