import { useRef, useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllPatientsAsync } from "../../features/patient/patientSlice";
import {
  beginTestAsync,
  CLEAR_TEST_SESSION,
} from "../../features/tests/testSlice";

import { Typeahead } from 'react-bootstrap-typeahead';


const TestStart = () => {
  const dispatch = useDispatch();
  const p_patients = (state) => state.patient.patients;
  let patients = useSelector(p_patients);

  const [patientSelection, setPatientSelection] = useState([]);
 
      
  const patientRef = useRef()

  const p_status = (state) => state.test.status;
  const status = useSelector(p_status);

  const p_session = (state) => state.test.sessionId;
  const sessionId = useSelector(p_session);

  const t_name = (state) => state.login.profile;
  const LoggedInUser = useSelector(t_name);

  const t_details = (state) => state.test.currentDetails;
  const currentDetails = useSelector(t_details);

  const [params, setParams] = useState({
    testName: "",
    testId: 0,
    patientName: "",
    patientId: "",
  });

  let options = LoggedInUser?.role == 1? patients: patients.filter((item) => item?.created_by == LoggedInUser?.email)

  const [trialTest, setTrialTest] = useState(false);
  const navigate = useNavigate();

  const EarlySpeechTrailTestsPageFunc = () => {

    //dispatch(beginTestAsync({ test: 13, patient: params.patientId }));
     const EarlySpeechTrailTestPagePath = "/tests/earlySpeechtrailTests";
     navigate(EarlySpeechTrailTestPagePath);
  };

  const PictureATrailTestsPageFunc = () => {
   // dispatch(beginTestAsync({ test: 136, patient: params.patientId }));
    const PictureTrailTestPagePath = "/tests/pictureTrailTests/" + 136;
    navigate(PictureTrailTestPagePath);
  };
  const PictureBTrailTestsPageFunc = () => {
   // dispatch(beginTestAsync({ test: 137, patient: params.patientId }));
    const PictureTrailTestPagePath = "/tests/pictureTrailTests/" + 137;
    navigate(PictureTrailTestPagePath);
  };
  const AudioOnlyTrailTestsPageFunc = () => {
   // dispatch(beginTestAsync({ test: 199, patient: params.patientId }));
   const SpeechSentenceTrailTestPagePath = "/tests/AudioOnlytrailTests/" + 199;
  navigate(SpeechSentenceTrailTestPagePath);
  };

  const handleParams= (e) =>{
    //alert(e[0].id)
    //let p = JSON.parse(e)
    setPatientSelection(e)
    const pname = e[0].name
    const pid = e[0].id
    setParams((params) => ({
      ...params,
      patientId: pid,
      patientName: pname
      }))
    //alert(JSON.stringify(params))
  }
  useEffect(() => {
    dispatch(getAllPatientsAsync());
    // console.log("Test Create Status: ", status);
    // console.log("params", params);
   
  

    if (params.testId != 0 && !status) {
      dispatch(CLEAR_TEST_SESSION());
      dispatch(
        beginTestAsync({ test: params.testId, patient: params.patientId })
      );
    }
    if (status) {  

      if (params.testId == 13) {
        const EarlySpeechTestPagePath =
          "/tests/earlySpeechTest/" + params?.testId + "/" + sessionId;
        navigate(EarlySpeechTestPagePath);
      } else if (params.testId == 136 || params.testId == 137) {
        const PictureTestPagePath =
          "/tests/pictureTests/" + params?.testId + "/" + sessionId;
        navigate(PictureTestPagePath);
      } else if (params.testId == 199) {
        const EarlySpeechTestPagePath =
          "/tests/AudioOnlyTest/" + params?.testId + "/" + sessionId;
        navigate(EarlySpeechTestPagePath);
      }
    }
    if (trialTest && status) {
      if (currentDetails?.testId == 13) {
        const EarlySpeechTrailTestPagePath = "/tests/earlySpeechtrailTests";
        navigate(EarlySpeechTrailTestPagePath);
      } else if (
        currentDetails?.testId == 136 ||
        currentDetails?.testId == 137
      ) {
        const PictureTrailTestPagePath =
          "/tests/pictureTrailTests/" + currentDetails?.testId;
        navigate(PictureTrailTestPagePath);
      } else if (currentDetails?.testId == 199) {
        const EarlySpeechTrailTestPagePath =
          "/tests/AudioOnlytrailTests/" + currentDetails?.testId;
        navigate(EarlySpeechTrailTestPagePath);
      }
    }

    console.log(LoggedInUser);
  }, [dispatch, params,status]);

  return (
    <>
      <div className="ContentMainBody">
        <Container fluid>
          <Row>
            <Col xxl={3} xl={3} lg={3} md={12}></Col>
            <Col xxl={6} xl={6} lg={6} md={12}>
              <Form className="select-name">
                <Form.Group>
                  {/* <Form.Label>Select Name</Form.Label> */}
                  
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={(e) => handleParams(e)}
                    options={options}
                    placeholder="Enter Patient Name..."     
                    selected={patientSelection}               
                    ref={patientRef}
                    
                  />
                
                  {/* <Form.Select
                    type="text"
                    onChange={(e) =>
                      setParams((params) => ({
                        ...params,
                        patientId: e.target.value,
                        patientName: patients.filter(
                          (item) => item.id == e.target.value
                        )[0]?.name,
                      }))
                    }
                  >
                    <option key={"id_default"} value="">
                      Please Select
                    </option>
                    {(LoggedInUser?.role == 1
                      ? patients
                      : patients.filter(
                          (item) => item?.created_by == LoggedInUser?.email
                        )
                    ).map((patient) => {
                      return (
                        <>
                          <option key={patient?.id} value={patient?.id}>
                            {patient?.name}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select> */}
                </Form.Group>
              </Form>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={12}></Col>
          </Row>
          <div className="TestListsSection">
            <Row>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={12}
                sm={12}
                className="margin-md-bottom-40"
              >
                <Button
                  type="button"
                  className="startTestBtn"
                  disabled={params.patientName==""}
                  onClick={(e) => {
                    setParams((params) => ({
                      ...params,                    
                      testName: "Early Speech Perception Test",
                      testId: 13,
                    }));
                  }}
                >
                  Early Speech Perception Test (ESPT)
                </Button>

                <div className="mock-test">
                  <Button
                    disabled={params.patientName==""}
                    type="button"
                    onClick={(e) => {
                      EarlySpeechTrailTestsPageFunc();
                      setTrialTest(true);
                    }}
                  >
                    Trail Test
                  </Button>
                </div>
              </Col>

              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={12}
                sm={12}
                className="margin-md-bottom-40"
              >
                <Button
                  type="button"
                  className="startTestBtn"
                  disabled={params.patientName==""}
                  onClick={(e) => {
                    setParams((params) => ({
                      ...params,
                      testName: "Picture Identification Test - A",
                      testId: 136,
                    }));
                  }}
                >
                  Picture Identification Test - A (PIT)
                </Button>
                <div className="mock-test">
                  <Button
                    disabled={params.patientName==""}
                    type="button"
                    onClick={(e) => {
                      PictureATrailTestsPageFunc();
                      setTrialTest(true);
                    }}
                  >
                    Trail Test
                  </Button>{" "}
                </div>
              </Col>
            </Row>
            <Row className="margin-lg-top-25">
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={12}
                sm={12}
                className="margin-md-bottom-40"
              >
                <Button
                  type="button"
                  className="startTestBtn"
                  disabled={params.patientName==""}
                  onClick={(e) => {
                    setParams((params) => ({
                      ...params,                    
                      testName: "Picture Identification Test - B",
                      testId: 137,
                    }));
                  }}
                >
                  Picture Identification Test - B (PIT)
                </Button>
                <div className="mock-test">
                  <Button
                    disabled={params.patientName==""}
                    type="button"
                    onClick={(e) => {
                      PictureBTrailTestsPageFunc();
                      setTrialTest(true);
                    }}
                  >
                    Trail Test
                  </Button>{" "}
                </div>
              </Col>

              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={12}
                sm={12}
                className="margin-md-bottom-40"
              >
                <Button
                  type="button"
                  className="startTestBtn"
                  disabled={params.patientName==""}
                  onClick={(e) => {
                    setParams((params) => ({
                      ...params,                     
                      testName: "Sentence perception test",
                      testId: 199,
                    }));
                  }}
                >
                  Sentence Perception Test
                </Button>
                <div className="mock-test">
                  <Button
                    disabled={params.patientName==""}
                    type="button"
                    onClick={(e) => {
                      AudioOnlyTrailTestsPageFunc();
                      setTrialTest(true);
                    }}
                  >
                    Trail Test
                  </Button>{" "}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TestStart;
