import { Container, Row, Col, Form, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsCheck2, BsFillArrowDownLeftSquareFill } from "react-icons/bs";
import profilePic from "../../global-images/profilePic.svg";
import {
  CLEAR_MSG,
  updateProfileAsync,
} from "../../features/patient/patientSlice";
import { calculateAge, maxDate } from "../../utils";
import { set } from "rsuite/esm/utils/dateUtils";

const UpdateNameForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const l_p = (state) => state.login.profile;
  const l_profile = useSelector(l_p);

  const p_profiles = (state) => state.patient.patients;
  const all_patients = useSelector(p_profiles);
  const patient = all_patients.filter(
    (p) => parseInt(p.id) === parseInt(props?.id)
  )[0];
  console.log(JSON.stringify(patient));
  const msg = (state) => state.patient.message;
  const message = useSelector(msg);

  const fileval = useRef();
  const [patientProfile, setPatientProfile] = useState({
    id: "",
    name: "",
    dob: "",
    gaurdian_name: "",
    address: "",
    phone: "",
    gaurdian_type: "",
    name_of_implant: "",
    date_of_surgery: "",
    implanted_side: "",
    date_of_switch_on: "",
    date_of_implantation: "",
    age_of_implantation: "",
    dp: "",
    created_by: "",
  });

  const cancelNameFunc = () => {
    const cancelNameBtn = "/name";
    navigate(cancelNameBtn);
  };

  const ageField = useRef(0);

  const [validated, setValidated] = useState(false);

  function updateNameFunc(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (
      patientProfile?.name == "" ||
      patientProfile.phone == "" ||
      patientProfile?.dob == "" ||
      patientProfile.phone.length != 10
    ) {
      return;
    }

    console.log(patientProfile);
    var frm = new FormData();
    // frm.append("patient_id", patientProfile.id)
    // frm.append("name", patientProfile.name)
    // frm.append("gaurdian_name", patientProfile.gaurdian_name)
    // frm.append("address", patientProfile.address)
    // frm.append("phone", patientProfile.phone)
    // frm.append("gaurdian_type", patientProfile.gaurdian_type)
    // frm.append("name_of_implant", patientProfile.name_of_implant)
    // frm.append("date_of_surgery", patientProfile.date_of_surgery)
    // frm.append("implanted_side", patientProfile.implanted_side)
    // frm.append("date_of_switch_on", patientProfile.date_of_switch_on)
    // //frm.append("age_of_implantation", patientProfile.age_of_implantation)
    // frm.append("dp", patientProfile.dp)
    // frm.append("dob", patientProfile.dob)
    // frm.append("age_of_implantation", "5")
    Object.keys(patientProfile).forEach((e) => {
      if (
        patientProfile[e] === null ||
        patientProfile[e] === "undefined" ||
        patientProfile[e] === ""
      ) {
        patientProfile[e] = "";
      }
    });
    // alert(JSON.stringify(patientProfile))
    frm.append("id", parseInt(props?.id));
    frm.append("name", patientProfile?.name);
    frm.append("gaurdian_name", patientProfile?.gaurdian_name);
    frm.append("address", patientProfile?.address);
    frm.append("phone", patientProfile?.phone);
    frm.append("gaurdian_type", patientProfile?.gaurdian_type);
    frm.append("name_of_implant", patientProfile?.name_of_implant);
    frm.append("date_of_surgery", patientProfile?.date_of_surgery);
    frm.append("implanted_side", patientProfile?.implanted_side);
    frm.append("date_of_switch_on", patientProfile?.date_of_switch_on);
    frm.append("date_of_implantation", patientProfile?.date_of_implantation);
    frm.append("age_of_implantation", patientProfile?.age_of_implantation);
    if (
      fileval.current.value === "" ||
      fileval.current.value === null ||
      fileval.current.value === undefined
    ) {
      frm.append("dp", "");
    } else {
      frm.append("dp", patientProfile?.dp);
    }

    frm.append("dob", patientProfile?.dob);

    if (
      patientProfile.created_by === "" ||
      patientProfile.created_by === null
    ) {
      frm.append("created_by", l_profile?.email);
    }

    console.log(frm);
    setaddsucesssetModalShow(false);
    dispatch(CLEAR_MSG());
    dispatch(updateProfileAsync(frm));
  }
  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);
  // const addsucesshandleShow = () => setaddsucesssetModalShow(true);

  useEffect(() => {
    if (message === "update successful") {
      setaddsucesssetModalShow(true);
    }
    const timeId = setTimeout(() => {
      if (message === "update successful") {
        setaddsucesssetModalShow(false);
        dispatch(CLEAR_MSG());
        cancelNameFunc();
      }
    }, 2000);

    let p = {
      id: patient?.id,
      name: patient?.name,
      dob: patient?.dob,
      gaurdian_name: patient?.gaurdian_name,
      address: patient?.address,
      phone: patient?.phone,
      gaurdian_type: patient?.gaurdian_type,
      name_of_implant: patient?.name_of_implant,
      date_of_surgery: patient?.date_of_surgery,
      implanted_side: patient?.implanted_side,
      date_of_switch_on: patient?.date_of_switch_on,
      date_of_implantation: patient?.date_of_implantation,
      age_of_implantation: patient?.age_of_implantation,
      dp: patient?.dp,
      created_by: patient?.created_by,
    };
    setPatientProfile((patientProfile) => ({ ...patientProfile, ...p }));
    // let p = patient.filter((obj) => obj.id === props?.id);
    // if (p.length > 0) {
    //   setPatientProfile((patientProfile) => ({
    //     ...{ patientProfile },
    //     ...p[0],
    //   }));
    //   //console.log(patientProfile)
    // }

    return () => {
      clearTimeout(timeId);
      /// console.log(p)
    };
  }, [dispatch, props, message, patient]);

  return (
    <>
      <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addNameSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addPopTextAreaOnSuccess">
            <p> {patientProfile?.name}, details updated succesfully!</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="ExaminerUpdateNameFormField">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={3}>
            <div className="ProfilePicSection">
              {patientProfile?.dp === null || patientProfile?.dp === "" ? (
                <img src={profilePic} className="img-fluid" alt="profile-pic" />
              ) : (
                <img
                  src={patientProfile?.dp}
                  className="img-fluid"
                  alt="profile-pic"
                />
              )}
            </div>
            <Form.Group className="mt-2">
              <Form.Label className="text-center d-block fw-bold">
                Patient Picture
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) =>
                  setPatientProfile((patientProfile) => ({
                    ...patientProfile,
                    ...{ dp: e.target.files[0] },
                  }))
                }
                ref={fileval}
              ></Form.Control>
            </Form.Group>
            {/* <div className="text-center">
              <Button type="button" className="btn-submit">
                Save
              </Button>
            </div> */}
          </Col>
          <Col xxl={9} xl={9} lg={9} md={9}>
            <Form
              role="form"
              className="updateNameForm"
              onSubmit={(e) => updateNameFunc(e)}
              noValidate
              validated={validated}
            >
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Name<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="name"
                      value={patientProfile?.name}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ name: e.target.value },
                        }))
                      }
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Date of Birth<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={patientProfile?.dob}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ dob: e.target.value },
                        }))
                      }
                      max={maxDate()}
                      required
                      ref={ageField}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Date of Birth is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Age</Form.Label>
                    <Form.Control
                      type="number"
                      readOnly
                      value={calculateAge(patientProfile?.dob)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Name of the Guardian</Form.Label>
                    <Form.Control
                      type="text"
                      value={patientProfile?.gaurdian_name}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ gaurdian_name: e.target.value },
                        }))
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Guardian Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      value={patientProfile?.gaurdian_type}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ gaurdian_type: e.target.value },
                        }))
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Phone<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      value={patientProfile?.phone}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{
                            phone: e.target.value.replace(/[^0-9.]/g, ""),
                          },
                        }))
                      }
                      pattern="^[123456789]\d{9,9}$"
                      title="Enter the Valid 10 digit mobile number"
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Enter the Valid 10 digit Mobile Number
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={patientProfile?.address}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ address: e.target.value },
                        }))
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Name of Implant</Form.Label>
                    <Form.Control
                      type="text"
                      value={patientProfile?.name_of_implant}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ name_of_implant: e.target.value },
                        }))
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Date Of Surgery</Form.Label>
                    <Form.Control
                      type="date"
                      value={patientProfile?.date_of_surgery}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ date_of_surgery: e.target.value },
                        }))
                      }
                      max={maxDate()}
                    />
                    {/*["checkbox"].map((type) => (
                        <div key={`default-${type}`} className="mb-3 mt-3">
                          <Form.Check
                            type={type}
                            id={`default-${type}`}
                            label={`NA`}
                          />
                        </div>
                      ))*/}
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Implanted Side</Form.Label>
                    <Form.Select
                      type="dropdown"
                      value={patientProfile?.implanted_side}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ implanted_side: e.target.value },
                        }))
                      }
                    >
                      <option></option>
                      <option>Right</option>
                      <option>Left</option>
                      <option>Both</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Date Of Switch-on</Form.Label>
                    <Form.Control
                      type="date"
                      value={patientProfile?.date_of_switch_on}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ date_of_switch_on: e.target.value },
                        }))
                      }
                      max={maxDate()}
                    />
                    {/*["checkbox"].map((type) => (
                        <div key={`default-${type}`} className="mb-3 mt-3">
                          <Form.Check
                            type={type}
                            id={`default-${type}`}
                            label={`NA`}
                          />
                        </div>
                      ))*/}
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Age of Implantation</Form.Label>
                    <Form.Control
                      type="text"
                      value={patientProfile?.age_of_implantation}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ age_of_implantation: e.target.value },
                        }))
                      }
                    //  max={maxDate()}
                    />
                    {/*["checkbox"].map((type) => (
                        <div key={`default-${type}`} className="mb-3 mt-3">
                          <Form.Check
                            type={type}
                            id={`default-${type}`}
                            label={`NA`}
                          />
                        </div>
                      ))*/}
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} className="text-end">
                  <Button
                    type="button"
                    className="btn-cancel"
                    onClick={cancelNameFunc}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Button type="submit" className="btn-submit">
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UpdateNameForm;
