const DeletePopupIcon = () => {
  return (
    <>
      <svg viewBox="0 0 50.513 62.257">
        <defs></defs>
        <g transform="translate(-41.785)">
          <path
            className="a"
            style={{ fill: "#f15e5e" }}
            d="M81.135,5.34H69.417V0H53.5V5.34H41.785v8.432h39.35ZM57.719,4.216H65.2V5.34H57.719Z"
          />
          <path
            className="a"
            style={{ fill: "#f15e5e" }}
            d="M95.426,140.13l.8-12.13H62.769l2.672,40.755H81.487A18.18,18.18,0,0,1,95.426,140.13Z"
            transform="translate(-18.035 -110.011)"
          />
          <path
            className="a"
            style={{ fill: "#f15e5e" }}
            d="M216.328,244.141A13.973,13.973,0,1,0,230.3,258.114,13.989,13.989,0,0,0,216.328,244.141Zm7.614,18.606-2.981,2.981-4.632-4.632-4.632,4.632-2.981-2.981,4.632-4.633-4.632-4.632L211.7,250.5l4.632,4.632,4.632-4.632,2.981,2.981-4.632,4.632Z"
            transform="translate(-138.004 -209.831)"
          />
        </g>
      </svg>
    </>
  );
};
export default DeletePopupIcon;
