// PatientsAgeGraph.js 
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Container, Row, Col } from "react-bootstrap";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPatientAgeGraph } from "../../features/dashboard/PatientAgeGraphSlice";
import { baseURL } from "../../../src/constants";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const PatientAgeGraph = () => {
  const dispatch = useDispatch();
  const patientAgeGraphData = useSelector((state) => state.patientAgeGraph.data);

  const r_role = (state) => state.login.role;
  const r_email = (state) => state.login.mail;

  const roles = useSelector(r_role);
  const mailVal = useSelector(r_email)
  console.log("inside graph:"+mailVal+"--"+roles)

  // Assuming 'createdByValue' is the value you want to append for 'created_by'
  const createdByValue = mailVal; // Replace 'yourValueHere' with the actual value
  let curUrl = ""
  if (parseInt(roles) == 1) {
    // Append the parameter for cases other than roles == 1
     curUrl = `${baseURL}patient/patients_agewise`;
    curUrl += `?created_by=`+createdByValue; // Replace YOUR_VALUE_HERE with the actual value
  }
  else{
    curUrl = `${baseURL}patient/patients_agewise_user`;
    curUrl += `?created_by=`+createdByValue; // Replace YOUR_VALUE_HERE with the actual value
  }
  console.log("current url"+curUrl)
  useEffect(() => {
    const fetchPatientsAgeGraphData = async () => {
      try {
        const response = await fetch(curUrl, {
          method: 'GET', // or 'POST' if your request is a POST
          cache: 'no-cache', // Forces a fresh fetch bypassing the cache
        });
        const data = await response.json();
        console.log("Received", data.result);
        dispatch(setPatientAgeGraph(data.result || []));
      } catch (error) {
        console.error("Error fetching patients age graph data:", error);
      }
    };

    fetchPatientsAgeGraphData();
  }, [dispatch]);

  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    // Set chart data based on the patientsAgeGraphData
    const labels = patientAgeGraphData.map((item) => item.agegroup);
    const counts = patientAgeGraphData.map((item) => item.count);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: "Number of Patients in Particular Age Category",
          data: counts,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(141,161,178,1)",
          barThickness: 40,
        },
      ],
    });

    // Set chart options
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          position: "top",
          display: false,
        },
        datalabels: {
          anchor: "end",
          align: "top",
          color: "#000",
          font: {
            size: 16,
            weight: "bold",
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          display: false,
          max: Math.max(...counts) + 5, // Adjust the max based on the data
        },
        x: {
          grid: {
            display: false,
            drawOnChartArea: false,
            drawBorder: false,
          },
          ticks: {
            font: {
              size: 16,
              weight: "bold",
            },
            color: "black",
          },
        },
      },
    });
  }, [patientAgeGraphData]);

  return (
    <div className="dashboard-box">
      <Container fluid>
        <Row>
          <Col xxl={12}>
            <h2 className="dashboard-title">Patients by age</h2>
            <Bar options={chartOptions} data={chartData} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PatientAgeGraph;
