import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addQuestion, getAllQuestions, updateQuestion } from './mediaAPI';


const initialState = {
    questions:[],
    status: false ,
    message:""
  };


  export const addQuestionAsync = createAsyncThunk(
    'media/addQuestion',   
    async (questionObj) => {
      const response = await addQuestion(questionObj);  
      return response.data;
    }
  );

  export const getAllQuestionsAsync = createAsyncThunk(
    'media/getAllQuestions',   
    async () => {
      const response = await getAllQuestions();  
      return response.data;
    }
  );

  export const updateQuestionAsync = createAsyncThunk(
    'media/updateQuestion',   
    async (question) => {
      const response = await updateQuestion(question);  
      return response.data;
    }
  );


  const mediaSlice = createSlice({
    name: "media",
    initialState,
    reducers: {
        FETCH_QUESTIONS: (state) => {
            return {...state.media.questions}
        },
        CLEAR_MSG: (state) => {
          state.message = ""
          
      }
    },
    extraReducers: {
        [addQuestionAsync.fulfilled]: (state, action) => {
            let data = action.payload          
            state.message = data
            getAllQuestionsAsync()
        },
        [getAllQuestionsAsync.fulfilled]: (state, action) => {
            let data = action.payload          
            console.log("Questions: ",data)
            state.questions = data
        },
        [updateQuestionAsync.fulfilled]: (state, action) => {
          let data = action.payload          
          console.log("Question Updated Response: ",data)
          state.message = data?.message
      }

    }
});


export const {FETCH_QUESTIONS,CLEAR_MSG}= mediaSlice.actions;
export default mediaSlice.reducer;