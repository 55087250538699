import { useEffect } from 'react';
import { useState } from 'react';
import {Modal,Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { ADD_LEVEL, UPDATE_LEVEL } from '../../features/tests/testSlice';
import QuestionBank from './QuestionBank';

function QuestionsModal(props){

    const dispatch = useDispatch()
    const q_level = (state) => state.test.currentLevels;
    const currLevels = useSelector(q_level);

    const [questionBankmodalshow, setQuestionBankModalShow] = useState(false);
    const questionBankhandleClose = () => setQuestionBankModalShow(false);
    const questionBankhandleShow = () => setQuestionBankModalShow(false);
    const qid =[]
    function saveQid(value, type){
        switch(type){
            case "add":
                 qid.push(value)
                 break;
            case "remove":
                qid.pop(value);
                break;
            default:
        }
       
        //alert(JSON.stringify(qid))
    }
    useEffect(()=>{
        if(props?.qbStatus){          
            setQuestionBankModalShow(true) 
        }
            return(()=> {
                setQuestionBankModalShow(false)
        
            })

    },[props])

    function handleClose(){
        setQuestionBankModalShow(false);
        props.setQBStatus(false)
 
    }

    function handleSave(){
        let validate = false
        currLevels.forEach(level =>{
            if(level.id === props?.levelId){
                validate = true
            }
         })
         if(validate){
            dispatch(UPDATE_LEVEL({"id": props?.levelId, "level_name": props?.levelName, "qids": qid}))
         }else{
            dispatch(ADD_LEVEL({"id": props?.levelId, "level_name": props?.levelName, "qids": qid}))
         }
       
        setQuestionBankModalShow(false);
        props.setQBStatus(false)      
    }
    return(
        <> 
            <Modal
                backdrop={'static'}
                show={questionBankmodalshow}
                onHide={questionBankhandleClose}
                className="text-center"
                size="lg"
                centered
            >
                <Modal.Body>
                <div className="addTestPopTextAreaOnSuccess">
                    <QuestionBank  levelId={props?.levelId} levelName={props?.levelName} saveQid={saveQid} type={props?.type}/>
                
                </div>
                </Modal.Body>
                <Modal.Footer>
                        <Button
                            type="button"
                            className="btn-test-delete-cancel"
                            onClick={handleClose}
                            >
                            Cancel
                            </Button>
                            <Button
                                type="button"
                                className="btn-test-delete-cancel"
                                onClick={handleSave}
                                >
                                Save
                                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default QuestionsModal;