import { Table, Row, Col, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import DeletePopupIcon from "../../global-images/DeletePopupIcon";
import { BsCheck2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersAsync,
  deleteUserAsync,
  CLEAR_USR_MSG,
} from "../../features/login/loginSlice";
import { format } from "date-fns";
import { userRegStatus, userRole } from "../../constants";
import ViewIcon from "../../global-images/ViewIcon";
import EditIcon from "../../global-images/EditIcon";
import DeleteIcon from "../../global-images/DeleteIcon";

const UserLists = (props) => {
  const [modalshow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  //const handleShow = () => setModalShow(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const p_profiles = (state) => state.login.users;
  const userDetails = useSelector(p_profiles);
  const msg = (state) => state.patient.message;
  const message = useSelector(msg);

  const [deleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: "",
  });
  const [deletesucessmodalshow, setdeletesucesssetModalShow] = useState(false);
  const deletesucesshandleClose = () => setdeletesucesssetModalShow(false);
  //const deletesucesshandleShow = () => setdeletesucesssetModalShow(true);

  // const [userDetails, setUserDetails]= useState({
  //     id: "",
  //     last_login: "",
  //     is_superuser: false,
  //     first_name: "",
  //     last_name: "",
  //     is_staff: false,
  //     is_active: false,
  //     date_joined: "",
  //     name: "",
  //     dp: "",
  //     role: 1,
  //     email: "",
  //     phone: "",
  //     gender: "",
  //     status: 0,
  //     password: "",
  //     address: null,
  //     username: "",
  //     hospital: null,
  //     message_to_admin: null

  // })
  useEffect(() => {
    dispatch(getAllUsersAsync());

    const timeId = setTimeout(() => {
      setdeletesucesssetModalShow(false);
      dispatch(CLEAR_USR_MSG());
    }, 3000);

    if (deleteConfirm.status) {
      // alert(deleteConfirm.id)
      dispatch(deleteUserAsync({ email: deleteConfirm.id }));
    }

    if (message === "delete successful") {
      setdeletesucesssetModalShow(true);
    }
    console.log("userDetails", userDetails)
    return () => {
      clearTimeout(timeId);
      dispatch(CLEAR_USR_MSG());
    };
  }, [dispatch, deleteConfirm, message]);

  const userEditFunc = (id) => {
    const userEditPath = "/updateUser/" + id;
    navigate(userEditPath);
  };

  const userViewFunc = (id) => {
    const userViewPath = "/viewUser/" + id;
    navigate(userViewPath);

  };
  return (
    <>
      <Modal
        show={modalshow}
        onHide={handleClose}
        className="deleteUser text-center"
        centered
      >
        <Modal.Body>
          <DeletePopupIcon />
          <div className="deleteUserPopTextArea">
            <h2>Are you sure?</h2>
            <p>
              Do you really want to delete this user? This process cannot be
              undone.
            </p>
            <Button
              type="button"
              className="btn-user-delete-cancel"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn-user-delete"
              onClick={() => {
                setDeleteConfirm((deleteConfirm) => ({
                  ...deleteConfirm,
                  ...{ status: true },
                }));
                //deletesucesshandleShow(true);
                handleClose();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* sucess popup */}
      <Modal
        show={deletesucessmodalshow}
        onHide={deletesucesshandleClose}
        className="deleteUserSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="deleteUserPopTextAreaOnSuccess">
            <p>User has been deleted succesfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="UserNameListsTable">
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Table>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Name</th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(props?.searchTextValue ? userDetails.filter(u => u.status === 1 && ((u.name.toLowerCase().startsWith(props?.searchTextValue)) || (userRole[parseInt(u?.role)]).toLowerCase().includes(props?.searchTextValue) || u.email.toLowerCase().includes(props?.searchTextValue))) : userDetails.filter(u => u.status === 1)).map((user) => {
                  return (
                    <tr key={user?.id}>
                      {/* <td>{user?.id}</td> */}
                      <td>{user?.name}</td>
                      <td>{userRole[parseInt(user?.role)]}</td>

                      <td>{user?.email}</td>

                      <td className="tableAction">
                        <span onClick={(e) => userViewFunc(user?.id)}>
                          <ViewIcon />
                        </span>
                        <span onClick={(e) => userEditFunc(user?.id)}>
                          <EditIcon />
                        </span>
                        {/* <span
                          onClick={(e) => {
                            setDeleteConfirm((deleteConfirm) => ({
                              ...{ deleteConfirm },
                              ...{ id: user?.email },
                            }));
                            handleShow();
                          }}
                        >
                          <DeleteIcon />
                        </span> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserLists;
