import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
const AddNameButtonComponent = () => {
  const AddNameBtn = useNavigate();
  const AddNameFunc = () => {
    const AddNameButton = "/addName";
    AddNameBtn(AddNameButton);
  };
  return (
    <>
      <Button type="button" className="btn-add" onClick={AddNameFunc}>
        <BsPlusLg />
        Add Name
      </Button>
    </>
  );
};

export default AddNameButtonComponent;
