const LogOutIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
      <path
        id="Path_30"
        d="M103.9,69.9l-5.3,5.3l6,6H73.7v7.6h30.9l-6,6l5.3,5.4L119.1,85L103.9,69.9z M58.6,58.6
	H85V51H58.6c-4.2,0-7.5,3.4-7.6,7.6v52.9c0,4.2,3.4,7.5,7.6,7.6H85v-7.6H58.6V58.6z"
      />
    </svg>
  );
};

export default LogOutIcon;
