import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addTest,
  beginTest,
  testResponse,
  testResult,
  testResultComments,
  testSubmit,
  getAllTests,
  deleteTest,
  getTestDetails,
  updateTest,
} from "./testAPI";

const initialState = {
  questions: [],
  response: {},
  props: {},
  sessionId: "",
  currentDetails: {},
  status: false,
  message: "",
  results: [],
  resultsComments: [],
  tests: [],
  currentLevel: 1,
  currentLevels: [],
  currentTestDetails: {},
};

export const addTestAsync = createAsyncThunk(
  "test/addTest",
  async (testDetails) => {
    const response = await addTest(testDetails);
    return response.data;
  }
);

export const beginTestAsync = createAsyncThunk(
  "test/beginTest",
  async (testDetails) => {
    const response = await beginTest(testDetails);
    return response.data;
  }
);

export const testResponseAsync = createAsyncThunk(
  "test/testResponse",
  async (tResponse) => {
    const response = await testResponse(tResponse);
    return response.data;
  }
);

export const testSubmitAsync = createAsyncThunk(
  "test/testSubmit",
  async (tResponse) => {
    const response = await testSubmit(tResponse);
    return response.data;
  }
);

export const testResultAsync = createAsyncThunk("test/testResult", async () => {
  const response = await testResult();
  return response.data;
});

export const testResultCommentsAsync = createAsyncThunk("test/getallanswers", async () => {
  const response = await testResultComments();
  return response.data;
});

export const getAllTestsAsync = createAsyncThunk(
  "test/getAllTests",
  async () => {
    const response = await getAllTests();
    return response.data;
  }
);

export const getTestDetailsAsync = createAsyncThunk(
  "test/getTestDetails",
  async (testDetails) => {
    const response = await getTestDetails(testDetails);
    return response.data;
  }
);

export const deleteTestAsync = createAsyncThunk(
  "test/delete",
  async (testDetails) => {
    const response = await deleteTest(testDetails);
    return response.data;
  }
);

export const updateTestAsync = createAsyncThunk(
  "test/update",
  async (testDetails) => {
    const response = await updateTest(testDetails);
    return response.data;
  }
);

export const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    FETCH_QUESTIONS: (state) => {
      return { ...state.questions };
    },
    SET_PROPS: (state, action) => {
      const data = action.payload;
      console.log(data);
      return { ...state, props: data };
    },
    FETCH_PROPS: (state) => {
      return { ...state.props };
    },
    CLEAR_TEST_MSG: (state) => {
      state.message = "";
    },
    CLEAR_TEST_SESSION: (state) => {
      state.sessionId = "";
    },
    RESET_STATUS: (state) => {
      state.status = false;
    },
    SET_LEVEL: (state, action) => {
      state.currentLevel = action.payload;
    },
    REMOVE_LEVEL: (state, action) => {
      state.currentLevel = state.currentLevel.filter(
        (item) => item?.id !== action.payload
      );
    },
    FETCH_LEVEL: (state) => {
      return { ...state, ...state.currentLevel };
    },
    UPDATE_LEVEL_NAME: (state, action) => {
      const data = action.payload;
      state.currentLevels.forEach((level, index) => {
        if (level?.id === data?.id) {
          state.currentLevels[index].level_name = data?.level_name;
        }
      });
    },
    UPDATE_LEVEL: (state, action) => {
      const data = action.payload;
      state.currentLevels.forEach((level, index) => {
        //if(level?.level_name === data?.level_name) validate = false
        if (level?.id === data?.id) {
          state.currentLevels[index].qids = data?.qids;
        }
      });
    },
    ADD_LEVEL: (state, action) => {
      const data = action.payload;
      let validate = true;
      state.currentLevels.forEach((level) => {
        //if(level?.level_name === data?.level_name) validate = false
        if (level?.qids === data?.qids) validate = false;
        if (level?.id === data?.id) validate = false;
      });

      if (validate) {
        state.currentLevels.push(action.payload);
      } else {
        // alert("Error in Adding Level")
      }
    },
    REMOVE_LEVELS: (state, action) => {
      // console.log("store1",current(state.currentLevels))
      state.currentLevels = state.currentLevels.filter(
        (item) => item?.id !== action.payload
      );
      // state.currentTestDetails?.levels = state.currentTestDetails?.levels.filter((item) => item?.id !== action.payload);
      // console.log("store2",current(state.currentLevels))
    },
    CLEAR_LEVELS: (state) => {
      state.currentLevels = [];
      state.currentLevel = 1;
    },
    FETCH_LEVELS: (state) => {
      return { ...state, ...state.currentLevels };
    },
  },
  extraReducers: {
    [addTestAsync.fulfilled]: (state, action) => {
      let data = action.payload;
      state.message = data;
    },
    [beginTestAsync.fulfilled]: (state, action) => {
      const data = action.payload;
      if (
        data?.sessionId !== null ||
        data?.sessionId !== "undefined" ||
        data?.sessionId !== ""
      ) {
        state.questions = data?.test_questions;
        state.sessionId = data?.session_id;
        state.currentDetails = {
          patientName: data?.patiennt_name,
          patient_id: data?.patiennt_id,
          examiner: data?.examiner,
          testId: data?.test_id,
          testName: data?.test_name,
          levels: Object.keys(data?.test_questions)?.length,
        };
        state.status = true;
        state.message = "Test Created";
      } else {
        state.status = false;
        state.message = "Test Failed";
      }
    },
    [testResponseAsync.fulfilled]: (state, action) => {
      let data = action.payload;
      state.message = data;
    },
    [testSubmitAsync.fulfilled]: (state, action) => {
      let data = action.payload;
      state.message = data;
    },
    [testResultAsync.fulfilled]: (state, action) => {
      console.log("Results Recorded!");

      let data = action.payload;
      console.log("results", data);
      state.results = data;
    },
    [testResultCommentsAsync.fulfilled]: (state, action) => {
      console.log("Results comments Recorded!");

      let data = action.payload;
      console.log("results comments", data);
      state.resultsComments = data;
    },
    [getAllTestsAsync.fulfilled]: (state, action) => {
      console.log("Get All Tests");

      let data = action.payload;
      // console.log("results", data )
      state.tests = data;
    },
    [deleteTestAsync.fulfilled]: (state, action) => {
      console.log("delete Test");

      let data = action.payload;
      // console.log("results", data )
      state.message = data?.message;
    },
    [updateTestAsync.fulfilled]: (state, action) => {
      console.log("update Test");

      let data = action.payload;
      // console.log("results", data )
      state.message = data?.message;
    },
    [getTestDetailsAsync.fulfilled]: (state, action) => {
      console.log("Get Test Details", action.payload);
      let data = action.payload;
      let newDetails = { ...state.currentTestDetails };
      newDetails = data;
      state.currentTestDetails = newDetails;
      // state.currentTestDetails = data;
    },
  },
});

export const {
  FETCH_QUESTIONS,
  SET_PROPS,
  FETCH_PROPS,
  CLEAR_TEST_MSG,
  CLEAR_TEST_SESSION,
  RESET_STATUS,
  SET_LEVEL,
  FETCH_LEVEL,
  ADD_LEVEL,
  UPDATE_LEVEL,
  UPDATE_LEVEL_NAME,
  REMOVE_LEVEL,
  REMOVE_LEVELS,
  CLEAR_LEVELS,
  FETCH_LEVELS,
} = testSlice.actions;
export default testSlice.reducer;
