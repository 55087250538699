import { Form, Button, Container, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_MSG,
  addProfileAsync,
} from "../../features/patient/patientSlice";
import { calculateAge } from "../../utils";
import { maxDate } from "../../utils";
import profilePic from "../../global-images/profilePic.svg";
const AddNameForm = () => {
  const dispatch = useDispatch();
  const cancelButton = useNavigate();
  const p_profiles = (state) => state.patient.patients;
  const patient = useSelector(p_profiles);
  const msg = (state) => state.patient.message;
  const message = useSelector(msg);

  const l_p = (state) => state.login.profile;
  const l_profile = useSelector(l_p);

  const cancelNameFunc = () => {
    const cancelNameBtn = "/name";
    cancelButton(cancelNameBtn);
  };

  const ageField = useRef(0);

  const [patientProfile, setPatientProfile] = useState({
    name: "",
    dob: "",
    gaurdian_name: "",
    address: "",
    phone: "",
    gaurdian_type: "",
    name_of_implant: "",
    date_of_surgery: "",
    implanted_side: "",
    date_of_switch_on: "",
    age_of_implantation: "",
    dp: "",
    created_by: "",
  });

  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);
  //const addsucesshandleShow = () => setaddsucesssetModalShow(true);

  const [validated, setValidated] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  function addNameFunc(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (
      patientProfile?.name == "" ||
      patientProfile.phone == "" ||
      patientProfile?.dob == "" ||
      patientProfile.phone.length != 10
    ) {
      return;
    }
    console.log(patientProfile);
    var frm = new FormData();
    //frm.append("id", patientProfile?.id);
    frm.append("name", patientProfile?.name);
    frm.append("gaurdian_name", patientProfile?.gaurdian_name);
    frm.append("address", patientProfile?.address);
    frm.append("phone", patientProfile?.phone);
    frm.append("gaurdian_type", patientProfile?.gaurdian_type);
    frm.append("name_of_implant", patientProfile?.name_of_implant);
    frm.append("date_of_surgery", patientProfile?.date_of_surgery);
    frm.append("implanted_side", patientProfile?.implanted_side);
    frm.append("date_of_switch_on", patientProfile?.date_of_switch_on);
    frm.append("age_of_implantation", patientProfile?.age_of_implantation);
    frm.append("dp", patientProfile?.dp);
    frm.append("dob", patientProfile?.dob);
    if (
      patientProfile.created_by === "" ||
      patientProfile.created_by === null
    ) {
      frm.append("created_by", l_profile?.email);
    }
    console.log(JSON.stringify(frm));

    dispatch(addProfileAsync(frm));
  }

  useEffect(() => {
    console.log(message);
    const timeId = setTimeout(() => {
      if (message === "Patient registered successfully") {
        setaddsucesssetModalShow(false);
        dispatch(CLEAR_MSG());
        cancelNameFunc();
      }
    }, 2000);

    // let p = patient.filter((obj) => obj.id === patientProfile?.id);
    //   if (p.length >= 0) {
    //     setPatientProfile((patientProfile) => ({
    //       ...{ patientProfile },
    //       ...p[0],
    //     }));
    //     //console.log(patientProfile)
    //   }
    if (message === "Patient registered successfully") {
      setaddsucesssetModalShow(true);
    } else if (
      message?.phone?.[0] === "patient with this phone already exists."
    ) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, message, patient, patientProfile]);
  return (
    <div>
      {
        <Modal
          show={addsucessmodalshow}
          onHide={addsucesshandleClose}
          className="addNameSuccess text-center"
          centered
        >
          <Modal.Body>
            <BsCheck2 />
            <div className="addPopTextAreaOnSuccess">
              <p>Patient has been added sucessfully</p>
            </div>
          </Modal.Body>
        </Modal>
      }
      <Row>
        <Col xxl={3} xl={3} lg={3} md={3}>
          <img src={profilePic} className="img-fluid" alt="profile-pic" />
          {/* {patientProfile.dp === null || patientProfile.dp === "" ? (
            <img src={profilePic} className="img-fluid" alt="profile-pic" />
          ) : (
            <img
              src={patientProfile?.dp}
              className="img-fluid"
              alt="profile-pic"
            />
          )} */}
          <Form.Group>
            <Form.Label className="text-center d-block fw-bold">
              Patient Picture
            </Form.Label>
            <Form.Control
              type="file"
              onChange={(e) =>
                setPatientProfile((patientProfile) => ({
                  ...patientProfile,
                  ...{ dp: e.target.files[0] },
                }))
              }
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xxl={9} xl={9} lg={9} md={9}>
          <Form
            className="ExaminerAddNameFormField"
            onSubmit={(e) => addNameFunc(e)}
            noValidate
            validated={validated}
          >
            <Row>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>
                    Name<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ name: e.target.value },
                      }))
                    }
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Name is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>
                    Date of Birth<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={patientProfile.dob}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ dob: e.target.value },
                      }))
                    }
                    max={maxDate()}
                    required
                    ref={ageField}
                  />
                  <Form.Control.Feedback type="invalid">
                    Date of Birth is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    type="number"
                    readOnly
                    value={calculateAge(ageField.current.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Name of the Guardian</Form.Label>
                  <Form.Control
                    type="text"
                    value={patientProfile.gaurdian_name}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ gaurdian_name: e.target.value },
                      }))
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Guardian Relationship</Form.Label>
                  <Form.Control
                    type="text"
                    value={patientProfile.gaurdian_type}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ gaurdian_type: e.target.value },
                      }))
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>
                    Mobile<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    value={patientProfile.phone}
                    onChange={(e) => {
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{
                          phone: e.target.value.replace(/[^0-9.]/g, ""),
                        },
                      }));
                    }}
                    pattern="^[123456789]\d{9,9}$"
                    title="Enter the Valid 10 digit mobile number"
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Enter the Valid 10 digit Mobile Number
                  </Form.Control.Feedback>
                  {message?.phone?.[0] && (
                    <p className="mobile-error">
                      Mobile Number Already Exists!!!
                    </p>
                  )}
                </Form.Group>
              </Col>
              {/* <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group>
                <Form.Label>ID</Form.Label>
                <Form.Control
                  type="text"
                  value={patientProfile.id}
                  onChange={(e) =>
                    setPatientProfile((patientProfile) => ({
                      ...patientProfile,
                      ...{ id: e.target.value },
                    }))
                  }
                ></Form.Control>
              </Form.Group>
            </Col> */}
              {/* <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group>
                  <Form.Label>Patient Picture</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ dp: e.target.files[0] },
                      }))
                    }
                  ></Form.Control>
                </Form.Group>
              </Col> */}
              <Col xxl={12} xl={12} lg={12} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={patientProfile.address}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ address: e.target.value },
                      }))
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Name of Implant</Form.Label>
                  <Form.Control
                    type="text"
                    value={patientProfile.name_of_implant}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ name_of_implant: e.target.value },
                      }))
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Date Of Surgery</Form.Label>
                  <Form.Control
                    type="date"
                    value={patientProfile.date_of_surgery}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ date_of_surgery: e.target.value },
                      }))
                    }
                    max={maxDate()}
                  />
                  {/* {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3 mt-3">
                    <Form.Check
                      type={type}
                      id={`default-${type}`}
                      label={`NA`}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ name_of_implant: "NA" },
                        }))
                      }
                    />
                  </div>
                ))} */}
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Implanted Side</Form.Label>
                  <Form.Select
                    type="dropdown"
                    value={patientProfile.implanted_side}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ implanted_side: e.target.value },
                      }))
                    }
                  >
                    <option></option>
                    <option>Right</option>
                    <option>Left</option>
                    <option>Both</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Date Of Switch-on</Form.Label>
                  <Form.Control
                    type="date"
                    value={patientProfile.date_of_switch_on}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ date_of_switch_on: e.target.value },
                      }))
                    }
                    max={maxDate()}
                  />
                  {/* {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3 mt-3">
                    <Form.Check
                      type={type}
                      id={`default-${type}`}
                      label={`NA`}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ implanted_side: "NA" },
                        }))
                      }
                    />
                  </div>
                ))} */}
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>Age of Implantation</Form.Label>
                  <Form.Control
                    type="text"
                    value={patientProfile?.age_of_implantation}
                    onChange={(e) =>
                      setPatientProfile((patientProfile) => ({
                        ...patientProfile,
                        ...{ age_of_implantation: e.target.value },
                      }))
                    }
                    //max={maxDate()}
                  />
                  {/* {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3 mt-3">
                    <Form.Check
                      type={type}
                      id={`default-${type}`}
                      label={`NA`}
                      onChange={(e) =>
                        setPatientProfile((patientProfile) => ({
                          ...patientProfile,
                          ...{ age_of_implantation: "NA" },
                        }))
                      }
                    />
                  </div>
                ))} */}
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6} className="text-end">
                <Button
                  type="button"
                  className="btn-cancel"
                  onClick={cancelNameFunc}
                >
                  Cancel
                </Button>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <Button type="submit" className="btn-submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AddNameForm;
