import { Form, Button, Container, Col, Row, Alert } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESET_NEWPWD_STATUS, setNewPasswordAsync } from "../../features/login/registerSlice";
const NewpasswordForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const f_e = state => state.register.fpEmail
  const email = useSelector(f_e)

  const p_s = state => state.register.passwordSet
  const passwordSet = useSelector(p_s)

  function handleSubmit(e){
    e.preventDefault()
    setSuccess(false)
    setShow(false)
    if(password === confirmpassword){
      dispatch(setNewPasswordAsync({"email": email, "new_password": password}))
   
    }else{
      setSuccess(false)
      setShow(true)
     
    }

    
  }

  function handleCancel(e){
    e.preventDefault() 
    navigate("/login", "replace")
  }

  useEffect(()=>{
    dispatch(RESET_NEWPWD_STATUS())
    if(passwordSet === "pass"){
      //navigate("/newPassword")
      setSuccess(true)
    }
    if(passwordSet === "fail"){
      setShow(true)
    }

  },[dispatch,navigate,show,passwordSet])
  return (
    <>
      <div className="newpasswordTitle text-center">
        <h1>New Password</h1>
        <p>Please enter your new password</p>
        <Alert show={show} variant="danger" className="text-center">                  
                  <p className="mt-2">Password change failed! Please try again!</p>
        </Alert>
        <Alert show={success} variant="success" className="text-center">                  
                  <p className="mt-2">New Password is set! Click here to <Link to={"/login"}> Login</Link></p>
        </Alert>
      </div>
      <Form role="form" onSubmit={(e) => handleSubmit(e)}>
        <Container>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <Form.Group>
                <Form.Control
                  type="password"
                 
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <Form.Group>
                <Form.Control
                  type="password"
                
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm password"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
            <Button type="button" className="btn-cancel" onClick={e => handleCancel(e)}>
                Cancel
              </Button>
               <span className="p-1"> </span>
              <Button type="submit" className="btn-submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default NewpasswordForm;
