import { Table, Row, Col, Modal, Button } from "react-bootstrap";
import DeletePopupIcon from "../../global-images/DeletePopupIcon";
import { BsCheck2 } from "react-icons/bs";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../global-images/DeleteIcon";
import EditIcon from "../../global-images/EditIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_TEST_MSG,
  deleteTestAsync,
  getAllTestsAsync,
  CLEAR_LEVELS,
} from "../../features/tests/testSlice";
import { format } from "date-fns";

const TestLists = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const t_tests = (state) => state.test.tests;
  const tests = useSelector(t_tests);

  const t_message = (state) => state.test.message;
  const message = useSelector(t_message);

  const [testdeletemodal, setTestDeleteModal] = useState(false);
  const testdeletehandleClose = () => setTestDeleteModal(false);
  const testdeletehandleShow = () => setTestDeleteModal(true);

  const [deletesucessmodalshow, setdeletesucesssetModalShow] = useState(false);
  const deletesucesshandleClose = () => setdeletesucesssetModalShow(false);
  // const deletesucesshandleShow = () => setdeletesucesssetModalShow(true);

  const [deleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: "",
  });

  useEffect(() => {
    dispatch(getAllTestsAsync());
    const timeId = setTimeout(() => {
      setdeletesucesssetModalShow(false);
      dispatch(CLEAR_TEST_MSG());
    }, 3000);

    if (message === "Test delete successful") {
      console.log(message);
      setdeletesucesssetModalShow(true);
    }

    if (deleteConfirm.status) {
      ///  alert(deleteConfirm.id)
      dispatch(deleteTestAsync({ tid: deleteConfirm.id }));
      deleteConfirm.status = false;
    }

    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, deleteConfirm, message]);

  const testUpdateFunc = (tid) => {
    const testUpdatePath = "/updateTest/" + tid;
    navigate(testUpdatePath);
  };
  return (
    <>
      <Modal
        show={testdeletemodal}
        onHide={testdeletehandleClose}
        className="deleteTest text-center"
        centered
      >
        <Modal.Body>
          <DeletePopupIcon />
          <div className="deletePopUpTest">
            <h2>Are you sure?</h2>
            <p>
              Do you really want to delete the Test? This process cannot be
              undone.
            </p>
            <Button
              type="button"
              className="btn-test-delete-cancel"
              onClick={testdeletehandleClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn-test-delete"
              onClick={() => {
                setDeleteConfirm((deleteConfirm) => ({
                  ...deleteConfirm,
                  ...{ status: true },
                }));
                testdeletehandleClose();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* sucess popup */}
      <Modal
        show={deletesucessmodalshow}
        onHide={deletesucesshandleClose}
        className="deleteTestSucess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="deletePopTextAreaOnSuccess">
            <p>Test has been deleted successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="AdminTestListTable">
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Test Name</th>
                  <th>Levels</th>
                  <th>Question Type</th>
                  <th>Created Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(props?.searchTextValue
                  ? tests.filter(
                    (item) =>
                      item.name
                        .toLowerCase()
                        .startsWith(props?.searchTextValue)
                  )
                  : tests
              ).map((test) => {
                  return (
                    <tr key={test?.tid}>
                      {/* <td>{test?.tid}</td> */}
                      <td>{test?.name}</td>
                      <td>{test?.levels}</td>
                      <td>{test?.qtype == 1 ? "Audio With Image":"Audio Only"}</td>
                      <td>
                        {format(new Date(test?.created_at), "dd MMM yyyy")}
                      </td>
                      <td>
                        <span
                          onClick={() => {
                            testUpdateFunc(test?.tid);
                          }}
                        >
                          <EditIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            setDeleteConfirm((deleteConfirm) => ({
                              ...{ deleteConfirm },
                              ...{ id: test?.tid },
                            }));
                            testdeletehandleShow();
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </td>
                    </tr>
                  );
                })}
                {/* <tr>
                  <td>01</td>
                  <td>ESPT</td>
                  <td>3</td>
                  <td>Audio With Image</td>
                  <td>28 Mar 2022</td>
                  <td>
                    <span onClick={testUpdateFunc}>
                      <EditIcon />
                    </span>
                    <span onClick={setTestDeleteModal(true)}>
                      <DeleteIcon />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>02</td>
                  <td>ESPT 2</td>
                  <td>3</td>
                  <td>Audio With Image</td>
                  <td>30 Mar 2022</td>
                  <td>
                    <span onClick={testUpdateFunc}>
                      <EditIcon />
                    </span>
                    <span onClick={setTestDeleteModal(true)}>
                      <DeleteIcon />
                    </span>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TestLists;
