const DashboardIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
      <g>
        <path
          d="M130.4,114.2c0,3.5,0,7,0,10.5c0,3.4-2.3,5.6-5.6,5.6c-5.6,0-25.3,0-31,0c-3.3,0-5.6-2.3-5.6-5.6c0-7.1,0-30,0-37.1
		c0-3.3,2.3-5.6,5.6-5.6c5.6,0,25.4,0,31,0c3.3,0,5.5,2.3,5.5,5.6C130.4,91.2,130.4,110.7,130.4,114.2z M126.1,114.1
		c0-3.5,0-22.8,0-26.3c0-1.1-0.3-1.4-1.4-1.4c-5.5,0-25.1,0-30.6,0c-1.2,0-1.5,0.3-1.5,1.5c0,6.9,0,29.8,0,36.8
		c0,1.1,0.3,1.4,1.4,1.4c5.5,0,25.2,0,30.7,0c1.1,0,1.4-0.3,1.4-1.4C126.1,121.2,126.1,117.7,126.1,114.1z M130.4,67.7
		c0,3.5,0-1.9,0,1.6c0,3.4-2.3,5.6-5.6,5.6c-5.6,0-25.2,0-30.8,0c-3.3,0-5.6-2.3-5.6-5.6c0-7.1,0-16.9,0-24c0-3.3,2.3-5.6,5.6-5.6
		c5.6,0,25.3,0,30.9,0c3.3,0,5.5,2.3,5.5,5.6C130.4,48.9,130.4,64.2,130.4,67.7z M126.1,67.6c0-3.5,0-18.6,0-22.1
		c0-1.1-0.3-1.4-1.4-1.4c-5.5,0-25,0-30.5,0c-1.2,0-1.5,0.3-1.5,1.5c0,6.9,0,16.7,0,23.6c0,1.1,0.3,1.4,1.4,1.4c5.5,0,25,0,30.6,0
		c1.1,0,1.4-0.3,1.4-1.4C126.1,65.7,126.1,71.2,126.1,67.6z M81.8,123.1c0,3.5,0-1.9,0,1.6c0,3.4-2.3,5.6-5.6,5.6
		c-5.6,0-25.3,0-31,0c-3.3,0-5.6-2.3-5.6-5.6c0-7.1,0-17,0-24.1c0-3.3,2.3-5.6,5.6-5.6c5.6,0,25.4,0,31,0c3.3,0,5.5,2.3,5.5,5.6
		C81.8,104.2,81.8,119.6,81.8,123.1z M77.5,123.1c0-3.5,0-18.7,0-22.2c0-1.1-0.3-1.4-1.4-1.4c-5.5,0-25.1,0-30.6,0
		c-1.2,0-1.5,0.3-1.5,1.5c0,6.9,0,16.8,0,23.7c0,1.1,0.3,1.4,1.4,1.4c5.5,0,25.2,0,30.7,0c1.1,0,1.4-0.3,1.4-1.4
		C77.5,121.2,77.5,126.6,77.5,123.1z M81.9,55.9c0,3.5,0,22.8,0,26.3c0,3.4-2.3,5.6-5.6,5.6c-5.6,0-25.3,0-31,0
		c-3.3,0-5.6-2.3-5.6-5.6c0-7.1,0-29.9,0-36.9c0-3.3,2.3-5.6,5.6-5.6c5.6,0,25.4,0,31,0c3.3,0,5.5,2.3,5.5,5.6
		C81.9,48.8,81.9,52.4,81.9,55.9z M77.5,55.8c0-3.5,0-6.9,0-10.4c0-1.1-0.3-1.4-1.4-1.4c-5.5,0-25.1,0-30.6,0
		c-1.2,0-1.5,0.3-1.5,1.5c0,6.9,0,29.7,0,36.6c0,1.1,0.3,1.4,1.4,1.4c5.5,0,25.2,0,30.7,0c1.1,0,1.4-0.3,1.4-1.4
		C77.5,78.6,77.5,59.3,77.5,55.8z"
        />
      </g>
    </svg>
  );
};

export default DashboardIcon;
