import { Row, Col, Table, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ViewIcon from "../../global-images/ViewIcon";
import { DateRangePicker } from "rsuite";
import "../../../node_modules/rsuite/dist/rsuite.css";
import PrintIcon from "../../global-images/PrintIcon";
import PdfIcon from "../../global-images/PdfIcon";
import ViewReportDetails from "./ViewReportDetails";
import { useRef, useState, useEffect, React } from "react";
import { getAllPatientsAsync } from "../../features/patient/patientSlice";
import { getAllUsersAsync } from "../../features/login/loginSlice";
import { getAllTestsAsync, testResultAsync } from "../../features/tests/testSlice";
import { useDispatch, useSelector } from "react-redux";
import { calculateAge } from "../../utils";

const ReportLists = (props) => {
  const p_patients = (state) => state.patient.patients;
  const patients = useSelector(p_patients);
  const p_profiles = (state) => state.login.users;
  const userDetails = useSelector(p_profiles);

  const t_tests = (state) => state.test.tests;
  const tests = useSelector(t_tests);
  const t_r = (state) => state.test.results;
  const testResults = useSelector(t_r);

  const reportViewBtn = useNavigate();
  const dispatch = useDispatch();
  //Filter variables
  const [selectDateRange, setSelectDateRange] = useState("");
  const [selectAudiologist, setSelectAudiologist] = useState("");
  const [selectPatientName, setSelectPatientName] = useState("");
  const [selectPatientAge, setSelectPatientAge] = useState("");
  const [selectImplmentSide, setSelectImplmentSide] = useState("");
  const [selectTest, setSelectTest] = useState("");

  const reportViewBtnFunc = (patientId) => {
    const reportViewBtnPath = "/viewReport/" + patientId;
    reportViewBtn(reportViewBtnPath);
  };
 const clearFilter = () => {
    console.log("fill")
    setSelectDateRange("");
    setSelectAudiologist("");
    setSelectPatientName("");
    setSelectPatientAge("");
    setSelectImplmentSide("");
    setSelectTest("");
  };
  const filterReportsArray = (patient) => {

    if (selectDateRange) {
      try {
        //selected start date
        let sd = new Date(selectDateRange[0]).getTime();
        //selected end date
        let ed = new Date(selectDateRange[1]).getTime();
        // console.log("sd", sd)
        // console.log("end", ed)
        return patient.filter(d => {
          var updatedDate = new Date(d?.updated_at).getTime();
          // console.log("up", updatedDate)
          // return (sd <= updatedDate && updatedDate <= ed);
          return (updatedDate >= sd && updatedDate <= ed);
        });

      } catch (e) {
        console.log('error while filtering by dates', e);
      }
    } else if (selectAudiologist) {
      return patient.filter((item) => item?.created_by === selectAudiologist);
    } else if (selectPatientName) {
      return patient.filter((item) => item?.name === selectPatientName);
    } else if (selectPatientAge) {
      return patient.filter((item) => calculateAge(item?.dob) == selectPatientAge);
    } else if (selectImplmentSide) {
      return patient.filter((item) => item?.implanted_side?.toLowerCase() === selectImplmentSide?.toLowerCase());
    } else if (selectTest) {
      let PatientList = testResults.filter(item => item.test?.includes(selectTest)).map(item => item.patient_id);
      // return patient.filter((item) => (PatientList.filter(item2 => item2 == item?.id)));
      return patient.filter((item) => PatientList.includes(item?.id));
    } else {
      return patient;
    }
  };

  useEffect(() => {
    dispatch(getAllPatientsAsync());
    dispatch(getAllUsersAsync());
    dispatch(getAllTestsAsync());
    dispatch(testResultAsync());
    // console.log("all", patients);
    // console.log("userDetails", userDetails)
    if(props?.clearFilter){
      clearFilter();
    }
    return () => { };
  }, [props]);

  return (
    <>
      <div className="reportSearch">
        <Row>
          <Col xxl={3} xl={3} lg={2} md={2}>
            <DateRangePicker
              onOk={(e) => {
                console.log(e)
                setSelectDateRange(e);
                setSelectAudiologist("");
                setSelectPatientName("");
                setSelectPatientAge("");
                setSelectImplmentSide("");
                setSelectTest("");
              }}
              placeholder="Select Date Range"
              format="dd-MM-yyyy"
              character=" to "
              value={selectDateRange}
            />
          </Col>
          <Col xxl={2} xl={2} lg={2} md={2}>
            <Form.Select onChange={(e) => {
              setSelectAudiologist(e.target.value);
              setSelectPatientName("");
              setSelectPatientAge("");
              setSelectDateRange("");
              setSelectImplmentSide("");
              setSelectTest("");
            }} value={selectAudiologist}>
              <option value="">Audiologist</option>
              {(userDetails?.filter((item) => item?.role == 4
              ))?.map((user) => {
                return (
                  <>
                    <option key={user?.id} value={user?.email}>
                      {user?.name}
                    </option>
                  </>
                );
              })}
            </Form.Select>
          </Col>
          <Col xxl={2} xl={2} lg={2} md={2}>
            <Form.Select onChange={(e) => {
              setSelectPatientName(e.target.value);
              setSelectAudiologist("");
              setSelectPatientAge("");
              setSelectDateRange("");
              setSelectImplmentSide("");
              setSelectTest("");
            }} value={selectPatientName}>
              <option value="">Patient Name</option>
              {patients?.map((patient) => {
                return (
                  <>
                    <option key={patient?.id} value={patient?.name}>
                      {patient?.name}
                    </option>
                  </>
                );
              })}
            </Form.Select>
          </Col>
          <Col xxl={1} xl={1} lg={2} md={2}>
            <Form.Select onChange={(e) => {
              setSelectPatientAge(e.target.value);
              setSelectPatientName("");
              setSelectAudiologist("");
              setSelectDateRange("");
              setSelectImplmentSide("");
              setSelectTest("");
            }} value={selectPatientAge}>
              <option value="">Age</option>
              {[...new Set(patients?.map((m) => calculateAge(m.dob))?.sort((a, b) => (a - b)))]?.map((patient) => {
                return (
                  <>
                    <option key={patient} value={patient}>
                      {patient}
                    </option>
                  </>
                );
              })}
            </Form.Select>
          </Col>
          <Col xxl={2} xl={2} lg={2} md={2}>
            <Form.Select onChange={(e) => {
              setSelectImplmentSide(e.target.value);
              setSelectPatientName("");
              setSelectAudiologist("");
              setSelectDateRange("");
              setSelectTest("");
            }} value={selectImplmentSide}>
              <option value="">Implanted Side</option>
              <option value="Right">Right</option>
              <option value="Left">Left</option>
              <option value="Both">Both</option>
            </Form.Select>
          </Col>
          <Col xxl={2} xl={2} lg={2} md={2}>
            <Form.Select onChange={(e) => {
              setSelectTest(e.target.value);
              setSelectPatientName("");
              setSelectAudiologist("");
              setSelectDateRange("");
              setSelectImplmentSide("");
            }} value={selectTest}>
              <option value="">Test</option>
              {tests?.map((test) => {
                return (
                  <>
                    <option key={test?.tid} value={test?.name}>
                      {test?.name}
                    </option>
                  </>
                );
              })}
            </Form.Select>
          </Col>
          {/* <Col xxl={2} xl={2} lg={2} md={2}>
            <Button
              type="button"
              className="btn-test-delete-cancel"
              onClick={clearFilter}
            >
              Clear Filter
            </Button>
          </Col> */}
        </Row>
      </div>
      <Row>
        <Col xxl={12} xl={12} lg={12} md={12}>
          <Table responsive>
            <thead>
              <tr>
                {/* <th>ID</th> */}
                <th>Name</th>
                <th>Age</th>
                <th>Phone</th>
                <th>Name of Implant</th>
                <th>Implanted Side</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {patients?.length > 0 && filterReportsArray(patients)?.map((patient, index) => {
                return (
                  <tr key={index}>
                    {/* <td>{patient.id}</td> */}
                    <td>{patient.name}</td>
                    <td>{calculateAge(patient.dob)}</td>
                    <td>{patient.phone}</td>
                    <td>{patient.name_of_implant}</td>
                    <td>{patient.implanted_side}</td>
                    <td>
                      <span onClick={() => reportViewBtnFunc(patient.id)}>
                        <ViewIcon />
                      </span>
                      {/* <span onClick={() => handlePdf()}>
                        <PdfIcon />
                      </span>
                      <span onClick={() => handlePrint()}>
                        <PrintIcon />
                      </span> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ReportLists;
