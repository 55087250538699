import { Form, Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import profilePic from "../../global-images/profilePic.svg";
import { BsCheck2 } from "react-icons/bs";
import {
  CLEAR_USR_MSG,
  getAllUsersAsync,
  getUserAsync,
  updateUserAsync,
} from "../../features/login/loginSlice";
import { userRole } from "../../constants";

const UpdateUserForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const p_users = (state) => state.login.users;
  const currentUser = useSelector(p_users);
  const user = currentUser.filter(
    (u) => parseInt(u.id) === parseInt(props?.id)
  )[0];

  const fileval = useRef();

  const msg = (state) => state.login.message;
  const message = useSelector(msg);

  // const p_profile = (state) => state.login.profile;
  // const profile = useSelector(p_profile);

  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);

  const userCancelBtnFunc = () => {
    const userCancelBtnPath = "/users";
    navigate(userCancelBtnPath);
  };

  const [validated, setValidated] = useState(false);
  function userUpdateFunc(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (
      userProfile.name == "" ||
      userProfile.email == "" ||
      userProfile.phone == "" ||
      userProfile.phone.length != 10 ||
      userProfile.gender == "" ||
      userProfile.password == ""
    ) {
      return;
    }

    //dispatch(CLEAR_MSG())
    console.log(userProfile);
    var frm = new FormData();
    frm.append("id", userProfile.id);
    frm.append("name", userProfile.name);
    frm.append("gender", userProfile.gender);
    frm.append("address", userProfile.address);
    frm.append("phone", userProfile.phone);
    frm.append("role", userProfile.role);
    frm.append("email", userProfile.email);
    frm.append("status", userProfile?.status);
    console.log(fileval.current);
    if (fileval.current.value === "") {
      // frm.append("dp", pic)
      console.log("No change ");
      frm.append("dp", "");
    } else {
      // alert(pic)
      //frm.append("dp", "")
      //console.log("change " + userProfile?.dp)
      frm.append("dp", userProfile?.dp);
    }

    console.log(frm);

    dispatch(updateUserAsync(frm));
    setaddsucesssetModalShow(false);
    dispatch(CLEAR_USR_MSG());
  }

  const [userProfile, setUserProfile] = useState({
    id: "",
    name: "",
    email: "",
    gender: null,
    phone: "",
    address: null,
    role: 2,
    dp: "",
  });

  //const [userProfile, setUserProfile] = useState(user)
  //setUserProfile(userProfile =>({...userProfile,...user}))
  //setUserProfile({...userProfile,...user});
  //console.log(pic)

  useEffect(() => {
    // dispatch(getAllUsersAsync())
    const timeId = setTimeout(() => {
      if (message === "update successful") {
        setaddsucesssetModalShow(false);
        dispatch(CLEAR_USR_MSG());
        userCancelBtnFunc();
      }
    }, 1000);

    const u = {
      id: user?.id,
      name: user?.name,
      email: user?.email,
      gender: user?.gender,
      phone: user?.phone,
      address: user?.address,
      role: user?.role,
      status: user?.status,
      dp: user?.dp,
    };
    setUserProfile((userProfile) => ({ ...userProfile, ...u }));

    //console.log(user)
    //setUserProfile({...userProfile,...user});

    if (message === "update successful") {
      setaddsucesssetModalShow(true);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, user, props, message]);

  return (
    <>
      <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addUserSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addUserPopTextAreaOnSuccess">
            <p>User has been updated successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="userUpdateFormField">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={3}>
            <div className="ProfilePicSection">
              {
                // alert(pic)
                userProfile.dp === "" ||
                userProfile.dp === undefined ||
                userProfile.dp === null ? (
                  <img
                    src={profilePic}
                    className="img-fluid usersProfilePicture"
                    alt="profile-pic"
                  />
                ) : (
                  <img
                    src={userProfile?.dp}
                    className="img-fluid usersProfilePicture"
                    alt="profile-pic"
                  />
                )
              }
            </div>
            <Form.Group className="mt-2">
              <Form.Label className="text-center d-block fw-bold">
                User Profile Picture
              </Form.Label>
              <Form.Control
                type="file"
                ref={fileval}
                onChange={(e) =>
                  setUserProfile((piuserProfilec) => ({
                    ...userProfile,
                    ...{ dp: e.target.files[0] },
                  }))
                }
              ></Form.Control>
            </Form.Group>
            {/* <div className="text-center">
              <Button type="button" className="btn-submit">
                Save
              </Button> 
            </div>*/}
          </Col>
          <Col xxl={9} xl={9} lg={9} md={9}>
            <Form
              role="form"
              onSubmit={(e) => userUpdateFunc(e)}
              noValidate
              validated={validated}
            >
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Name<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={userProfile?.name}
                      onChange={(e) => {
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ name: e.target.value },
                        }));
                      }}
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Role<span>*</span>
                    </Form.Label>
                    <Form.Select
                      type="text"
                      value={userProfile?.role}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ role: e.target.value },
                        }))
                      }
                      required
                    >
                      {userRole.map((el, index) => {
                        return <option value={index}>{el}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Email ID<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      value={userProfile?.email}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ email: e.target.value },
                        }))
                      }
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Email is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Phone<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      value={userProfile?.phone}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ phone: e.target.value.replace(/[^0-9.]/g, "") },
                        }))
                      }
                      required
                      pattern="^[123456789]\d{9,9}$"
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Enter the Valid 10 digit Mobile Number
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Gender<span>*</span>
                    </Form.Label>
                    <Form.Select
                      type="dropdown"
                      value={userProfile?.gender}
                      required
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ gender: e.target.value },
                        }))
                      }
                    >
                      <option value={""}></option>
                      <option value={"M"}>Male</option>
                      <option value={"F"}>Female</option>
                      <option value={"O"}>Others</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Gender is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      type="dropdown"
                      value={userProfile?.status}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ status: e.target.value },
                        }))
                      }
                    >
                      <option value={0}>Inactive</option>
                      <option value={1}>Active</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      value={userProfile?.address}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ address: e.target.value },
                        }))
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} className="text-end">
                  <Button
                    type="button"
                    className="btn-cancel"
                    onClick={userCancelBtnFunc}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Button type="submit" className="btn-submit">
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UpdateUserForm;
