import { useDispatch, useSelector } from "react-redux";
import userAvatar from "./user-avatar.svg";
import UserDate from "./UserDate";
import UserTime from "./UserTime";
import { userRole } from "../../constants";
import { useEffect } from "react";
import { getUserAsync } from "../../features/login/loginSlice";

const UserDetails = () => {
  const dispatch = useDispatch();

  const u_id = (state) => state.login.id;
  const uid = useSelector(u_id);

  const user = (state) => state.login.profile;
  const userProfile = useSelector(user);
  //console.log(uid)
  //console.log(userProfile?.dp)

  useEffect(() => {
    dispatch(getUserAsync({ userid: uid }));
  }, [dispatch, uid]);

  return (
    <>
      <div className="mt-2 userDetails text-center">
        <img
          src={userProfile?.dp === null ? userAvatar : userProfile?.dp}
          alt="user-avatar"
          className="userAvatar"
        />
        <h3>{userProfile?.name}</h3>
        {/* <h6> {userRole[parseInt(userProfile?.role)]}</h6> */}
        <div className="userDate-Time">
          <ul>
            <li>
              <UserDate />
            </li>
            <li>
              <UserTime />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
