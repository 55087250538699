import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import LeftSidebar from "../components/sidebar/LeftSidebar";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import ViewReportDetails from "../components/report/ViewReportDetails";
import "../components/report/report.css";
import logo from "../global-images/spetra-logo.svg";
import ToggleMenu from "../global-images/ToggleMenu";
const ViewReport = () => {
  const viewHeight = window.innerHeight;
  console.log(viewHeight);
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };
  const contentStyle = {
    marginLeft: sidebarOpen ? "250px" : "0px",
    transition: "margin 0.2s ease",
    borderTopLeftRadius: sidebarOpen ? "40px" : "0px",
  };
  const BackButton = useNavigate();
  const BackButtonFunc = () => {
    const BackBtn = "/report";
    BackButton(BackBtn);
  };
  return (
    <>
      <div className="spetraLayout">
        <Container fluid>
          <Row>
            <div className="SideBar">
              <LeftSidebar
                isOpen={sidebarOpen}
                toggleSidebar={handleViewSidebar}
              />
            </div>
            <div className="main">
              <div className="mainContentboxshadow" style={contentStyle}>
                <div
                  className="ContentMainBody"
                  style={{
                    minheight: viewHeight,
                    maxheight: viewHeight,
                  }}
                >
                  <Container fluid>
                    <Row>
                      <Col xxl={3} xl={3} lg={3} md={4}>
                        <span
                          onClick={handleViewSidebar}
                          className="toggleMenu"
                        >
                          <ToggleMenu />
                        </span>

                        {(() => {
                          if (!sidebarOpen) {
                            return (
                              <img
                                src={logo}
                                alt="spetra-logo"
                                className="sidebarOpenLogo"
                              />
                            );
                          } else {
                            return "";
                          }
                        })()}
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={4} className="text-center">
                        <h1>View Report</h1>
                      </Col>
                      <Col xxl={3} xl={3} lg={3} md={4} className="text-end">
                        <Button
                          type="button"
                          className="btn-back"
                          onClick={BackButtonFunc}
                        >
                          <BsArrowLeft />
                          Back
                        </Button>
                      </Col>
                    </Row>
                    <ViewReportDetails />
                  </Container>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ViewReport;
