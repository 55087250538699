const PdfIcon = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <g>
          <path
            d="M48.7,85c0-11.9,0-23.8,0-35.7c0-4.1,1.7-7.1,5.5-8.8c1.2-0.5,2.5-0.7,3.8-0.7c11.8-0.1,23.5,0,35.3,0c0.5,0,1,0.2,1.4,0.6
          c8.7,8.7,17.4,17.4,26.1,26.1c0.3,0.3,0.5,0.9,0.5,1.3c0,17.8,0,35.6,0,53.5c0,5.4-3.9,9.2-9.3,9.2c-18,0-36,0-54,0
          c-5.5,0-9.3-3.9-9.3-9.6C48.7,108.9,48.7,96.9,48.7,85z M89.6,49.2c0,7.3,0,14.7,0,22.1c7.5,0,15,0,22.1,0
          C104.4,64,97,56.6,89.6,49.2z M78,112.4c2.4,0,4.7,0.2,7,0c5.5-0.6,8.2-3.1,8.8-8c0.7-5.4-2-9.4-7.3-10c-2.5-0.3-5.2,0-7.7,0
          c-0.7,0-0.9,0.3-0.9,1c0,3.2,0,6.3,0,9.5C78,107.3,78,109.7,78,112.4z M66.4,106.2c1.9-0.3,3.6-0.4,5.2-0.9c2.7-0.9,4-3.1,3.7-6.1
          c-0.2-2.5-1.9-4.5-4.6-4.8c-2.4-0.3-4.9,0-7.4-0.1c-0.8,0-1,0.4-1,1.1c0,5.3,0,10.6,0,15.8c0,0.3,0,0.6,0.1,0.9c1.4,0,2.6,0,4,0
          C66.4,110.1,66.4,108.1,66.4,106.2z M107.7,97.6c0-1.2,0-2.2,0-3.3c-3.7,0-7.3,0-10.9,0c0,6,0,12,0,18c1.4,0,2.6,0,4.1,0
          c0-2.4,0-4.8,0-7.2c2.2,0,4.3,0,6.4,0c0-1.1,0-2.2,0-3.3c-2.2,0-4.3,0-6.4,0c0-1.4,0-2.7,0-4.1C103.1,97.6,105.4,97.6,107.7,97.6z"
          />
          <path d="M82.1,109.3c0-4,0-7.8,0-11.7c2.2-0.7,4.9-0.1,6.2,1.5c1.4,1.7,1.7,5.3,0.5,7.5C87.4,109.2,84.9,109.5,82.1,109.3z" />
          <path
            d="M66.4,103c0-1.9,0-3.5,0-5c0-0.2,0.4-0.6,0.6-0.6c0.9,0,1.8,0,2.6,0.2c1,0.3,1.6,1,1.6,2.1c0.1,1.2-0.3,2.3-1.4,2.7
          C68.8,102.7,67.7,102.7,66.4,103z"
          />
        </g>
      </svg>
    </>
  );
};

export default PdfIcon;
