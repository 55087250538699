const PatientGroupIcon = () => {
  return (
    <svg viewBox="0 0 39.741 25.188">
      <defs></defs>
      <path
        d="M34.639,70.952c.079-.434.158-.867.276-1.3a7.841,7.841,0,0,1,4.77-5.362l.237-.118a4.606,4.606,0,0,1-.552-6.939,4.539,4.539,0,1,1,5.914,6.86c.828.513,1.7.986,2.563,1.5A17.048,17.048,0,0,1,49.5,64.525c.591-.315,1.222-.552,1.853-.828a5.777,5.777,0,0,1-.71-9.225,5.748,5.748,0,1,1,6.742,9.265,10.161,10.161,0,0,1,3.588,1.932c.907-.552,1.774-1.064,2.681-1.577a4.456,4.456,0,0,1-1.814-4.061,4.3,4.3,0,0,1,1.538-3,4.532,4.532,0,0,1,6.229.276,4.6,4.6,0,0,1-.591,6.821,8.137,8.137,0,0,1,5.322,8.082h-9.7a6.438,6.438,0,0,1,.039,1.222,7.628,7.628,0,0,1-.237,1.932,4.06,4.06,0,0,1-3.982,2.878c-4.021.039-8.043.039-12.1,0a4.155,4.155,0,0,1-4.218-4.337V72.253c-.039,0-.158-.039-.237-.039H34.6C34.639,71.819,34.639,71.385,34.639,70.952Zm19.752,6.071h1.577c1.616,0,3.193.039,4.81-.039a2.822,2.822,0,0,0,2.6-2.523,9.169,9.169,0,0,0-1.853-6.426A8.993,8.993,0,0,0,45.521,72.45a7.669,7.669,0,0,0,0,2.326,2.859,2.859,0,0,0,3,2.247Zm.039-13.759a4.455,4.455,0,1,0-4.455-4.416A4.392,4.392,0,0,0,54.431,63.264ZM62,66.575A10.548,10.548,0,0,1,64.248,70.6a.452.452,0,0,0,.513.394H73.04a6.581,6.581,0,0,0-4.3-5.52A6.486,6.486,0,0,0,62,66.575Zm-15.139-.039a6.476,6.476,0,0,0-7.175-.867A6.549,6.549,0,0,0,35.9,70.952c.158,0,.237.039.315.039H44.18c.237,0,.315-.118.394-.315.394-.907.789-1.774,1.222-2.641C46.112,67.482,46.506,67.048,46.861,66.536ZM69.61,60.622A3.256,3.256,0,1,0,66.258,63.7,3.257,3.257,0,0,0,69.61,60.622ZM45.8,60.267a3.257,3.257,0,0,0-6.505.355,3.258,3.258,0,1,0,6.505-.355Z"
        transform="translate(-34.6 -53.087)"
      />
    </svg>
  );
};

export default PatientGroupIcon;
