import { Button } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const AddUserButton = () => {
  const addUserBtn = useNavigate();
  const addUserBtnFunc = () => {
    const addUserBtnpath = "/addUser";
    addUserBtn(addUserBtnpath);
  };
  return (
    <>
      <Button type="button" className="btn-add" onClick={addUserBtnFunc}>
        <BsPlusLg />
        Add User
      </Button>
    </>
  );
};

export default AddUserButton;
