export const baseURL = "https://api.spetra.in/"; //"https://api.spetra.in/"; //"http://18.235.135.114/"; // "http://127.0.0.1:8000/";
export const s3URL = "https://spetrastoragev1.s3.ap-south-1.amazonaws.com/";
export const userRegStatus = ["Inactive", "Active", "Submitted"];
export const userRole = [
  "NA",
  "Super Admin",
  "Admin",
  "Support",
  "Audiologist",
  "Other",
];
  