import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Image,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsCheck2 } from "react-icons/bs";

import QuestionBank from "./QuestionBank";
import { useDispatch, useSelector } from "react-redux";
import { TestLevel } from "./Levels";
import {
  addTestAsync,
  CLEAR_LEVELS,
  getTestDetailsAsync,
  SET_LEVEL,
  ADD_LEVEL,
  REMOVE_LEVELS,
  updateTestAsync,
  CLEAR_TEST_MSG,
} from "../../features/tests/testSlice";
import { getAllQuestionsAsync } from "../../features/media/mediaSlice";
import { useRef } from "react";
import { isEqual } from "rsuite/esm/utils/dateUtils";
import { getTestDetails } from "../../features/tests/testAPI";
import { useCallback } from "react";

const UpdateTestForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const q_questions = (state) => state.media.questions;
  const questions = useSelector(q_questions);
  const q_level = (state) => state.test.currentLevels;
  const currLevels = useSelector(q_level);

  const q_testDetails = (state) => state.test.currentTestDetails;
  const currTestDetails = useSelector(q_testDetails);

  const t_message = (state) => state.test.message;
  const message = useSelector(t_message);

  const [currTest, setCurrTest] = useState({
    name: "",
    qtype: 0,
    levels: [],
    trial: {},
  });

  const [testName, setTestName] = useState("");
  const [apiCall, setApiCall] = useState(true);

  const [trialQ, setTrialQ] = useState({
    level_name: "trial",
    qids: [],
  });

  function handleSave(e) {
    e.preventDefault();
    const lvls = [];
    currLevels.forEach((element) => {
      let q = [];
      element.qids.forEach((el) => q.push(parseInt(el)));
      // alert(q)
      lvls.push({ level: element.id, level_name: element?.level_name, qid: q });
    });

    const addTest = {
      tid: params?.tid,
      name: testName || currTestDetails?.name,
      qtype: currTest?.qtype > 0 ? currTest?.qtype : currTestDetails?.qtype,
      levels: lvls,
      trial: {
        qid: [],
        level_name: testName + "_trial",
      },
    };
    console.log("add test", addTest);
    //  alert(JSON.stringify(addTest))
    dispatch(updateTestAsync(addTest));
    setApiCall(true);
    console.log("Test updated message");
    setaddsucesssetModalShow(true);
  }

  const testCancelBtn = useNavigate("");
  const testCancelBtnFunc = () => {
    const testCancelBtnUrl = "/test";
    testCancelBtn(testCancelBtnUrl);
  };
  const [levels, setLevels] = useState([{}]);
  const addNewLevel = () => {
    let addLevel;
    if (!levels[0].id) {
      let id = currTestDetails?.levels.length
        ? currTestDetails.levels[currTestDetails?.levels.length - 1].level + 1
        : 1;
      addLevel = { id };
      setLevels((pre) => [{ id: id }]);
    } else {
      const id = levels.length ? levels[levels.length - 1].id + 1 : 1;
      addLevel = { id };
      const addLevelField = [...levels, addLevel];
      setLevels(addLevelField);
    }

    dispatch(SET_LEVEL(addLevel));
  };

  const addLevelHandler = (e) => {
    e.preventDefault();
    // addNewLevel();
    dispatch(
      ADD_LEVEL({
        id: currLevels[currLevels.length - 1].id + 1,
        level_name: "",
        qids: [],
      })
    );
  };

  function handleRemoveLevel(id) {
    dispatch(REMOVE_LEVELS(id));
  }

  const loadLevels = () => {
    try {
      setTestName(currTestDetails?.name);
      console.log("currTestDetails", currTestDetails);
      currTestDetails?.levels.forEach((l, i) => {
        dispatch(
          ADD_LEVEL({ id: l?.level, level_name: l?.level_name, qids: l?.qid })
        );
      });
    } catch (e) {
      console.log("loadLevels e", e);
    }
  };

  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);
  //const addsucesshandleShow = () => setaddsucesssetModalShow(true);

  useEffect(() => {
    if (params?.tid > 0 && apiCall) {
      dispatch(getTestDetailsAsync({ tid: params?.tid }));
      dispatch(getAllQuestionsAsync());
      setApiCall(false);
    }
    // console.log("currTestDetails",currTestDetails)
    if (currTestDetails?.name) {
      dispatch(CLEAR_LEVELS());
      loadLevels();
    }

    // let tstLvl = []
    // currTestDetails?.levels.forEach(l => {
    //   tstLvl.push(l?.level)
    // })
    // setLevels(levels=> ({...levels,tstLvl}))
    // console.log(tstLvl)
    // console.log(message);
    // const timeId = setTimeout(() => {
    //   setaddsucesssetModalShow(false);
    // }, 3000);
    if (message === "update successful") {
      setaddsucesssetModalShow(true);
    }
    const timeId = setTimeout(() => {
      if (message === "update successful") {
        setaddsucesssetModalShow(false);
        dispatch(CLEAR_TEST_MSG());
        testCancelBtnFunc();
      }
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, params, addsucessmodalshow, currTestDetails, message]);

  return (
    <>
      {/* Question Bank Modal*/}

      <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addTestSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addTestPopTextAreaOnSuccess">
            <p>Test has been updated sucessfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <Form className="addTestForm">
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <h2>Edit Test</h2>
          </Col>
        </Row>
        <Row>
          <Col xxl={6} xl={6} lg={6} md={6}>
            <Form.Group className="margin-bottom-20">
              <Form.Label>
                Test Type<span>*</span>
              </Form.Label>
              <Form.Select
                type="dropdown"
                defaultValue={currTestDetails?.qtype}
                // disabled={true}
                onChange={(e) =>
                  setCurrTest((currTest) => ({
                    ...currTest,
                    ...{ qtype: e.target.value },
                  }))
                }
                required
              >
                {/* <option></option> */}
                <option value={1}>Audio with Image</option>
                <option value={2}>Audio Only</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6}>
            <Form.Group className="margin-bottom-20">
              <Form.Label>
                Test Name<span>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={currTestDetails?.name}
                onChange={(e) => {
                  setTestName(e.target.value);
                }}
                required
              ></Form.Control>

              {/* <Button
                type="button"
                className="test-add-level"
                onClick={loadLevels}
                >
                   Load Questions
                </Button> */}
            </Form.Group>
          </Col>

          <Col xxl={12} xl={12} lg={12} md={12}>
            <ul>
              {/* level Component */}
              {currLevels.map((lvl) => (
                <TestLevel
                  id={lvl?.id}
                  name={lvl?.level_name}
                  qids={lvl?.qids}
                  type={currTest?.qtype > 0 ? currTest?.qtype : currTestDetails?.qtype}
                  handleRemoveLevel={handleRemoveLevel}
                  showRemoveButton={(currTest?.qtype > 0 ? currTest?.qtype : currTestDetails?.qtype) == 1 ? true : false}
                />
              ))}
            </ul>
          </Col>

          {/* <Col xxl={12} xl={12} lg={12} md={12} >
              <ul>
                {levels[0].id && levels.map((level) => (
                  <TestLevel id={level?.id} name={level?.name} handleRemoveLevel={handleRemoveLevel} showRemoveButton={true} />
                ))}
              </ul>
            </Col> */}

          {(currTest?.qtype > 0 ? currTest?.qtype : currTestDetails?.qtype) == 1 && (<>
            <Col xxl={12} xl={12} lg={12} className="text-end">
              <Button
                type="button"
                className="test-add-level"
                onClick={addLevelHandler}
              >
                Add Level
              </Button>
            </Col>
          </>)}


          {/* <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <Form.Group>
                <Form.Label>
                  Add Trial Questions<span>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  value={currTest.trial.qids}
                  onChange={(e) => setTrialQ(trialQ => ({ ...{ trialQ }, ...{ qids: e.target.value } }))}
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
            </Row> */}
          <Col xxl={12} xl={12} lg={12} className={(currTest?.qtype > 0 ? currTest?.qtype : currTestDetails?.qtype) == 1 ? "text-end":"text-center"}>
            <Button
              type="button"
              className="btn-cancel"
              onClick={testCancelBtnFunc}
            >
              Cancel
            </Button>
            <Button type="button" className="btn-submit" onClick={handleSave}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default UpdateTestForm;
