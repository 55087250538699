import { useEffect } from "react";
import { useState } from "react";
import { Alert, Form, Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetPasswordAsync,
  SET_FP_EMAIL,
  RESET_FP_STATUS,
} from "../../features/login/registerSlice";

const ResetForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const s_t = (state) => state.register.pwdMailSent;
  const status = useSelector(s_t);

  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  //alert(status)
  const passwordResetClick = (e) => {
    e.preventDefault();
    dispatch(resetPasswordAsync({ email: email }));
    dispatch(SET_FP_EMAIL(email));
  };

  const backToLoginPage = () => {
    const backToLoginPath = "/login";
    navigate(backToLoginPath);
  };

  useEffect(() => {
    console.log("status " + status);

    if (status === "mail_sent") {
      dispatch(SET_FP_EMAIL(email));
      navigate("/verify");
      setShow(false);
    } else if (status === "mail_failed") {
      setShow(true);
    } else {
      setShow(false);
    }

    return () => {
      if (status === "mail_failed" || status === "mail_sent") {
        dispatch(RESET_FP_STATUS());
      }
    };
  }, [dispatch, navigate, status, setShow, email]);

  return (
    <>
      <div className="resetFormTitle text-center">
        <h1>Forgot Password?</h1>
        <Alert show={show} variant="danger" className="text-center">
          <p className="mt-2">Email not sent! Please Enter a valid email</p>
        </Alert>
        <p>
          Enter your email address and we will send you a link to reset your
          password
        </p>
      </div>
      <Form className="resetForm">
        <Container>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
              <Button
                type="submit"
                onClick={(e) => passwordResetClick(e)}
                className="btn-submit"
              >
                Submit
              </Button>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              className="backtoLogin text-center"
            >
              <p>
                Click here to <span onClick={backToLoginPage}>Login</span>
              </p>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default ResetForm;
