import logo from "../../global-images/spetra-logo.svg";
import "./sidebar.css";
import UserDetails from "./UserDetails";
import SideNav from "./SideNav";
import Logout from "./Logout";
import { useDispatch } from "react-redux";
import {
  FETCH_CONTENT_TERMS,
  FETCH_CONTENT_PRIVACY,
} from "../../features/terms/termsSlice";
const LeftSidebar = (props) => {
  const dispatch = useDispatch();
  const handleTerms = () => {
    dispatch(FETCH_CONTENT_TERMS());
  };
  const handlePrivacy = () => {
    dispatch(FETCH_CONTENT_PRIVACY());
  };
  const sidebarClass = props.isOpen ? "leftSidebar open" : "leftSidebar";
  return (
    <>
      <div className={sidebarClass}>
        <img src={logo} alt="spetra-logo" className="leftSidebarLogo" />
        <UserDetails />
        <SideNav />
        <Logout />
        <div className="text-center sideBarTermsMenu">
          <p onClick={handleTerms}>Terms &amp; Conditions</p>
          <p onClick={handlePrivacy}>Privacy Policy</p>
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;
