import {baseURL}  from "../../constants"

export function registerUser(profile= {}) {
    const requestOptions = {
            method: 'POST',
            headers: {},
            body: profile
            
        }
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "api/register/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }

  export function forgotPassword(profile= {}) {
    const requestOptions = {
            method: 'POST',
            headers: {"Content-Type": "application/json" },
            body: JSON.stringify(profile)
            
        }
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "api/password/forgot/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }

  export function verifyCode(code= {}) {
    const requestOptions = {
            method: 'POST',
            headers: {"Content-Type": "application/json" },
            body: JSON.stringify(code)
            
        }
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "api/password/verify/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }

  export function setNewPassword(password= {}) {
    const requestOptions = {
            method: 'POST',
            headers: {"Content-Type": "application/json" },
            body: JSON.stringify(password)
            
        }
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "api/password/reset/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }