import { Form, Button, Container, Col, Row ,Alert} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyCodeAsync } from "../../features/login/registerSlice";
import { Link, useNavigate } from "react-router-dom";


const CodeConfirmationForm = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const f_e = state => state.register.fpEmail
  const email = useSelector(f_e)
   
  const c_v = state => state.register.codeVerify
  const codeVerification = useSelector(c_v)

  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);

  function verifyCode(e){
    e.preventDefault()
   dispatch(verifyCodeAsync({"email": email, "verification_code": code}))
  }
  function handleCancel(e){
    e.preventDefault()
    navigate("/login", "replace")
  }
  useEffect(()=>{
    if(codeVerification=== "pass"){
      navigate("/newPassword")
      setShow(false)
    }
    if(codeVerification === "fail"){
      setShow(true)
    }

  },[navigate,show,codeVerification])
  return (
    <>
      <div className="verficationCodeTitle text-center">
        <h1>Verification Code</h1> 
        <p>
          Please enter the verification code which you have recieved to your
          email
        </p>
        <Alert show={show} variant="danger" className="text-center">                  
                  <p className="mt-2">Verification failed! Please try again!</p>
        </Alert>
      </div>
      <Form role="form" onSubmit={(e)=> {verifyCode(e)}}>
        <Container>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter the Verification Code"
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
              <Button type="button" className="btn-cancel" onClick={e => handleCancel(e)}>
                Cancel
              </Button>
               <span className="p-1"> </span>
              <Button type="submit" className="btn-submit ">
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default CodeConfirmationForm;
