import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0, // Initial count value
};

const registrationCountSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setRegistrationCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { setRegistrationCount } = registrationCountSlice.actions;
export default registrationCountSlice.reducer;
