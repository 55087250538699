const PrintIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
      <g>
        <path
          d="M39.7,72.1c0.2-0.6,0.3-1.1,0.5-1.7c1.3-4,4.9-6.6,9.2-6.6c6.1,0,12.1,0,18.2,0c17.7,0,35.4,0,53.1,0
		c4.7,0,8.5,2.9,9.6,7.4c0.2,0.8,0.3,1.7,0.3,2.5c0,10.9,0,21.7,0,32.6c0,5.8-4.1,9.9-9.9,9.9c-1.9,0-3.7,0-5.6,0c0-0.4,0-0.7,0-1
		c0-4.3,0-8.6,0-12.9c0-7.4-5.6-13-12.9-13c-11.2,0-22.4,0-33.7,0c-7.3,0-12.9,5.6-12.9,12.9c0,4.3,0,8.6,0,12.9c0,0.3,0,0.6,0,1.1
		c-1.2,0-2.3,0-3.4,0c-1.9,0-3.9,0.2-5.8-0.5c-3.4-1.3-5.6-3.8-6.3-7.4c0-0.1-0.1-0.3-0.2-0.4C39.7,96,39.7,84,39.7,72.1z
		 M52.4,80.7c0,2.3,1.8,4.3,4.2,4.3c2.4,0,4.3-1.8,4.3-4.2c0-2.4-1.9-4.3-4.2-4.3C54.4,76.5,52.5,78.4,52.4,80.7z"
        />
        <path
          d="M85,126.1c-5.5,0-11,0-16.5,0c-3.1,0-4.8-1.7-4.8-4.7c0-6.3,0-12.6,0-18.9c0-3,1.7-4.7,4.7-4.7c11,0,22.1,0,33.1,0
		c3,0,4.7,1.7,4.7,4.7c0,6.3,0,12.6,0,18.9c0,3.1-1.7,4.7-4.8,4.7C96,126.2,90.5,126.1,85,126.1z"
        />
        <path
          d="M85.1,58.1c-9.1,0-18.2,0-27.4,0c-1.9,0-2.4-0.5-2.4-2.4c0-1.6-0.1-3.3,0.1-4.9c0.5-4,3.7-6.9,7.8-6.9c7.8,0,15.6,0,23.4,0
		c6.7,0,13.3,0,20,0c5,0,8.3,3.3,8.3,8.3c0,1.2,0,2.5,0,3.7c0,1.6-0.6,2.2-2.2,2.2C103.4,58.1,94.2,58.1,85.1,58.1z"
        />
      </g>
    </svg>
  );
};

export default PrintIcon;
