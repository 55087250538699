import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useState } from "react";
import {
  addQuestionAsync,
  CLEAR_MSG,
  getAllQuestionsAsync,
} from "../../features/media/mediaSlice";
import { useDispatch, useSelector } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { BsCaretDownSquareFill } from "react-icons/bs";
import { useEffect } from "react";
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";
import MediaList from "./MediaList";

//import {uuidv4} from 'uuidv4'
const AddAudioWithImage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const msg = (state) => state.media.message;
  const message = useSelector(msg);

  const [question, setQuestion] = useState({
    audioField: null,
    imgField: null,
    audioTitle: "",
    qtype: 1,
  });

  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);
  //const [addFields, setAddFields] = useState();
  // const addNewFields = () => {
  //    const id = fields.length ? fields[fields.length - 1].id + 1 : 1;
  //    const addField = { id };
  //    const addNewField = [...fields, addField];
  //    setFields(addNewField);

  // };

  const [validated, setValidated] = useState(false);

  function addMediaFunc(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (
      question.imgField == "" ||
      question.audioTitle == "" ||
      question.audioField == ""
    ) {
      return;
    }
    // alert(1);
    var frm = new FormData();
    frm.append("qtype", question.qtype);
    frm.append("image", question.imgField);
    frm.append("audio_title", question.audioTitle);
    frm.append("audio", question.audioField);
    console.log("question");
    dispatch(addQuestionAsync(frm));
    dispatch(CLEAR_MSG());
  }

  useEffect(() => {
    const timeId = setTimeout(() => {
      setaddsucesssetModalShow(false);
      dispatch(CLEAR_MSG());
    }, 1000);

    if (message === "Question added successfully") {
      setaddsucesssetModalShow(true);
    }

    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, message]);

  // const deleteBtnHandler = (id) => {
  //   const deleteBtn = fields.filter((field) => field.id !== id);
  //   setFields(deleteBtn);
  // };

  // const fieldHandler = (e) => {
  //   e.preventDefault();
  //   addNewFields(addFields);
  //   setAddFields("");
  // };
  return (
    <>
      <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addNameSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addPopTextAreaOnSuccess">
            <p>Media has been added sucessfully</p>
          </div>
        </Modal.Body>
      </Modal>

      <ul>
        <li>
          <Form
            className="addMediaFileForm"
            onSubmit={addMediaFunc}
            noValidate
            validated={validated}
          >
            <Row>
              <Col xxl={3} xl={3} lg={3} md={12}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>
                    Image<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    required
                    accept="image/*"
                    onChange={(e) =>
                      setQuestion((question) => ({
                        ...question,
                        ...{ imgField: e.target.files[0] },
                      }))
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Image is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xxl={5} xl={5} lg={4} md={12}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>
                    Audio Title<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={question.audioTitle}
                    required
                    onChange={(e) =>
                      setQuestion((question) => ({
                        ...question,
                        ...{ audioTitle: e.target.value },
                      }))
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Title is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={3} md={12}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>
                    Audio<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    required
                    onChange={(e) =>
                      setQuestion((question) => ({
                        ...question,
                        ...{ audioField: e.target.files[0] },
                      }))
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Audio is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xxl={1} xl={1} lg={2} md={12}>
                <Form.Group>
                  <Form.Label className="invisible">label</Form.Label>
                  <Button type="submit" className="btn-submit">
                    Add
                  </Button>
                </Form.Group>
              </Col>
              {/* <Col xxl={1} xl={1} lg={1} md={1}>
                  <Form.Group>
                    <Form.Label className="invisible">label</Form.Label>
                    <Button
                      type="button"
                      className="btn-delete-icon"
                      onClick={() => deleteBtnHandler(field.id)}
                    >
                      <BsXLg />
                    </Button>
                  </Form.Group>
                </Col> */}
              {/* <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
                <Button type="submit" className="btn-submit">
                  Save
                </Button>
              </Col> */}
            </Row>
          </Form>
        </li>
      </ul>
      {/* <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
        <Button type="button" className="btn-add" onClick={fieldHandler}>
          <BsPlusLg />
          Add Field
        </Button>
      </Col> */}
    </>
  );
};

export default AddAudioWithImage;
