import { Container, Navbar, Nav, Row, Col, Modal } from "react-bootstrap";
import { useState } from "react";
import LoginForm from "../components/login/LoginForm";
import "../components/login/login.css";
import logo from "../global-images/spetra-logo.svg";
import mailIcon from "../components/login/images/landingMailIcon.png";
import aboutIcon from "../components/login/images/landingAboutIcon.png";
const Login = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="aboutContent"
        scrollable={true}
      >
        <Modal.Header className="text-center">
          <Modal.Title>The App Story</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            <strong>
              SPETRA (SPEECH PERCEPTION TEST FOR REHABILITATION AUDIOLOGY)
            </strong>
          </p>
          <div className="AboutContentSection">
            <p>
              There are more than 34 million children affected with hearing loss
              around the globe. This population require rehabilitation to
              address the impact of hearing loss in their lives. The only way
              for this population to access hearing is by using hearing implants
              and hearing aids. These devices help children to listen and
              develop spoken language. However, the progress in hearing using
              the above technology in children must be monitored to facilitate
              appropriate rehabilitation. It is widely agreed that the current
              generation of children have been highly influenced by the digital
              technology. Therefore, the SPETRA App is an speech perception
              assessment App that was developed for children with hearing
              implants and hearing aids.
            </p>
            <p>
              The main objective is to test children using the advantage of the
              app-based algorithm. This app will be a quick and hassle-free
              clinical tool that can be used easily by audiologists, speech
              language pathologists and habilitation professionals to track the
              progress of the child.
            </p>
          </div>
          <p className="margin-bottom-5">
            <strong>How does SPETRA App help professionals</strong>
          </p>
          <ul>
            <li>
              Will engage and reinforce the child to provide accurate results
              for the selected tests.
            </li>
            <li>
              Data will be stored electronically and can be retrieved at any
              point of time.
            </li>
            <li>
              Be a paperless format and that will be easy to document results.
            </li>
            <li>
              Can share the immediate response of the child in real-time to the
              caregiver.
            </li>
            <li>Data analytics report can be generated.</li>
          </ul>
        </Modal.Body>
      </Modal>
      <div className="loginBackground">
        <Navbar>
          <Container>
            <Navbar.Brand href="#">
              <img src={logo} alt="spetra-logo" className="spetraLoginLogo" />
            </Navbar.Brand>
            <Nav className="justify-content-end aboutMenu">
              <Nav.Link href="#" onClick={handleShow}>
                {"  "}
                <img src={aboutIcon} alt="mailIcon" /> About us
              </Nav.Link>
              <ul className="landingPageNav">
                <li>
                  <b>
                    <img src={mailIcon} alt="mailIcon" width="70" />
                  </b>
                  <span>
                    Questions? Email Us
                    <Nav.Link href="mailto:spetraindia@gmail.com">
                      <strong>spetraindia@gmail.com</strong>
                    </Nav.Link>
                  </span>
                </li>
              </ul>
            </Nav>
          </Container>
        </Navbar>
        <div className="LandingPageBody">
          <Container>
            <Row>
              <Col xxl={9} xl={8} lg={8} md={12}>
                <div className="masterTitle">
                  <h1>
                    <span>SPETRA</span>
                    <br />
                    Assesments Made
                    <br />
                    <span>Easy</span>
                  </h1>
                </div>
              </Col>
              <Col xxl={3} xl={4} lg={4} md={12}>
                <div className="loginFormBox">
                  <LoginForm />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Login;
