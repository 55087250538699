import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { forgotPassword, registerUser,setNewPassword,verifyCode } from "./registerAPI";
const initialState = {
  status: false,
  message: "",
  fpEmail:"",
  pwdMailSent:"",
  codeVerify:"",
  passwordSet:""
};

export const registerAsync = createAsyncThunk(
  "register/registerUser",
  async (profile) => {
    const response = await registerUser(profile);
    // The value we return becomes the `fulfilled` action payload
    //console.log(JSON.stringify(response.data));
    return response.data;
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "register/forgotPassword",
  async (profile) => {
    const response = await forgotPassword(profile);
    // The value we return becomes the `fulfilled` action payload
    //console.log(JSON.stringify(response.data));
    return response.data;
  }
);

export const verifyCodeAsync = createAsyncThunk(
  "register/verifyCode",
  async (code) => {
    const response = await verifyCode(code);
    // The value we return becomes the `fulfilled` action payload
   // console.log(JSON.stringify(response.data));
    return response.data;
  }
);

export const setNewPasswordAsync = createAsyncThunk(
  "register/setPassword",
  async (password) => {
    const response = await setNewPassword(password);
    // The value we return becomes the `fulfilled` action payload
    //console.log(JSON.stringify(response.data));
    return response.data;
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    REGISTER_COMPLETE: (state) => {
      return [...state.register];
    },
    RESET_FP_STATUS: (state) => {
      state.pwdMailSent = "";
    },
    RESET_NEWPWD_STATUS: (state) => {
      state.passwordSet = "";
    },
    SET_FP_EMAIL: (state,action) => {
      state.fpEmail = action.payload;
    },
    CLEAR_USR_MSG: (state) => {
      state.message = "";
    },
    CLEAR_USR_STATUS: (state) => {
      state.status = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAsync.pending, (state) => {
        console.log("Register Pending");
        state.status = false;
      })
      .addCase(registerAsync.rejected, (state, action) => {
        console.log("Register failed");
        state.status = false;
        //const data = action.payload;
        //let k = Object.keys(data)[0];
        state.message = "Registeration_Failed";
        //console.log(k);
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        console.log("Register fulfilled");
        const data = action.payload;
	//console.log("file response:"+JSON.stringify(data))
        let k = data?.success;
        if (k === "True") {
          state.status = true;
          state.message = "Registeration_Successful";
        } else {
          state.status = false;
          state.message = "Registeration_Failed";
          //state.message = data?.message;
        }
      }).addCase(resetPasswordAsync.fulfilled, (state, action) => {
        const data = action.payload;
        let success = data?.success
       
        if(success === "False"){
      
          state.pwdMailSent = "mail_failed"
        }else{
          state.pwdMailSent = "mail_sent"
        }
      }).addCase(verifyCodeAsync.fulfilled, (state, action) => {
        const data = action.payload;
        let success = data?.valid
        if(success === false){
          state.codeVerify = "fail"
        }else{
          state.codeVerify = "pass"
        }
      }).addCase(setNewPasswordAsync.fulfilled, (state, action) => {
        const data = action.payload;
        let success = data?.valid
        if(success === false){
          state.passwordSet = "fail"
        }else{
          state.passwordSet = "pass"
        }
      });
  },
});

export const { REGISTER_COMPLET, CLEAR_USR_MSG,SET_FP_EMAIL,RESET_FP_STATUS,RESET_NEWPWD_STATUS, CLEAR_USR_STATUS } = registerSlice.actions;

export default registerSlice.reducer;
