const MediaMenuIcon = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <path
          d="M70.5,130.4c-1.6-0.5-2.9-1.5-3.3-3.2c-0.1-0.4-0.1-0.9-0.1-1.3c0-27.2,0-54.4,0-81.6c0-2.4,1.5-4.2,3.7-4.4
	c2-0.3,4,1.1,4.5,3.1c0.1,0.5,0.1,0.9,0.1,1.4c0,27.2,0,54.3,0,81.5c0,2.5-0.8,3.6-3.4,4.6C71.6,130.4,71,130.4,70.5,130.4z
	 M39.7,88.6c0.1,0.1,0.1,0.2,0.2,0.3c0.7,2.2,2.5,3.3,4.6,3c2.1-0.3,3.5-1.9,3.6-4.1c0.1-1.9,0.1-3.9,0-5.8c-0.1-1.7-1-2.9-2.5-3.6
	c-1.5-0.7-3.1-0.5-4.3,0.6c-0.7,0.6-1.1,1.6-1.6,2.4C39.7,83.9,39.7,86.2,39.7,88.6z M80.8,100.7c0,3.3,0,6.6,0,9.9
	c0,3.2,2.8,5.2,5.7,4.2c1.8-0.6,2.8-2.2,2.8-4.5c0-16.9,0-33.7,0-50.6c0-0.2,0-0.4,0-0.6c-0.2-3-3.1-4.8-5.8-3.7
	c-1.7,0.7-2.7,2.2-2.7,4.3c0,8.4,0,16.9,0,25.3C80.8,90.2,80.8,95.4,80.8,100.7z M116.7,60c0-0.5,0-1-0.1-1.5
	c-0.4-2.4-2.8-3.9-5.2-3.3c-2,0.5-3.2,2.2-3.2,4.5c0,16.9,0,33.8,0,50.6c0,0.2,0,0.5,0,0.7c0.2,2.9,2.9,4.7,5.6,3.8
	c1.8-0.6,2.8-2.2,2.8-4.5c0-8.4,0-16.8,0-25.2C116.7,76.7,116.7,68.4,116.7,60z M61.8,67.2c0-3-2.5-5-5.2-4.3
	c-2,0.5-3.2,2.2-3.2,4.5c0,11.7,0,23.4,0,35.1c0,0.4,0,0.9,0.1,1.3c0.4,2.2,2.5,3.8,4.7,3.4c2.3-0.4,3.6-2,3.6-4.5
	c0-5.9,0-11.8,0-17.7C61.8,79.1,61.8,73.1,61.8,67.2z M94.6,95.5c0,2.6,2,4.3,4.6,4.1c2.3-0.2,3.7-1.9,3.7-4.4c0-6.8,0-13.6,0-20.4
	c0-0.4,0-0.8-0.1-1.1c-0.5-2.1-2.4-3.4-4.6-3.2c-2.1,0.2-3.7,1.9-3.7,4.1c0,3.5,0,7,0,10.4C94.6,88.5,94.5,92,94.6,95.5z M122,85
	c0,1,0,2,0,3c0.1,2.4,1.8,4,4.1,4c2.3,0,4.1-1.6,4.2-4c0.1-2,0.1-4,0-5.9c-0.1-2.2-2-3.9-4.1-3.9c-2.2,0-4,1.6-4.2,3.9
	C121.9,83,122,84,122,85C122,85,122,85,122,85z"
        />
      </svg>
    </>
  );
};

export default MediaMenuIcon;
