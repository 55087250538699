import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeftSidebar from "../components/sidebar/LeftSidebar";
import "../components/name/name.css";
import AddNameForm from "../components/name/AddNameForm";
import NameBackButton from "../components/name/NameBackButton";
import logo from "../global-images/spetra-logo.svg";
import ToggleMenu from "../global-images/ToggleMenu";
const AddName = () => {
  const viewHeight = window.innerHeight;
  console.log(viewHeight);
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };
  const contentStyle = {
    marginLeft: sidebarOpen ? "250px" : "0px",
    transition: "margin 0.2s ease",
    borderTopLeftRadius: sidebarOpen ? "40px" : "0px",
  };
  return (
    <div className="spetraLayout">
      <Container fluid>
        <Row>
          <div className="SideBar">
            <LeftSidebar
              isOpen={sidebarOpen}
              toggleSidebar={handleViewSidebar}
            />
          </div>
          <div className="main">
            <div className="mainContentboxshadow" style={contentStyle}>
              <div
                className="AddNamesBody"
                style={{
                  minheight: viewHeight,
                  maxheight: viewHeight,
                }}
              >
                <Container fluid>
                  <Row>
                    <Col xxl={3} xl={3} lg={3} md={4}>
                      <span onClick={handleViewSidebar} className="toggleMenu">
                        <ToggleMenu />
                      </span>

                      {(() => {
                        if (!sidebarOpen) {
                          return (
                            <img
                              src={logo}
                              alt="spetra-logo"
                              className="sidebarOpenLogo"
                            />
                          );
                        } else {
                          return "";
                        }
                      })()}
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={4}>
                      <h1 className="text-center fw-bold">Add Name</h1>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={4} className="text-end">
                      <NameBackButton />
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12}>
                      <AddNameForm />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AddName;
