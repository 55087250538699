import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [], // Initial data array
};

const recentlyActiveUserSlice = createSlice({
  name: "recentlyActiveUser",
  initialState,
  reducers: {
    setRecentlyActiveUser: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setRecentlyActiveUser } = recentlyActiveUserSlice.actions;
export default recentlyActiveUserSlice.reducer;
