const PlayButtonIcon = () => {
  return (
    <>
      <svg viewBox="0 0 70 70">
        <defs></defs>
        <circle
          className="a"
          cx="28.5"
          cy="28.5"
          r="28.5"
          transform="translate(7 7)"
          style={{ fill: "#fff" }}
        />
        <path
          className="b"
          d="M37,2A35,35,0,1,0,72,37,35.013,35.013,0,0,0,37,2ZM28.25,52.75V21.25L52.75,37Z"
          transform="translate(-2 -2)"
          style={{ fill: "#39b54a" }}
        />
      </svg>
    </>
  );
};

export default PlayButtonIcon;
