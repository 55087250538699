import { Container, Row, Col } from "react-bootstrap";
import PatientGroupIcon from "./PatientGroupIcon";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setRegistrationCount } from "../../features/dashboard/RegistrationCountSlice";
import { baseURL } from "../../../src/constants";

const NumOfRegistrations = () => {
  const dispatch = useDispatch();
  
  const registrationCount = useSelector((state) => state.registration.count);

  let registrationLabel = "Registrations";

  const r_role = (state) => state.login.role;
  const r_email = (state) => state.login.mail;

  const roles = useSelector(r_role);
  const mailVal = useSelector(r_email);

  // Assuming 'createdByValue' is the value you want to append for 'created_by'
  const createdByValue = mailVal; // Replace 'yourValueHere' with the actual value
  let curUrl = ""
  if (parseInt(roles) == 1) {
    // Append the parameter for cases other than roles == 1
     curUrl = `${baseURL}api/registration_count/`;
    curUrl += `?created_by=`+createdByValue; // Replace YOUR_VALUE_HERE with the actual value
  }
  else{
    registrationLabel = "Patients";
    curUrl = `${baseURL}patient/patient_count_user/`;
    curUrl += `?created_by=`+createdByValue; // Replace YOUR_VALUE_HERE with the actual value
  }
  useEffect(() => {
    // Fetch the registration count from your API endpoint
    // Replace the following with your actual API call
    const fetchRegistrationCount = async () => {
      try {
        const response = await fetch(curUrl);
        const data = await response.json();
        if (parseInt(roles) == 1) {
        dispatch(setRegistrationCount(data.registration_count));
        }
        else
        {
          dispatch(setRegistrationCount(data.count));
        }
      } catch (error) {
        console.error("Error fetching registration count:", error);
      }
    };

    fetchRegistrationCount();
  }, [dispatch]);

  return (
    <div className="dashboard-box">
    <Container fluid style={{ marginRight: 0, marginLeft: 0, paddingRight: 0, paddingLeft: 12 }}>
      <Row>
        <Col xxl={0.5} xl={2} lg={3} md={4} sm={4} >
          <PatientGroupIcon />
        </Col>
        <Col xxl={11.5} xl={10} lg={9} md={8} sm={8}>
          <p>Number of {registrationLabel}:</p>
          <h2>{registrationCount}</h2>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default NumOfRegistrations;

