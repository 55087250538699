import { Row, Col, Form, Button,Modal } from "react-bootstrap";
import { useState } from "react";
import { BsXLg } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { useEffect } from "react";
import {
  CLEAR_MSG,
  getAllQuestionsAsync,
  updateQuestionAsync,
} from "../../features/media/mediaSlice";
import { useNavigate, useParams } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { useRef } from "react";

const UpdateAudioOnly = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const msg = (state) => state.media.message;
  const message = useSelector(msg);

  const qs = (state) => state.media.questions

  const questions = useSelector(qs)
  const quest = questions.filter(q=> parseInt(q.qid) === parseInt(props?.qid))[0]

  const [question, setQuestion] = useState({
    qid:"",
    audio: null,
    audio_title: "",
    qtype: "",
  });

  const audioTitle = useRef()
  //Audio Only
  const [audioOnlyField, setAudioOnlyField] = useState("");
  const [audioOnlyTitle, setAudioOnlyTitle] = useState("");

  const [audiofields, setAudioFields] = useState([
    {
      id: 1,
    },
  ]);
  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);

  const [addaudioFields, setAddAudioFields] = useState("");
  const addNewAudioFields = () => {
    const id = audiofields.length
      ? audiofields[audiofields.length - 1].id + 1
      : 1;
    const addAudioField = { id };
    const addNewAudioField = [...audiofields, addAudioField];
    setAudioFields(addNewAudioField);
  };
  
  const audiodeleteBtnHandler = (id) => {
    const audioOnlydeleteBtn = audiofields.filter(
      (audioOnly) => audioOnly.id !== id
    );
    setAudioFields(audioOnlydeleteBtn);
  };
  const [validated, setValidated] = useState(false);

  const audiofieldHandler = (e) => {
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
      e.preventDefault();
    //   e.stopPropagation();
    // }
    setValidated(true);
    if (
      question?.audio_title === "" 
    ) {
      return;
    }
    var frm = new FormData();
    frm.append("qid", question?.qid);
    frm.append("qtype",2);
    frm.append("audio_title", question?.audio_title);
    //frm.append("audio", audioOnlyField);
    //console.log("audio",frm);
    dispatch(updateQuestionAsync(frm));
    dispatch(CLEAR_MSG());
    // addNewAudioFields(addaudioFields);
    // setAddAudioFields("");
  }

 function cancel(){
    navigate("/media")
  }

  useEffect(() => {
  

    if (message === "update successful") {
      setaddsucesssetModalShow(true);
      setTimeout(() => {
        setaddsucesssetModalShow(false);
        dispatch(CLEAR_MSG());
        cancel()
      }, 3000);
    }
    if(question.qid === ""){
      setQuestion({
        qid: quest?.qid,
        audio: quest?.audio,
        image: quest?.image,
        audio_title: quest?.audio_title,
        qtype: quest?.qtype,
      })
    }
 

   

    return () => {
    
    };
  }, [dispatch, message, cancel]);

  return (
    <>
    <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addNameSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addPopTextAreaOnSuccess">
            <p>Media has been updated sucessfully</p>
          </div>
        </Modal.Body>
      </Modal>

            <Form className="addMediaFileForm" onSubmit={audiofieldHandler}  noValidate
            validated={validated}>
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} className="m-auto ">
                  <Form.Group className="p-2">
                    <Form.Label>
                      Audio Title<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={question?.audio_title}                               
                      onChange={(e) =>
                        setQuestion((question) => ({
                          ...question,
                          ...{ audio_title: e.target.value },
                        }))
                      }
                      required            
                    ></Form.Control>
                     <Form.Control.Feedback type="invalid">
                    Audio Title is required
                  </Form.Control.Feedback>
                  </Form.Group>
               
                  <Form.Group className="p-2">
                    <Form.Label>
                      Audio
                    </Form.Label>
                    <Form.Group>
                    <ReactAudioPlayer src={question?.audio} controls />
                    </Form.Group>
                    
                  </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col xxl={6} xl={6} lg={6} md={6} className="m-auto">
                  <Form.Group className="text-center p-2" >
                    <Form.Label className="invisible">label</Form.Label>
                    <Button type="submit" className="btn-submit">
                      Update
                    </Button>
                    <span className="p-2"></span>
                    <Button type="button" className="btn-cancel" onClick={cancel}>
                      Cancel
                    </Button>
                  </Form.Group>
                </Col>
                {/* <Col xxl={2} xl={2} lg={2} md={2}>
                  <Form.Group>
                    <Form.Label className="invisible">label</Form.Label>
                    <Button
                      type="button"
                      className="btn-delete-icon"
                      onClick={() => audiodeleteBtnHandler(audioOnly.id)}
                    >
                      <BsXLg />
                    </Button>
                  </Form.Group>
                </Col> */}
              </Row>
            </Form>
       
      {/* <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
        <Button type="button" className="btn-add" onClick={audiofieldHandler}>
          <BsPlusLg />
          Add Field
        </Button>
      </Col> */}
      {/* <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
        <Button type="button" className="btn-submit">
          Save
        </Button>
      </Col> */}
    </>
  );
};

export default UpdateAudioOnly;
