import { Container, Row, Col, Form } from "react-bootstrap";
import { useState } from "react";
import LeftSidebar from "../components/sidebar/LeftSidebar";
import MediaBackButton from "../components/media/MediaBackButton";

import UpdateAudioOnly from "../components/media/UpdateAudioOnly";
import "../components/media/media.css";
import UpdateAudioWithImage from "../components/media/UpdateAudioWithImage";
import { useParams } from "react-router-dom";
const UpdateMedia = () => {
const params = useParams()
const [sidebarOpen, setSideBarOpen] = useState(true);
const[searchText, setSearchText] = useState("");
const handleViewSidebar = () => {
  setSideBarOpen(!sidebarOpen);
};

  return (
    <>
      <div className="spetraLayout">
        <Container fluid>
          <Row>
            <Col
              xxl={2}
              xl={2}
              lg={3}
              md={3}
              sm={3}
              className="padding-right-0"
            >
              <div className="SideBar">
              <LeftSidebar
                isOpen={sidebarOpen}
                toggleSidebar={handleViewSidebar}
              />
            </div>
            </Col>
            <Col
              xxl={10}
              xl={10}
              lg={9}
              md={9}
              sm={9}
              className="mainContentboxshadow"
            >
              <div className="ContentMainBody">
                <Container fluid>
                  <Row>
                    <Col xxl={3} xl={3} lg={3} md={4}></Col>
                    <Col xxl={6} xl={6} lg={6} md={4} className="text-center">
                      <h1 className="text-center">Update Media</h1>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={4}>
                      <MediaBackButton />
                    </Col>
                  </Row>
                  <Col xxl={12} xl={12} lg={12} md={12}>
                      {
                        (params?.type === "1")? <UpdateAudioWithImage qid= {params?.qid} /> : <UpdateAudioOnly qid= {params?.qid}/>
                      }
                  </Col>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UpdateMedia;
