import { Alert, Form, Button, Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentQRCode from './PaymentQRCode';


import {
  CLEAR_USR_MSG,
  CLEAR_USR_STATUS,
  registerAsync,
} from "../../features/login/registerSlice";
import {
  FETCH_CONTENT_TERMS,
  FETCH_CONTENT_PRIVACY,
} from "../../features/terms/termsSlice";
const RegistrationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const upiId = 'prathiba.ben@okhdfcbank';
  const name = 'Spetra';
  const registrationAmount=1000;

  const fileval = useRef();
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    gender: "",
    phone: "",
    password: "",
    comments: "",
    role: 4,
    dp: "",
    payment_reference: "", // new field for reference number
    payment_reference_screenshot: null, // new field for file upload
  });
  const [passMessage, setPassMessage] = useState(false);
  // const errorMsg = useRef();

  const [show, setShow] = useState(false);

  const msg = (state) => state.register?.message;
  const message = useSelector(msg);

  const [fileError, setFileError] = useState(''); // State for file upload error message

// Function to handle file changes
const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    if (!file.type.startsWith('image/')) {
      // Reset the file input
      e.target.value = '';
      setFileError('Please upload an image file.');
      return;
    }
    setFileError('');
    setProfile((profile) => ({
      ...profile,
      ...{ payment_reference_screenshot: file }, 
    }));
  } else {
    // Handle the case where a user cancels the file selection
    setFileError('');
  }
};

  const handleTerms = () => {
    dispatch(FETCH_CONTENT_TERMS());
  };
  const handlePrivacy = () => {
    dispatch(FETCH_CONTENT_PRIVACY());
  };

  const confPwd = useRef();
  const defaultErrMsg = "Registration Failed! Please try again";
  const [errMsg, setErrMsg] = useState(defaultErrMsg);
  // alert(message)
  const [validated, setValidated] = useState(false);



  const userRegistration = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (
      profile.name == "" ||
      profile.email == "" ||
      profile.phone == "" ||
      profile.phone.length != 10 ||
      profile.password == "" ||

      profile.checkbox == false
    ) {
      return;
    } else if (profile.password !== confPwd.current.value) {
      setErrMsg("Registration Failed! Password Mismatch");
      setShow(true);
      return;
    }
    setShow(false);
    setErrMsg(defaultErrMsg);
    let frm = new FormData();
    frm.append("name", profile?.name);
    frm.append("email", profile?.email);
    frm.append("role", profile?.role);
    frm.append("gender", profile?.gender);
    frm.append("phone", profile?.phone);
    frm.append("password", profile?.password);
    frm.append("comments", profile?.comments);
    frm.append("dp", profile?.dp);
    frm.append("payment_reference", profile?.payment_reference);
    frm.append("payment_reference_screenshot", profile?.payment_reference_screenshot);
    console.log(JSON.stringify(frm));

    dispatch(registerAsync(frm));
    dispatch(CLEAR_USR_MSG());
  };

  const cancelButton = useNavigate();
  const registrationCancel = () => {
    const cancelPath = "/login";
    cancelButton(cancelPath);
    dispatch(CLEAR_USR_MSG());
  };

  useEffect(() => {
    // dispatch(CLEAR_USR_STATUS())
    console.log(message);
    if (message === "Registeration_Failed") {
      setShow(true);
    }
    if (message === "Registeration_Successful") {
      setShow(false);
      setPassMessage(true);
      setTimeout(() => {
        navigate('/login'); // Adjust the path as needed for your login route
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    return () => {
      if (passMessage) dispatch(CLEAR_USR_MSG());
    };
  }, [dispatch, message, passMessage]);
  return (
    <>
      <div className="resgiterFormTitle">
        <h1>Registration Form</h1>
      </div>
      <div>
        <Alert show={show} variant="danger" className="text-center">
          <p className="mt-2">{errMsg}</p>
        </Alert>
        <Alert show={passMessage} variant="success" className="text-center">
          <p className="mt-2">
            Your Registration is successful! Please await approval from our
            Admin via email to login.{" "}
          </p>
        </Alert>
      </div>
      <Form
        className="registrationForm"
        onSubmit={(e) => userRegistration(e)}
        noValidate
        validated={validated}
      >
        <Container>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="margin-bottom-20">
                <Form.Label>
                  Name<span>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  onChange={(e) =>
                    setProfile((profile) => ({
                      ...profile,
                      ...{ name: e.target.value },
                    }))
                  }
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Name is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="margin-bottom-20">
                <Form.Label>
                  Email ID<span>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  required
                  onChange={(e) =>
                    setProfile((profile) => ({
                      ...profile,
                      ...{ email: e.target.value },
                    }))
                  }
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Email is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="margin-bottom-20">
                <Form.Label>
                  Contact Number<span>*</span>
                </Form.Label>
                <Form.Control
                  type="tel"
                  required
                  onChange={(e) =>
                    setProfile((profile) => ({
                      ...profile,
                      ...{ phone: e.target.value.replace(/[^0-9.]/g, "") },
                    }))
                  }
                  pattern="^[123456789]\d{9,9}$"
                  title="Enter the Valid 10 digit mobile number"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Contact Number is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="margin-bottom-20">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setProfile((profile) => ({
                      ...profile,
                      ...{ gender: e.target.value },
                    }))
                  }
                >
                  <option value={""}></option>
                  <option value={"M"}>Male</option>
                  <option value={"F"}>Female</option>
                  <option value={"O"}>Others</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="margin-bottom-20">
                <Form.Label>
                  Password<span>*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  required
                  ref={confPwd}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Password is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Form.Group className="margin-bottom-20">
                <Form.Label>
                  Confirm Password<span>*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  required
                  onChange={(e) =>
                    setProfile((profile) => ({
                      ...profile,
                      ...{ password: e.target.value },
                    }))
                  }
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Confirm Password is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
  
            {/* Styled Col for the Payment Message */}
            <Col xxl={12} xl={12} lg={12} md={12} className="mb-4">
              <div className="payment-message-wrapper">
                <p className="payment-message">
                  You can make the payment by scanning the QR Code image shown below the form and enter the payment details in following fields.
                </p>
              </div>
            </Col>

            
          {/* File Upload Field */}
          <Col xxl={6} xl={6} lg={6} md={6}>
            <Form.Group className="margin-bottom-20">
              <Form.Label>Upload Payment Receipt Screenshot</Form.Label>
                <Form.Control
                type="file"
                required
                accept="image/*"
                onChange={handleFileChange}
              ></Form.Control>
                <Form.Control.Feedback type="invalid">
                {fileError}
                </Form.Control.Feedback>
            </Form.Group>
          </Col>
            <Col xxl={6} xl={6} lg={6} md={6}>
            <Form.Group className="margin-bottom-20">
              <Form.Label>Payment Reference Number</Form.Label>
              <Form.Control
                type="text"
                required
                onChange={(e) =>
                  setProfile((profile) => ({
                    ...profile,
                    ...{ payment_reference: e.target.value },
                  }))
                }
              ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Payment Reference is required
                </Form.Control.Feedback>
            </Form.Group>
          </Col>


            <Col xxl={6} xl={6} lg={6} md={6} className="d-none">
              <Form.Group>
                <Form.Label>User Profile Picture</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) =>
                    setProfile((profile) => ({
                      ...profile,
                      ...{ dp: e.target.files[0] },
                    }))
                  }
                  ref={fileval}
                ></Form.Control>
              </Form.Group>
            </Col>
            {/* <Col xxl={12} xl={12} lg={12} md={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setProfile((profile) => ({
                      ...profile,
                      ...{ address: e.target.value },
                    }))
                  }
                ></Form.Control>
              </Form.Group>
            </Col> */}
            {/* <Col xxl={12} xl={12} lg={12} md={12}>
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) =>
                  setProfile((profile) => ({
                    ...profile,
                    ...{ comments: e.target.value },
                  }))
                }
              ></Form.Control>
            </Col> */}

            <Col xxl={12} xl={12} lg={12} md={12}>
              {["checkbox"].map((type) => (
                <div key={`default-${type}`} className="mb-3 mt-3">
                  <Form.Check
                    type={type}
                    id={`default-${type}`}
                    required
                    className="d-inline-block"
                    checked={true}
                    readOnly
                  />
                  <label className="terms">
                    I accept to the{" "}
                    <span onClick={handleTerms}>terms and conditions</span>{" "}
                    &amp; <span onClick={handlePrivacy}>privacy policy</span>
                  </label>
                </div>
              ))}
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} className="text-end">
              <Button
                type="button"
                className="btn-cancel"
                onClick={registrationCancel}
              >
                Cancel
              </Button>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6}>
              <Button type="submit" className="btn-submit">
                Submit
              </Button>
            </Col>
          </Row>

           {/* QR Code Image Display at the End of the Form */}
           <Row className="justify-content-center mt-4">
            <Col md={12} className="text-center">
              <PaymentQRCode upiId={upiId} name={name} registrationAmount={registrationAmount} />
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default RegistrationForm;
