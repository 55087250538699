import { Container, Row, Col, Button } from "react-bootstrap";
import profilePic from "../../global-images/profilePic.svg";
import { BsPencil } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { userRole } from "../../constants";

const ViewUserDetails = (props) => {
  const navigate = useNavigate();
  const p_profiles = (state) => state.login.users;
  const user = useSelector(p_profiles);

  const userEditFunc = () => {
    const userEditPath = "/updateUser";
    navigate(userEditPath);
  };

  const [userProfile, setUserProfile] = useState({
    id: "",
    name: "",
    email: "",
    gender: null,
    phone: "",
    password: "",
    address: null,
    role: 2,
    dp: "",
  });

  useEffect(() => {
    let p = user.filter((obj) => parseInt(obj.id) === parseInt(props?.id));
    if (p.length > 0) {
      setUserProfile((userProfile) => ({ ...{ userProfile }, ...p[0] }));
      //console.log(patientProfile)
    }
    return () => {
      /// console.log(p)
    };
  }, [user, props]);
  return (
    <>
      <div className="viewUserDetails">
        <Container fluid>
          <Row>
            <Col xxl={2} xl={2} lg={2} md={2} className="text-center">
              <img
                src={userProfile?.dp === null ? profilePic : userProfile?.dp}
                className="img-fluid usersProfilePicture"
                alt="profile-pic"
              />
              {/* <Button type="button" className="btn-edit" onClick={userEditFunc}>
                <BsPencil />
                Edit User
              </Button> */}
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5}>
              <div className="ViewUserDetailsData">
                <h2>Name</h2>
                <p>{userProfile?.name}</p>
              </div>
              <div className="ViewUserDetailsData">
                <h2>Email</h2>
                <p>{userProfile?.email}</p>
              </div>
              <div className="ViewUserDetailsData">
                <h2>Phone</h2>
                <p>{userProfile?.phone}</p>
              </div>
              <div className="ViewUserDetailsData">
                <h2>Role</h2>
                <p>{userRole[parseInt(userProfile?.role)]}</p>
              </div>
              <div className="ViewUserDetailsData">
                <h2>Payment Reference Screenshot</h2>
               
{userProfile?.payment_screenshot_file && (
  <img
    src={`${window.location.origin}/media/payment_screenshots/${userProfile.payment_screenshot_file.replace('https://spetrastoragev1.s3.amazonaws.com/', '')}`}
    alt="Payment Screenshot"
  />
)}
              </div>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5}>
              <div className="ViewUserDetailsData">
                <h2>ID</h2>
                <p>{userProfile?.id}</p>
              </div>
              <div className="ViewUserDetailsData">
                <h2>Gender</h2>
                <p>{userProfile?.gender}</p>
              </div>
              <div className="ViewUserDetailsData">
                <h2>Address</h2>
                <p>{userProfile?.address}</p>
              </div>
              <div className="ViewUserDetailsData">
                <h2>Payment Reference </h2>
                <p>{userProfile?.payment_reference}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ViewUserDetails;
