const ApproveIcon = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <path
          d="M75.4,97.4c7-7,13.6-13.7,20.3-20.3c7.7-7.7,15.4-15.5,23.2-23.2c3.7-3.7,8.9-4,12.6-0.9c3.8,3.2,4.3,8.8,1.2,12.7
	c-0.4,0.5-0.9,1-1.4,1.4C115.1,83.3,99,99.4,82.9,115.6c-4.8,4.8-9.8,4.8-14.7,0C61,108.4,53.8,101.2,46.7,94
	c-5.1-5.2-2.9-13.6,4.1-15.3c3.4-0.9,6.4,0.2,8.9,2.7c4.8,4.8,9.6,9.5,14.3,14.3C74.5,96.2,74.8,96.7,75.4,97.4z"
        />
      </svg>
    </>
  );
};

export default ApproveIcon;
