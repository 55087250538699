import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PrivateRoutes = ({ children}) => {
  //console.log(store.getState().login.status.toString())
  //const location = useLocation()

  const token = sessionStorage.getItem("accessToken");
   const s_role = state => state.login.role
   const role = useSelector(s_role)

   if (role === 0){
    // not logged in so redirect to login page with the return url
    return <Navigate to={{ pathname: "/login" }} replace />;
   }


  //console.log(token);
if (token === "" || token === "undefined" || token === null) {
  // not logged in so redirect to login page with the return url
    return <Navigate to={{ pathname: "/login" }} replace />;
}
  return children
};
