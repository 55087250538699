import { baseURL } from "../../constants";

export function addTest(testDetails = {}) {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json"},
    body: JSON.stringify(testDetails)
  };
  console.log(JSON.stringify(requestOptions));
  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/create/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function beginTest(testDetails = {}) {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  //testDetails["jwt"] = sessionStorage.getItem("accessToken")
  const requestOptions = {
    method: "POST",
    headers: {
      Cookie: sessionStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(testDetails),
  };
  //console.log(JSON.stringify(requestOptions))
  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/start/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function testResponse(tResponse = {}) {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(tResponse),
  };

  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/response/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function testSubmit(tResponse = {}) {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(tResponse),
  };

  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/submit/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function testResult() {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: null,
  };

  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/allresult/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function testResultComments() {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: null,
  };

  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/getallanswers/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function getAllTests() {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: null,
  };

  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/getall/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function deleteTest(testDetails = {}) {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(testDetails),
  };

  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/delete/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function updateTest(testDetails = {}) {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(testDetails),
  };

  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/update/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function getTestDetails(testDetails = {}) {
  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
  // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(testDetails),
  };

  return new Promise((resolve, reject) => {
    fetch(baseURL + "test/get/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}