const ResultsIcon = () => {
  return (
    <>
      <svg version="1.1" viewBox="0 0 170.1 170.1">
        <g>
          <path
            d="M42.6,130.4c-1.5-0.6-2-1.8-2-3.4c0.1-4.9,0-9.9,0-14.8c0-2.2,1-3.2,3.2-3.2c6.7,0,13.4,0,20.1,0c0.4,0,0.7,0,1.2,0
		c0-0.4,0-0.7,0-1c0-3.6,0-7.3,0-10.9c0-1.9,1.1-3,3-3c6.7,0,13.5,0,20.2,0c0.4,0,0.7,0,1.2,0c0-0.4,0-0.7,0-1.1c0-5.5,0-11,0-16.5
		c0-2.2,1-3.2,3.2-3.2c2.9,0,5.9,0,9,0c0-0.4,0-0.7,0-1.1c0-9.7,0-19.5,0-29.2c0-1.6,0.5-2.8,2.1-3.4c0.4,0,0.8,0,1.2,0
		c1.9,0.7,2.2,2.3,2,4.1c0.5,0,0.8,0,1.2,0c6.4,0,12.7,0,19.1,0c1.3,0,2.3,0.4,2.8,1.6c0.5,1.2,0.1,2.2-0.7,3.1
		c-1.5,1.6-3,3.2-4.4,4.8c-0.2,0.2-0.4,0.5-0.7,0.7c0.9,0.9,1.7,1.7,2.6,2.5c0.9,0.9,1.7,1.7,2.6,2.6c0.9,0.9,1.2,2,0.7,3.1
		c-0.5,1.2-1.5,1.6-2.8,1.6c-6.4,0-12.8,0-19.2,0c-0.3,0-0.7,0-1,0c0,3.3,0,6.4,0,9.5c3.1,0,6.2,0,9.3,0c2.4,0,3.4,0.9,3.4,3.4
		c0,16.7,0,33.4,0,50.1c0,1.6-0.4,2.9-2,3.6C92.7,130.4,67.6,130.4,42.6,130.4z M114.4,78.7c-6.6,0-13.1,0-19.5,0
		c0,15.5,0,30.9,0,46.3c6.5,0,13,0,19.5,0C114.4,109.6,114.4,94.2,114.4,78.7z M89.4,99.5c-6.4,0-12.7,0-19,0c0,8.5,0,17,0,25.5
		c6.4,0,12.7,0,19,0C89.4,116.5,89.4,108,89.4,99.5z M65,125c0-3.6,0-7.2,0-10.7c-6.4,0-12.7,0-19,0c0,3.6,0,7.1,0,10.7
		C52.4,125,58.7,125,65,125z M121.5,49.1c-4.9,0-9.7,0-14.5,0c0,3.1,0,6.2,0,9.3c4.7,0,9.3,0,14.2,0c-0.9-0.8-1.6-1.5-2.3-2.2
		c-1.3-1.3-1.3-2.7-0.1-4.1C119.7,51.2,120.6,50.2,121.5,49.1z"
          />
          <path
            d="M39.8,92.3c0.5-1.1,1.4-1.7,2.4-2.1c15.5-6.9,27.3-17.9,35.4-32.8c0.1-0.3,0.3-0.5,0.4-0.8c0,0,0-0.1,0-0.3
		c-1.4,0.3-2.8,0.6-4.1,0.8c-1.2,0.2-2.3-0.4-2.8-1.5c-0.7-1.5,0.1-3.3,1.8-3.7c3.1-0.7,6.2-1.3,9.3-2c1.5-0.3,2.5,0.2,3.2,1.6
		c1.4,2.8,2.8,5.6,4.2,8.4c0.7,1.5,0.2,3.1-1.1,3.8c-1.4,0.6-2.8,0.1-3.6-1.4c-0.6-1.1-1.2-2.3-1.8-3.6c-0.2,0.3-0.3,0.5-0.5,0.7
		C73.8,75.7,61.1,87.5,44.3,95c-2.3,1-3.2,0.8-4.6-1.3C39.8,93.2,39.8,92.8,39.8,92.3z"
          />
        </g>
      </svg>
    </>
  );
};

export default ResultsIcon;
