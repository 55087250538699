const DashboardPercentageIcon = () => {
  return (
    <svg viewBox="0 0 29.924 30.215">
      <defs></defs>
      <path
        d="M76.253,51.664l.039-.118V50.009l-.118-.237c0-.039-.039-.039-.039-.079A3.106,3.106,0,0,0,74.4,47.84a3.428,3.428,0,0,0-3.824.867L50.864,68.42l-1.892,1.892-.276.276a3.158,3.158,0,0,0-.473,3.391A3.1,3.1,0,0,0,51.1,75.911h.118a3.363,3.363,0,0,0,2.4-1.064c7.1-7.136,14.232-14.232,21.368-21.368A4.163,4.163,0,0,0,76.253,51.664Zm-1.538-.355a3.151,3.151,0,0,1-.867,1.1c-7.1,7.136-14.232,14.232-21.329,21.368a1.67,1.67,0,0,1-1.34.591,1.658,1.658,0,0,1-1.222-2.76c.039-.039.079-.118.158-.158l1.892-1.892L71.72,49.851a2.233,2.233,0,0,1,.591-.473,2.119,2.119,0,0,1,.828-.2,2,2,0,0,1,.67.118,1.589,1.589,0,0,1,.867.946c0,.039.039.079.039.118v.946Zm.237,15.1a6.3,6.3,0,0,0-8.2.039,6.056,6.056,0,0,0,4.1,10.527h0a6.083,6.083,0,0,0,4.1-10.566Zm-.907,7.648a4.405,4.405,0,0,1-3.193,1.3h0a4.493,4.493,0,0,1-3.036-7.806,4.188,4.188,0,0,1,2.72-1.143h.434a4.041,4.041,0,0,1,2.957,1.1,4.474,4.474,0,0,1,1.459,3.351A4.71,4.71,0,0,1,74.046,74.058ZM53.111,58.84h0a6.057,6.057,0,0,0,6.071-6.071,5.923,5.923,0,0,0-2.011-4.494,5.547,5.547,0,0,0-4.1-1.5,5.587,5.587,0,0,0-4.1,1.538A6.067,6.067,0,0,0,47,52.768,6.186,6.186,0,0,0,53.111,58.84Zm-3-9.383a4.188,4.188,0,0,1,2.72-1.143h.434a4.041,4.041,0,0,1,2.957,1.1,4.527,4.527,0,0,1,.118,6.545,4.405,4.405,0,0,1-3.193,1.3h0a4.492,4.492,0,0,1-4.494-4.494A4.184,4.184,0,0,1,50.115,49.457Z"
        transform="translate(-47 -46.76)"
      />
    </svg>
  );
};

export default DashboardPercentageIcon;
