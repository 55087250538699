import { Row, Col, Table, Button } from "react-bootstrap";
import profilePic from "../../global-images/profilePic.svg";
import React from "react";
import Pdf from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import PdfIcon from "../../global-images/PdfIcon";
import PrintIcon from "../../global-images/PrintIcon";
import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { testResultAsync } from "../../features/tests/testSlice";
import { getAllPatientsAsync } from "../../features/patient/patientSlice";
import { calculateAge } from "../../utils";
import { format } from "date-fns";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import AudioCommentsModal from '../results/AudioCommentsModal';
import logo from "../../global-images/spetra-logo.svg";

const ViewReportDetails = (props) => {
  //audio comments
  const [currentAudioTest, setCurrentAudioTest] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e,sessionID) => {
    e.preventDefault();
    setCurrentAudioTest(sessionID);
    setShow(true);
  }

  const dispatch = useDispatch();
  const p_patients = (state) => state.patient.patients;
  const patients = useSelector(p_patients);
  const t_r = (state) => state.test.results;
  const testResults = useSelector(t_r);

  const componentRef = useRef();
  const params = useParams();

  const [showHeader, setShowHeader] = useState(false);
  const getESPTOverAllResult = (array) => {
    let Level1 = true,
      Level2 = true,
      Level3 = true;
    if (array.length > 0) {
      if (array.filter((item) => item.level == "1")[0]) {
        Level1 =
          Math.round(array.filter((item) => item.level == "1")[0]?.total_questions) === Math.round(array.filter((item) => item.level === "1")[0]?.questions_attended);
      } else if (array.filter((item) => item.level == "2")[0]) {
        Level2 =
          Math.round(array.filter((item) => item.level == "2")[0]?.total_questions) === Math.round(array.filter((item) => item.level === "2")[0]?.questions_attended);
      } else if (array.filter((item) => item.level == "3")[0]) {
        Level3 =
          Math.round(array.filter((item) => item.level == "3")[0]?.total_questions) === Math.round(array.filter((item) => item.level === "3")[0]?.questions_attended);
      }
      return Level1 && Level2 && Level3;
    }
    return false;
  };
  const pageStyle = `@page {
    size: A4 portriat;
  }`;
  const handlePrint = useReactToPrint({
    // bodyClass: "viewReportContent img-fluid",
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: pageStyle,
    removeAfterPrint: true,
    documentTitle: "VIEW REPORT",
    // onAfterPrint: () => setShowHeader(false),

  });

  const PdfOptions = {
    orientation: "portriat",
    // title: "VIEW REPORT",
    useCORS: true,
    // width:"100%",
    // putOnlyUsedFonts:true
    // unit: 'in',
    // format: [4,2],
  };

  useEffect(() => {
    dispatch(getAllPatientsAsync());
    dispatch(testResultAsync());
    console.log("params", params);
    return () => { };
  }, [params]);

  return (
    <>
     <AudioCommentsModal show={show} hide={handleClose} currentAudioTest={currentAudioTest}/>

      <div className="downloadReportArea">
        <Row>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12}></Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} className="text-end">
            <Pdf
              targetRef={componentRef}
              filename="view-report.pdf"
              options={PdfOptions}
              onComplete={(e) => setShowHeader(false)}
              x={5} y={10} scale={0.8}
            >
              {({ toPdf }) => (
                <Button className="btn-pdf" onClick={() => {
                  setShowHeader(true);
                  toPdf();
                }}>
                  <PdfIcon />
                  PDF
                </Button>
              )}
            </Pdf>
            <Button className="btn-print" onClick={() => {
              // setShowHeader(true);
              handlePrint();
            }}>
              <PrintIcon />
              Print
            </Button>
          </Col>
        </Row>
      </div>

      <div className="viewReportContent" ref={componentRef}>

        {showHeader && <Row className="text-center mb-4">
          <Col >
            <h1>View Report</h1>
          </Col>
        </Row>}

        {patients
          .filter((item) => item.id == params?.patientId)
          .map((patient, index) => {
            return (
              <Row key={index} className="">
                  <div className="text-center w-100">
                    <img src={logo} alt="spetra-logo" className="p-2" />
                  </div>
                 <Table className="text-center w-100">
                      <tr>
                        <td className="align-top"> 
                              <img
                                src={patient.dp === null ? profilePic : patient.dp}
                                className="img-fluid"
                                alt="profile-pic"
                                style={{width:"250px", height:"250px"}}                   
                              />
                          </td>
                          <td className="align-top">
                              <table className="w-100">
                                  <tr>
                                      <td>                                              
                                         <div className="display-block viewReportData">
                                            <table>
                                                <tr>
                                                  <td>  
                                                    <h2>Name</h2>
                                                    <p>{patient.name}</p></td>
                                                  <td>
                                                    <h2>Age</h2>
                                                    <p>{calculateAge(patient.dob)}</p>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>  
                                                    <h2>Name of Father/Mother/Guardian</h2>
                                                    <p>{patient.gaurdian_name}</p>
                                                    </td>
                                                  <td>
                                                     <h2>Guardian Relationship</h2>
                                                    <p>{patient.gaurdian_type}</p>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>  
                                                 
                                                    <h2>Contact Number</h2>
                                                    <p>{patient.phone}</p>
                                                    </td>
                                                  <td>
                                                     <h2>Address</h2>
                                                    <p>{patient.address}</p>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>                                                 
                                                    <h2>Implant Name</h2>
                                                    <p>{patient.name_of_implant}</p>                                
                                       
                                                  </td>
                                                  <td>
                                                    <h2>Date of Surgery</h2>
                                                    <p>{patient.date_of_surgery}</p>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>                                                 
                                                  <h2>Implant Side</h2>
                                                   <p>{patient.implanted_side}</p>                                       
                                                  </td>
                                                  <td>
                                                      <h2>Date of Swicthed ON</h2>
                                                       <p>{patient.date_of_switch_on}</p> 
                                                     
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>                                                 
                                                       <h2>Age of Implantation</h2>
                                                       <p>{patient.dob}</p>                                      
                                                  </td>
                                                  <td>
                                                      
                                                  </td>
                                                </tr>
                                               </table>
                                                   
                                           </div>
                                                
                                      </td>
                                  </tr>
                                 
                              </table>
                          </td>
                      </tr>
                   </Table>
               
              </Row>
            );
          })}
        <div className="viewReportTable">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date of Test</th>
                    <th>Test</th>
                    <th>Score</th>
                    <th>Comments</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {testResults
                    .filter((item) => item.patient_id == params?.patientId)
                    .map((result, index) => {
                      return (
                        <tr key={index + "_" + result?.test}>
                          <td>
                            {format(
                              new Date(result?.date_of_test),
                              "dd MMM yyyy"
                            )}
                          </td>
                          <td>{result?.test}</td>
                          <td>
                            {/* Test score - PIT Test A,B */}
                            {result?.test.startsWith("Picture") && (
                              <span>
                                {" "}
                                {result?.test === "Picture Identification Test - A"
                                  ? "PIT - A"
                                  : "PIT - B"}{" "}
                                {Math.round(result?.levels[0]?.overall_perc)} %{" "}
                              </span>
                            )}
                            {/* Test score - ESPT */}
                            {result?.test.startsWith("Early") && (
                              <>
                                {result?.levels.filter(
                                  (item) => item.level == "1"
                                )[0] && (
                                    <span>
                                      Syllable Categorization{" "}
                                      {
                                        result?.levels.filter(
                                          (item) => item.level == "1"
                                        )[0]?.correctly_answered
                                      }{" "}
                                    </span>
                                  )}
                                {result?.levels.filter(
                                  (item) => item.level == "2"
                                )[0] && (
                                    <span>
                                      Bisyllable Word Recognition{" "}
                                      {
                                        result?.levels.filter(
                                          (item) => item.level == "2"
                                        )[0]?.correctly_answered
                                      }{" "}
                                    </span>
                                  )}
                                {result?.levels.filter(
                                  (item) => item.level == "3"
                                )[0] && (
                                    <span>
                                      Trisyllable Word Recognition{" "}
                                      {
                                        result?.levels.filter(
                                          (item) => item.level == "3"
                                        )[0]?.correctly_answered
                                      }{" "}
                                    </span>
                                  )}
                              </>
                            )}
                            {/* Test score - Sentence Perception test */}
                            {result?.test.startsWith("Sentence") && (
                              <span>
                                {" "}
                                {(
                                  <span>
                                    {
                                      result?.levels[0]?.correctly_answered
                                    }{" / "}
                                    {
                                      result?.levels[0]?.total_questions
                                    }{" "}
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                          <td className="commentIcon">
                      {result?.test === "Sentence Perception Test" ? (
                        <span onClick={(e)=>handleShow(e,result)}>
                          <BsFillChatSquareTextFill />
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                          {/* over all status - PIT Test A,B */}
                          {result?.test.startsWith("Picture") && (
                            <td
                              className={Math.round(result?.levels[0]?.total_questions) === Math.round(result?.levels[0]?.questions_attended)
                                ? "results-status-completed"
                                : "results-status-incomplete"
                              }
                            >
                              <span>
                                {Math.round(result?.levels[0]?.total_questions) === Math.round(result?.levels[0]?.questions_attended)
                                  ? "Completed"
                                  : "Incomplete"}
                              </span>
                            </td>
                          )}
                          {/* over all status - ESPT */}
                          {result?.test.startsWith("Early") && (
                            <td
                              className={
                                getESPTOverAllResult(result?.levels)
                                  ? "results-status-completed"
                                  : "results-status-incomplete"
                              }
                            >
                              <span>
                                {getESPTOverAllResult(result?.levels)
                                  ? "Completed"
                                  : "Incomplete"}
                              </span>
                            </td>
                          )}
                          {/* over all status - Sentence Perception test */}
                          {result?.test.startsWith("Sentence") && (
                            <td
                              className={
                                Math.round(result?.levels[0]?.total_questions) === Math.round(result?.levels[0]?.questions_attended)
                                  ? "results-status-completed"
                                  : "results-status-incomplete"
                              }
                            >
                              <span>
                                {Math.round(result?.levels[0]?.total_questions) === Math.round(result?.levels[0]?.questions_attended)
                                  ? "Completed"
                                  : "Incomplete"}
                              </span>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ViewReportDetails;
