import { Button } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const AddTestButtonComponent = () => {
  const addTestBtn = useNavigate();
  const addTestBtnFunc = () => {
    const addTestBtnUrl = "/addTest";
    addTestBtn(addTestBtnUrl);
  };
  return (
    <>
      <Button type="button" className="btn-add" onClick={addTestBtnFunc}>
        <BsPlusLg />
        Add Test
      </Button>
    </>
  );
};

export default AddTestButtonComponent;
