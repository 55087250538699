import { Button } from "react-bootstrap";
import LogOutIcon from "./LogoutIcon";
import { useNavigate } from "react-router-dom";
import {LOG_OUT} from "../../features/login/loginSlice"
import { useDispatch} from "react-redux";

const Logout = () => {
const dispatch = useDispatch()
const nav =  useNavigate()
  const logoutFunc = () => {
   dispatch(LOG_OUT())
   nav("/")
  };




  return (
    <>
      <div className="logOut">
        <Button type="button" onClick={logoutFunc}>
          <LogOutIcon />
          Logout
        </Button>
      </div>
    </>
  );
};
export default Logout;
