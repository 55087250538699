import { baseURL } from "../../constants";

export function loginUser(credentials = {}) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(credentials),
  };
  return new Promise((resolve, reject) => {
    fetch(baseURL + "api/login/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function logout() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: null,
  };
  return new Promise((resolve, reject) => {
    fetch(baseURL + "api/logout/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function getAllUsers(credentials = {}) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: null,
  };
  return new Promise((resolve, reject) => {
    fetch(baseURL + "api/getall/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function getUser(userProfile = {}) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userProfile),
  };
  return new Promise((resolve, reject) => {
    fetch(baseURL + "api/profile/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function updateUser(userProfile = {}) {
  const requestOptions = {
    method: "POST",
    headers: { },
    body: userProfile,
  };
  return new Promise((resolve, reject) => {
    fetch(baseURL + "api/update/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function deleteUser(userProfile = {}) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userProfile),
  };
  return new Promise((resolve, reject) => {
    fetch(baseURL + "api/delete/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}

export function activateUser(userProfile = {}) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userProfile),
  };
  return new Promise((resolve, reject) => {
    fetch(baseURL + "api/activate/", requestOptions)
      .then((response) => {
        response.json().then((res) => {
          setTimeout(() => resolve({ data: res }), 500);
        });
      })
      .catch((err) => reject({ data: err }));
  });
}
