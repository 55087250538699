import { useSelector } from "react-redux"

export default function Permissions({role, children}) {

    const r_role = state =>state.login.role
    const roles = useSelector(r_role)
    let canPass = false
    
    role.forEach(element => {
              if(element === roles)  canPass = true
     })
     //alert(canPass)
     if(canPass){
        return children
     }else{
       return null
     }
}