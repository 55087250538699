import RegistrationForm from "../components/registration/RegistrationForm";
import logo from "../global-images/spetra-logo.svg";
import "../components/registration/registration.css";
import { Col, Container, Row } from "react-bootstrap";
const Registration = () => {
  return (
    <>
      <div className="registrationBackground">
        <Container>
          <Row>
            <Col xxl={2} xl={2} lg={2} md={12}></Col>
            <Col xxl={8} xl={8} lg={8} md={12}>
              <div className="registrationFormBox">
                <img
                  src={logo}
                  alt="spetra-logo"
                  className="spetraRegisterLogo"
                />
                <RegistrationForm />
              </div>
            </Col>
            <Col xxl={2} xl={2} lg={8} md={12}></Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Registration;
