import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [], // Initial data array
};

const patientAgeGraphSlice = createSlice({
  name: "patientAgeGraph",
  initialState,
  reducers: {
    setPatientAgeGraph: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setPatientAgeGraph } = patientAgeGraphSlice.actions;
export default patientAgeGraphSlice.reducer;
