import { Button } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const MediaBackButton = () => {
  const mediaBtnBack = useNavigate();
  const mediaBackFunc = () => {
    const medidaBackPath = "/media";
    mediaBtnBack(medidaBackPath);
  };
  return (
    <>
      <Button type="button" className="btn-back" onClick={mediaBackFunc}>
        <BsArrowLeft />
        Back
      </Button>
    </>
  );
};

export default MediaBackButton;
