const DashBoardTickIcon = () => {
  return (
    <svg viewBox="0 0 36.514 32.137">
      <defs></defs>
      <path
        d="M53,64.087c.237-.434.434-.828.631-1.222a37.215,37.215,0,0,1,6.663-8.595,58.222,58.222,0,0,1,13.562-9.777.833.833,0,0,1,1.143.2.877.877,0,0,1-.276,1.183A59.46,59.46,0,0,0,56.784,73.351c-.237.749-.434,1.5-.631,2.287-.118.394-.2.749-.67.828-.434.118-.71-.158-.986-.434-3.509-3.785-6.978-7.57-10.487-11.354C42.355,62.864,40.7,61.09,39,59.277c-.276-.315-.513-.631-.315-1.064a.979.979,0,0,1,1.025-.513h7.018a1.3,1.3,0,0,1,1.1.513l4.731,5.441ZM68.336,49.736a.3.3,0,0,1-.158.079,48.39,48.39,0,0,0-9.462,8.476,27.519,27.519,0,0,0-4.652,7.333c-.355.828-.986.946-1.577.237-1.814-2.09-3.667-4.218-5.48-6.308a.842.842,0,0,0-.67-.315H41.33c4.573,4.928,9.068,9.817,13.6,14.706A62.094,62.094,0,0,1,68.336,49.736Z"
        transform="translate(-38.615 -44.357)"
      />
    </svg>
  );
};

export default DashBoardTickIcon;
