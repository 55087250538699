const ViewIcon = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <path
          d="M85,53.7C64.9,53.8,46.9,66.2,39.7,85c9.5,25,37.5,37.7,62.5,28.2c13-4.9,23.2-15.2,28.2-28.2
	C123.2,66.2,105.2,53.8,85,53.7z M85,105.9c-11.5,0.1-21-9.1-21.1-20.6c-0.1-11.5,9.1-21,20.6-21.1s21,9.1,21.1,20.6
	c0,0.1,0,0.2,0,0.3C105.7,96.5,96.5,105.8,85,105.9z M85,72.5c-6.9-0.1-12.6,5.5-12.7,12.4c-0.1,6.9,5.5,12.6,12.4,12.7
	c6.9,0.1,12.6-5.5,12.7-12.4c0-0.1,0-0.1,0-0.2C97.5,78.2,91.9,72.6,85,72.5C85.1,72.5,85.1,72.5,85,72.5z"
        />
      </svg>
    </>
  );
};

export default ViewIcon;
