import logo from "../global-images/spetra-logo.svg";
import "../components/passwordreset/reset.css";
import { Container, Row, Col } from "react-bootstrap";
import NewpasswordForm from "../components/passwordreset/NewpasswordForm";
const NewPassword = () => {
  return (
    <>
      <div className="newPasswordBackground">
        <Container>
          <Row>
            <Col xxl={3} xl={3} lg={3}></Col>
            <Col xxl={6} xl={6} lg={6}>
              <div className="newPasswordBox">
                <img src={logo} alt="spetra-logo" className="spetraResetLogo" />
                <NewpasswordForm />
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={3}></Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default NewPassword;
