import { Container, Row, Col, Button } from "react-bootstrap";
import profilePic from "../../global-images/profilePic.svg";
import { BsPencil } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { calculateAge } from "../../utils";
const ViewNameDetails = (props) => {
  const navigate = useNavigate();
  const p_profiles = (state) => state.patient.patients;
  const all_patients = useSelector(p_profiles);
  const patient = all_patients.filter(
    (p) => parseInt(p.id) === parseInt(props?.id)
  )[0];
  const [patientProfile, setPatientProfile] = useState({
    id: "",
    name: "",
    age: "",
    gaurdian_name: "",
    address: "",
    phone: "",
    gaurdian_type: "",
    name_of_implant: "",
    date_of_surgery: "",
    implanted_side: "",
    date_of_switch_on: "",
    age_of_implantation: "",
    dp: "",
  });
  const nameUpdateFunc = () => {
    const nameUpdatePath = "/updateName";
    navigate(nameUpdatePath);
  };

  useEffect(() => {
    let p = {
      patient_id: patient?.id,
      name: patient?.name,
      dob: patient?.dob,
      gaurdian_name: patient?.gaurdian_name,
      address: patient?.address,
      phone: patient?.phone,
      gaurdian_type: patient?.gaurdian_type,
      name_of_implant: patient?.name_of_implant,
      date_of_surgery: patient?.date_of_surgery,
      implanted_side: patient?.implanted_side,
      date_of_switch_on: patient?.date_of_switch_on,
      age_of_implantation: patient?.age_of_implantation,
      dp: patient?.dp,
      created_by: patient?.created_by,
    };
    setPatientProfile((patientProfile) => ({ ...patientProfile, ...p }));
    return () => {};
  }, [patient, props]);
  return (
    <>
      <div className="ViewNameDetails">
        <Container fluid>
          <Row>
            <Col xxl={2} xl={2} lg={2} md={2} className="text-center">
              {patientProfile.dp === null || patientProfile.dp === "" ? (
                <img src={profilePic} className="img-fluid" alt="profile-pic" />
              ) : (
                <img
                  src={patientProfile?.dp}
                  className="img-fluid"
                  alt="profile-pic"
                />
              )}

              {/* <Button
                type="button"
                className="btn-edit"
                onClick={nameUpdateFunc}
              >
                <BsPencil />
                Edit Profile
              </Button> */}
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5}>
              <div className="ViewNameDetailsData">
                <h2>Name</h2>
                <p>{patientProfile?.name}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Age</h2>
                <p>{calculateAge(patientProfile?.dob)}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Name of Father/Mother/Guardian</h2>
                <p>{patientProfile?.gaurdian_name}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Guardian Relationship</h2>
                <p>{patientProfile?.gaurdian_type}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Contact Number</h2>
                <p>{patientProfile?.phone}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Address</h2>
                <p>{patientProfile?.address}</p>
              </div>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5}>
              <div className="ViewNameDetailsData">
                <h2>ID</h2>
                <p> {patientProfile?.id}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Name of the Implant</h2>
                <p> {patientProfile?.name_of_implant}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Date of Surgery</h2>
                <p> {patientProfile?.date_of_surgery}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Implanted Side</h2>
                <p> {patientProfile?.implanted_side}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Date of Switch-on</h2>
                <p> {patientProfile?.date_of_switch_on}</p>
              </div>
              <div className="ViewNameDetailsData">
                <h2>Age of Implantation</h2>
                <p> {patientProfile?.age_of_implantation}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ViewNameDetails;
