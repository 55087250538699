import { Badge,Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setTestMasterCount } from "../../features/dashboard/TestMasterCountSlice";
import { baseURL } from "../../../src/constants";
import DashBoardTickIcon from "./DashBoardTickIcon";

const TestBlock = () => {
  const dispatch = useDispatch();
  const testMasterCountData = useSelector((state) => state.testMasterCount.data);

  useEffect(() => {
    // Fetch the registration count from your API endpoint
    // Replace the following with your actual API call
    const fetchTestMasterCount = async () => {
      try {
        const response = await fetch(`${baseURL}test/testmaster_count/`);
        const data = await response.json();
        console.log("Received", data.result);
        dispatch(setTestMasterCount(data.result));
      } catch (error) {
        console.error("Error fetching registration approved count:", error);
      }
    };

    fetchTestMasterCount();
  }, [dispatch]);

  return (
<div className="dashboard-box">
  <Container fluid>
    <Row>
      <Col xxl={3} xl={3} lg={3} md={12} sm={12}>
        <DashBoardTickIcon />
      </Col>
      <Col xxl={9} xl={9} lg={9} md={12} sm={12}>
      <p className="fs-6 fw-bold text-uppercase mb-3">Speech Perception Test</p>
        {Array.isArray(testMasterCountData) &&
          testMasterCountData.map((testMaster, index) => (
            <div key={index} className="d-flex align-items-center mb-3 justify-content-between">
            <div className="col-9">
              <p className="mb-0" style={{ fontSize: '15px' }}>{testMaster.name}</p>
            </div>
            <div className="col-3 text-end">
              <Badge bg="spetra-bg" style={{ width: '50px'}}>{testMaster.test_count}</Badge>
            </div>
          </div>
          ))}
      </Col>
    </Row>
  </Container>
</div>
  );
};
export default TestBlock;
