import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
const NameBackButton = () => {
  const BackButton = useNavigate();
  const BackButtonFunc = () => {
    const BackBtn = "/name";
    BackButton(BackBtn);
  };
  return (
    <Button type="button" className="btn-back" onClick={BackButtonFunc}>
      <BsArrowLeft />
      Back
    </Button>
  );
};

export default NameBackButton;
