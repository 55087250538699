import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Modal,
  Button,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dummyImage from "../../global-images/dummy-img.svg";
import DeleteIcon from "../../global-images/DeleteIcon";
import EditIcon from "../../global-images/EditIcon";
import DeletePopupIcon from "../../global-images/DeletePopupIcon";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_MSG,
  getAllQuestionsAsync,
} from "../../features/media/mediaSlice";
import AddAudioWithImage from "./AddAudioWithImage";
import AddAudioOnly from "./AddAudioOnly";
import UpdateAudioWithImage from "./UpdateAudioWithImage";
import { stringToObject } from "rsuite/esm/utils";
const MediaList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalshow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const [deletesucessmodalshow, setdeletesucesssetModalShow] = useState(false);
  const deletesucesshandleClose = () => setdeletesucesssetModalShow(false);
  const deletesucesshandleShow = () => setdeletesucesssetModalShow(true);

  const q_questions = (state) => state.media.questions;
  const questions = useSelector(q_questions);
  const msg = (state) => state.media.message;
  const message = useSelector(msg);

  useEffect(() => {
    dispatch(getAllQuestionsAsync());

    const timeId = setTimeout(() => {
      setdeletesucesssetModalShow(false);
    }, 2000);
    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, questions.length,message]);
  const audioWithImageBtn = useNavigate();
  const audioWithImgFunc = () => {
    const audioWithImagePath = "/updateMedia";
    audioWithImageBtn(audioWithImagePath);
  };

  const audioOnlyBtn = useNavigate();
  const audioOnlyBtnFunc = () => {
    const audioOnlyBtnPath = "/updateMedia";
    audioOnlyBtn(audioOnlyBtnPath);
  };
  const [mediatype, setMediaType] = useState("audio-with-image");
  const onsetMediaType = (value) => {
    setMediaType(value);
  };
  const DisplayTable = () => {
    if (mediatype === "audio-with-image") {
      return (
        <>
          <div className="addMediaFiles">
            <fieldset className="fieldSetborder">
              <legend className="legendtextClass">Add Media</legend>
              <AddAudioWithImage />
            </fieldset>
          </div>
          <div className="mediaAudiowithImage">
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                <Table responsive className="scroll-auto">
                  <thead>
                    <tr>
                      {/* <th>ID</th> */}
                      <th>Image</th>
                      <th>Audio</th>
                      <th>Audio Title</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(props?.searchTextValue
                      ? questions.filter(
                          (item) =>
                            item.audio_title
                              .toLowerCase()
                              .startsWith(props?.searchTextValue) &&
                            item.qtype == 1
                        )
                      : questions.filter((item) => item.qtype == 1)
                    ).map((question) => {
                      return (
                        <tr>
                          {/* <td>{question?.qid}</td> */}
                          <td>
                            <img
                              src={question?.image}
                              className="dummy-img"
                              alt="img"
                            />
                          </td>
                          <td>
                            <audio controls>
                              <source src={question?.audio}></source>
                            </audio>
                          </td>
                          <td>{question?.audio_title} </td>
                          <td>
                            <span >
                              {/* <DeleteIcon /> */}
                             <Link to= { "/updatemedia/1/" +  question?.qid} ><EditIcon/></Link>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="addMediaFiles">
            <fieldset className="fieldSetborder">
              <legend className="legendtextClass">Add Media</legend>
              <AddAudioOnly />
            </fieldset>
          </div>
          <div className="mediaAudioOnly">
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Audio Title</th>
                      <th>Audio</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(props?.searchTextValue
                      ? questions.filter(
                          (item) =>
                            item.audio_title
                              .toLowerCase()
                              .startsWith(props?.searchTextValue) &&
                            item.qtype == 2
                        )
                      : questions.filter((item) => item.qtype == 2)
                    ).map((question) => {
                      return (
                        <tr>
                          {/* <td>{question?.qid}</td> */}
                          <td>{question?.audio_title} </td>
                          <td>
                            <audio controls>
                              <source src={question?.audio}></source>
                            </audio>
                          </td>

                          <td>
                            <span>
                              <Link to= { "/updatemedia/2/" +  question?.qid} ><EditIcon/></Link>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <Modal
        show={modalshow}
        onHide={handleClose}
        className="deleteName text-center"
        centered
      >
        <Modal.Body>
          <DeletePopupIcon />
          <div className="deletePopTextArea">
            <h2>Are you sure?</h2>
            <p>
              Do you really want to the media? This process cannot be undone.
            </p>
            <Button
              type="button"
              className="btn-name-delete-cancel"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn-name-delete"
              onClick={() => {
                deletesucesshandleShow();
                handleClose();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* sucess popup */}
      <Modal
        show={deletesucessmodalshow}
        onHide={deletesucesshandleClose}
        className="deleteNameSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="deletePopTextAreaOnSuccess">
            <p>Media has been deleted successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="mediaListSelect">
        <Container fluid>
          <Row>
            <Col xxl={3} xl={3} lg={3} md={12}></Col>
            <Col xxl={6} xl={6} lg={6} md={12}>
              <Form.Group>
                <Form.Label>Media Type</Form.Label>
                <Form.Select
                  value={mediatype}
                  onChange={(e) => onsetMediaType(e.target.value)}
                >
                  <option value={"audio-with-image"}>Audio With Image</option>
                  <option value={"audio-only"}>Audio Only</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={12}></Col>
          </Row>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <DisplayTable />
          </Col>
        </Container>
      </div>

      {/* {mediatype === "audio-with-image" && <div>Table 1</div>}
      {mediatype === "audio-only" && <div>Table 2</div>} */}
    </>
  );
};

export default MediaList;
