import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Image,
  Form,
  Stack,
} from "react-bootstrap";
import { BsCheck2 } from "react-icons/bs";
//import LeftSidebar from "../../components/sidebar/LeftSidebar";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_STATUS,
  testResponseAsync,
  testSubmitAsync,
  CLEAR_TEST_MSG,
} from "../../features/tests/testSlice";
//import { shuffle } from "../../utils";
import { useState } from "react";
import { s3URL } from "../../constants";
import { useCallback } from "react";
import ReactAudioPlayer from "react-audio-player";
import { shuffle } from "../../utils";
import logo from "../../global-images/spetra-logo.svg";

const EarlySpeechTrailTests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const t_name = (state) => state.login.profile?.name;
  const examiner = useSelector(t_name);

  const t_q = (state) => state.test.questions;
  const testQuestions = useSelector(t_q);
  const t_s = (state) => state.test.sessionId;
  const sessionId = useSelector(t_s);
  const p_patients = (state) => state.patient.patients;
  const patients = useSelector(p_patients);
  const t_details = (state) => state.test.currentDetails;
  const currentDetails = useSelector(t_details);
  const t_message = (state) => state.test.message;
  const message = useSelector(t_message);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAudio, setCurrentAudio] = useState("");
  const [currentLevel, setCurrentLevel] = useState(1);
  const [currentOptions, setCurrentOptions] = useState({
    qid: "",
    options: [],
    level_name: "",
  });
  const [qidResponseStore, setQidResponseStore] = useState([]);
  const [qidResponse, setQidResponse] = useState("");
  const [exitTest, setExitTest] = useState(false);

  // test submit popup
  const [testsubmitmodal, setTestSubmitModal] = useState(false);
  const testsubmithandleClose = () => setTestSubmitModal(false);
  const testsubmithandleShow = (e) => {
    e.preventDefault();
    setQidResponse("");
    //last question answer submission on each level
    const resp = {
      session_id: sessionId,
      qid: currentOptions.qid,
      qid_response: qidResponse,
      level: currentLevel,
    };
    if (qidResponse) {
      // dispatch(testResponseAsync(resp));
    }
    setTestSubmitModal(true);
  };

  const SkipLevel = (e) => {
    e.preventDefault();
    setQidResponse("");
    //last question answer submission on each level
    const resp = {
      session_id: sessionId,
      qid: currentOptions.qid,
      qid_response: qidResponse,
      level: currentLevel,
    };
    if (qidResponse) {
      // dispatch(testResponseAsync(resp));
    }
    if (currentLevel == 1) {
      setCurrentLevel(2);
      setCurrentIndex(0);
    } else if (currentLevel == 2) {
      setCurrentLevel(3);
      setCurrentIndex(0);
    }
  };

  const [submitsucessmodalshow, setsubmitsucesssetModalShow] = useState(false);
  const submitsucesshandleClose = () => setsubmitsucesssetModalShow(false);
  const [submitConfirm, setSubmitConfirm] = useState(false);

  const testsBackBtnFunc = () => {
    dispatch(RESET_STATUS());
    const testsBackBtnPath = "/tests/";
    navigate(testsBackBtnPath);
  };

  const PrepareLevels = () => {
    if (currentLevel === 1) {
      return testQuestions?.level1;
    } else if (currentLevel === 2) {
      return testQuestions?.level2;
    } else if (currentLevel === 3) {
      return testQuestions?.level3;
    }
  };

  const fetchQuestions = useCallback(() => {
    try {
      if (
        testQuestions === "" ||
        testQuestions === "undefined" ||
        testQuestions === null
      )
        return;
      console.log("index: ", currentIndex);
      const q = PrepareLevels()?.quesions.slice(0, 5);
      let opts = [];
      if (q === "undefined") return;
      Array.from(q).forEach((e) => {
        opts.push({
          id: e?.qid,
          image: s3URL + e?.image,
          audio: s3URL + e?.audio,
        });
      });
      opts = shuffle(opts);

      if (currentIndex > opts.length) {
        setCurrentIndex(opts.length);
        return;
      }
      const qid = q[currentIndex].qid;
      const audio = s3URL + q[currentIndex].audio;

      const co = {
        qid: qid,
        options: opts,
        level_name: PrepareLevels()?.level_name,
      };
      setCurrentOptions(co);
      setCurrentIndex(currentIndex);
      setCurrentAudio(audio.toString());
    } catch (ex) {
      console.log(ex);
    }
  }, [testQuestions, currentIndex, currentLevel]);

  function handleBack(e) {
    e.preventDefault();
    setQidResponse(qidResponseStore[currentIndex - 1]);
    setCurrentIndex(currentIndex - 1);
    setCurrentOptions((currentOption) => ({
      ...{ currentOption },
      ...{ qid: currentOptions.options.filter(item => item.id == qidResponseStore[currentIndex - 1])[0]?.id },
    }));
    setCurrentAudio(
      currentOptions.options.filter(item => item.id == qidResponseStore[currentIndex - 1])[0]?.audio.toString()
    );

  };

  function handleNext(e) {
    e.preventDefault();
    let isAlreadyAnswered = false;
    if (qidResponseStore[currentIndex + 1]) {
      setQidResponse(qidResponseStore[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
      setCurrentOptions((currentOption) => ({
        ...{ currentOption },
        ...{ qid: currentOptions.options.filter(item => item.id == qidResponseStore[currentIndex + 1])[0]?.id },
      }));
      setCurrentAudio(
        currentOptions.options.filter(item => item.id == qidResponseStore[currentIndex + 1])[0]?.audio.toString()
      );
      isAlreadyAnswered = true;
    }
    if (currentIndex + 1 != currentOptions.options.length) {
      if (!isAlreadyAnswered) {
        setQidResponse("");
        setCurrentIndex(currentIndex + 1);
        setCurrentOptions((currentOption) => ({
          ...{ currentOption },
          ...{ qid: currentOptions.options[currentIndex + 1]?.id },
        }));
        setCurrentAudio(
          currentOptions.options[currentIndex + 1]?.audio.toString()
        );
      }
    } else {
      if (
        currentLevel == 1 &&
        currentIndex + 1 == currentOptions.options?.length
      ) {
        setCurrentLevel(2);
        setCurrentIndex(0);
        setQidResponse("");
        setQidResponseStore([]);
      } else if (
        currentLevel == 2 &&
        currentIndex + 1 == currentOptions.options?.length
      ) {
        setCurrentLevel(3);
        setCurrentIndex(0);
        setQidResponse("");
        setQidResponseStore([])
      }
    }
    const resp = {
      session_id: sessionId,
      qid: currentOptions.qid,
      qid_response: qidResponse,
      level: currentLevel,
    };
    if (qidResponse) {
      // dispatch(testResponseAsync(resp));
    }
  }

  useEffect(() => {
    console.log(message);
    fetchQuestions();

    if (message?.message === "Test submitted successfully") {
      setsubmitsucesssetModalShow(true);
    }
    const timeId = setTimeout(() => {
      if (message?.message === "Test submitted successfully") {
        setsubmitsucesssetModalShow(false);
        dispatch(CLEAR_TEST_MSG());
        testsBackBtnFunc();
      }
      if (submitsucessmodalshow) {
        // setsubmitsucesssetModalShow(false);
        // if ((currentIndex + 1 == currentOptions.options?.length) || currentIndex != 0) {
        dispatch(CLEAR_TEST_MSG());
        testsBackBtnFunc();
        // }
      }
    }, 3000);

    if (submitConfirm) {
      // if (
      //   currentLevel == 1 &&
      //   currentIndex + 1 == currentOptions.options?.length
      // ) {
      //   setCurrentLevel(2);
      //   setCurrentIndex(0);
      //   setsubmitsucesssetModalShow(true);
      // } else if (
      //   currentLevel == 2 &&
      //   currentIndex + 1 == currentOptions.options?.length
      // ) {
      //   setCurrentLevel(3);
      //   setCurrentIndex(0);
      //   setsubmitsucesssetModalShow(true);
      // } else {
      setsubmitsucesssetModalShow(true);
      // dispatch(testSubmitAsync({ session_id: sessionId }));
      // }
      setSubmitConfirm(false);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [
    dispatch,
    fetchQuestions,
    currentDetails,
    submitConfirm,
    message,
    patients,
    currentLevel,
  ]);

  return (
    <>
      {/* submit popup */}
      <Modal
        show={testsubmitmodal}
        onHide={testsubmithandleClose}
        className="submitTest text-center"
        centered
      >
        <Modal.Body>
          <div className="submitPopUpTest">
            <h2>Are you sure?</h2>
            <p>
              Do you really want to {exitTest ? "exit" : "submit"} the{" "}
              {currentOptions?.level_name} Trail Test?
            </p>
            <Button
              type="button"
              className="btn-cancel"
              onClick={testsubmithandleClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn-submit"
              onClick={() => {
                setSubmitConfirm(true);
                testsubmithandleClose();
              }}
            >
              {exitTest ? "Exit" : "Submit"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={submitsucessmodalshow}
        onHide={submitsucesshandleClose}
        className="submitTestSucess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="submitPopTextAreaOnSuccess">
            <p>Your Trial test is done!  Results will not be saved.</p>
          </div>
        </Modal.Body>
      </Modal>

      <div className="spetraLayout writeTestScreen">
        <Container fluid>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="mainContentboxshadow"
            >
              <div className="ContentMainBody TestsPage">
                <Container fluid>
                  <Row className="testsTopArea">
                    <Col
                      xxl={3}
                      xl={3}
                      lg={3}
                      md={4}
                      className="testsPatientName"
                    >
                      <img
                        src={logo}
                        alt="spetra-logo"
                        className="sidebarOpenLogo"
                      />
                      <span>
                        <h3>Name</h3>
                        <h2>
                          {
                            patients.filter(
                              (item) => item.id == currentDetails?.patient_id
                            )[0]?.name
                          }
                        </h2>
                      </span>
                    </Col>
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={5}
                      className="text-center onGoingTestName"
                    >
                      <h3>{currentDetails?.testName} (Trail)</h3>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} className="text-end">
                      <Button
                        type="button"
                        className="btn-back text-right"
                        // onClick={testsBackBtnFunc}
                        onClick={(e) => {
                          setExitTest(true);
                          testsubmithandleShow(e);
                        }}
                      >
                        <BsArrowLeft />
                        Exit
                      </Button>
                    </Col>
                  </Row>
                  {/* <div className="TestsDetailsBox">
                    <Row>
                      <Col
                        xxl={2}
                        xl={2}
                        lg={3}
                        md={6}
                        sm={6}
                        className="margin-md-bottom-20"
                      >
                        <h3>Name</h3>
                        <h2>
                          {
                            patients.filter(
                              (item) => item.id == currentDetails?.patient_id
                            )[0]?.name
                          }
                        </h2>
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={6}
                        className="margin-md-bottom-20"
                      >
                        <h3>Test Name</h3>
                        <h2>{currentDetails?.testName}</h2>
                      </Col>
                      <Col xxl={4} xl={4} lg={2} md={6} sm={6}>
                        <h3>Subtest</h3>
                        <h2>{currentOptions.level_name}</h2>
                      </Col>
                      <Col xxl={2} xl={2} lg={3} md={6} sm={6}>
                        <h3>Examiner</h3>
                        <h2>{examiner}</h2>
                      </Col>
                    </Row>
                  </div> */}
                  <div className="TestsQuestions">
                    <Row>
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={12}
                        className="text-center"
                      >
                        <h1>
                          Total Questions: {currentIndex + 1}/{" "}
                          {currentOptions?.options?.length}
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <Form>
                        <Row>
                          {currentOptions !== "undefined" ||
                            currentOptions !== null ||
                            currentOptions !== ""
                            ? currentOptions?.options?.map((rq, index) => {
                              return (
                                <Col
                                  xxl={2}
                                  xl={2}
                                  lg={3}
                                  md={3}
                                  className="text-center mb-4"
                                >
                                  <Image
                                    src={rq?.image}
                                    alt=""
                                    className="img-responsive"
                                    onClick={(e) => {
                                      setQidResponse(rq?.id);
                                      setQidResponseStore(pre => ({ ...pre, ...{ [currentIndex]: rq?.id } }));
                                    }}
                                    style={{
                                      width: "200px",
                                      height: "160px",
                                    }}
                                  ></Image>
                                  <div>
                                    <Form.Check
                                      type="radio"
                                      name="group1"
                                      //id={rq?.id}
                                      checked={rq?.id == qidResponse}
                                      // onClick={(e) =>
                                      //   setQidResponse(e.target.id)
                                      // }
                                      onChange={(e) => {
                                        setQidResponse(rq?.id);
                                        setQidResponseStore(pre => ({ ...pre, ...{ [currentIndex]: rq?.id } }));
                                      }}
                                    ></Form.Check>
                                  </div>
                                </Col>
                              );
                            })
                            : ""}
                        </Row>
                      </Form>
                    </Row>
                  </div>

                  <div className="TestQuestionsPlayArea">
                    <Row>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={5}
                        sm={12}
                        className="text-center w-100 m-auto mb-4"
                      >
                        <ReactAudioPlayer src={currentAudio} controls c />
                      </Col>
                      <Col
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={5}
                        sm={12}
                        className="text-center w-100 m-auto p-2"
                      >
                        <Button
                          type="button"
                          disabled={
                            currentIndex ==
                            0
                          }
                          className="btn-submit"
                          onClick={(e) => handleBack(e)}
                        >
                          Back
                        </Button>

                        <Button
                          type="button"
                          disabled={
                            (currentLevel == 3 &&
                              currentIndex + 1 ==
                              currentOptions.options?.length) ||
                            qidResponse == ""
                          }
                          className="btn-submit"
                          onClick={(e) => handleNext(e)}
                        >
                          {currentLevel != 3 &&
                            currentIndex + 1 == currentOptions.options?.length
                            ? "Submit Level"
                            : "Next"}
                        </Button>
                        <Button
                          type="button"
                          disabled={currentLevel == 3}
                          className="btn-submit"
                          onClick={(e) => SkipLevel(e)}
                        >
                          Skip Level
                        </Button>
                        <Button
                          type="button"
                          className="btn-submit-tests"
                          onClick={(e) => testsubmithandleShow(e)}
                        >
                          Submit Test
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EarlySpeechTrailTests;
