const DeleteIcon = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <path
          d="M102.8,130.4c-11.8,0-23.6,0-35.4,0c-0.1-0.1-0.3-0.1-0.4-0.1c-7.6-1.2-12.7-7-12.7-14.7c0-15.7,0-31.4,0-47.1
	c0-2.2,1.2-3.4,3.4-3.4c18.3,0,36.6,0,54.9,0c0.4,0,0.8,0,1.1,0.1c1.5,0.4,2.3,1.5,2.3,3.4c0,12.3,0,24.5,0,36.8
	c0,3.7,0.1,7.3-0.1,11c-0.3,5.7-3.1,10-8.2,12.5C106.2,129.6,104.4,129.9,102.8,130.4z M70.2,97.7c0,4.5,0,9.1,0,13.6
	c0,0.4,0,0.9,0.1,1.3c0.3,1.7,1.7,3,3.4,3.1c1.8,0.1,3.4-1,3.8-2.6c0.2-0.6,0.2-1.2,0.2-1.8c0-9.1,0-18.2,0-27.3
	c0-0.4,0-0.9-0.1-1.3c-0.3-1.9-2-3.2-3.9-3.1c-2.1,0.2-3.5,1.8-3.5,4.1C70.2,88.4,70.2,93.1,70.2,97.7z M99.9,97.7
	c0-4.5,0-9.1,0-13.6c0-0.4,0-0.9-0.1-1.3c-0.3-1.8-1.7-3-3.5-3.1c-1.7-0.1-3.3,1-3.8,2.6c-0.1,0.5-0.2,1.1-0.2,1.7
	c0,9.2,0,18.3,0,27.5c0,0.4,0,0.9,0.1,1.3c0.3,1.6,1.7,2.9,3.3,3c2.4,0.2,4.1-1.5,4.1-4.1C99.9,107,99.9,102.4,99.9,97.7z
	 M73.4,44.4c0-0.2,0.1-0.2,0.1-0.3c0.2-3.4,1.3-4.5,4.7-4.5c4.7,0,9.3,0,14,0c3,0,4.3,1.2,4.4,4.3c0,0.1,0,0.2,0,0.5
	c0.3,0,0.6,0,0.9,0c4,0,7.9,0,11.9,0c5.7,0,9.6,4,9.7,9.6c0,1.7,0,3.4,0,5.1c0,1.5-0.8,2.3-2.3,2.3c-21.1,0-42.3,0-63.4,0
	c-1.5,0-2.3-0.7-2.3-2.3c0-2.4-0.1-4.7,0.2-7.1c0.5-4.2,4.4-7.6,8.7-7.7c4.2-0.1,8.4,0,12.6,0C72.8,44.4,73.1,44.4,73.4,44.4z"
        />
      </svg>
    </>
  );
};

export default DeleteIcon;
