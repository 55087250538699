import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Modal, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  FETCH_CONTENT_TERMS_HIDE,
  FETCH_CONTENT_PRIVACY_HIDE,
} from "./features/terms/termsSlice";

const Modals = () => {
  const dispatch = useDispatch();
  const msg = (state) => state.terms.isTermsModal;
  const TermsModal = useSelector(msg);

  const termsMsg = (state) => state.terms.isPrivacyModal;
  const privacyModal = useSelector(termsMsg);

  useEffect(() => {}, [TermsModal]);

  const modelTermsHandleClose = () => {
    dispatch(FETCH_CONTENT_TERMS_HIDE());
  };

  const modelPrivacyHandleClose = () => {
    dispatch(FETCH_CONTENT_PRIVACY_HIDE());
  };

  return (
    <>
      <Modal
        show={TermsModal}
        onHide={modelTermsHandleClose}
        className="TermsModalContent"
        scrollable={true}
      >
        <Modal.Header className="text-center">
          <Modal.Title>Terms &amp; Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="TermsSection">
            <p>
              These General Terms incorporate our Privacy Policy which describes
              how we will use any personal information collected as a result of
              your use of the Services. For more information, please see our
              full Privacy Policy.
            </p>
            <p>
              Additional terms, which should be read in conjunction with these
              General Terms apply to some of our Services
            </p>
            <p>
              Please read these General Terms (including the Privacy Policy) as
              well as any Additional Terms carefully, before using any part of
              our App services. By using the app, you confirm that you accept
              the General Terms, the Privacy Policy and any relevant Additional
              Terms and that you agree to comply with them. You should print a
              copy of these General Terms, the Privacy Policy and any relevant
              Additional Terms for future reference
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Privacy</strong>
            </p>
            <p>
              By agreeing to these General Conditions, you agree to the terms of
              our Privacy Policy, which is incorporated herein. Before using the
              app, please carefully review our Privacy Policy. All personal
              information provided to us as a result of your Access will be
              handled in accordance with our Privacy Policy, and by Accessing
              the App, you consent to the collection, storage, use and
              dissemination of your personal information in accordance with this
              Privacy Policy.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Changes to Terms</strong>
            </p>
            <p>
              The General Terms and any Additional Terms may be updated by us
              from time to time. We will post any changes that we make on this
              page and will indicate at the top of the page (or on the first
              page of any Additional Terms) the date that the relevant terms
              were last revised.
            </p>
            <p>
              Your continued use of our App Services after any such changes have
              been made, constitutes your acceptance of the new General Terms
              and/or Additional Terms. If you do not agree to these changes,
              please do not use or access (or continue to use or access)
              Services.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>
                Registration by you and your online Educational App Store
                account
              </strong>
            </p>
            <p>
              Certain aspects of the Services may require you to register and
              provide information about yourself. You agree to: (a) provide
              accurate and complete information about yourself as prompted by
              the relevant registration form and (b) maintain and promptly
              update this information (by using the appropriate forms on our
              website or emailing us at (
              <Nav.Link href="mailto:spetraindia@gmail.com">
                spetraindia@gmail.com
              </Nav.Link>
              )). We may terminate your account and any or all rights that you
              have been granted to make use of our services, if any information
              you provide is inaccurate, false, or incomplete.
            </p>
            <p>
              The registration processes on our App may involve you creating a
              password to enable you to access your account. You are responsible
              for maintaining the confidentiality of your username and password
              and are fully responsible for all activities that occur under your
              password or account. You agree to (a) immediately notify us of any
              unauthorised use of your account and any other breach of security,
              and (b) ensure that you exit from your account at the end of each
              session. We will not be liable for any loss or damage arising from
              your failure to comply with these obligations.
            </p>
            <p>
              You agree that we have no responsibility or liability for the
              deletion or failure to store any messages, other communications or
              other content maintained or transmitted by our Services.
            </p>
            <p>
              We reserve the right to de-activate accounts that are inactive for
              an extended period of time.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Email Communication</strong>
            </p>
            <p>
              By registering to SPETRA you agree to receive occasional “account
              maintenance” email notifications, these will inform you about your
              account details when you first register as well as essential
              account information in relation to the Service. You will also be
              placed onto the mailing list to receive emails that provide
              additional resources and news apps which you can unsubscribe from
              at any stage
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Termination</strong>
            </p>
            <p>
              We may terminate your access use of the Services, with or without
              notice and without liability to you or any third party. We may
              also remove at any time, including, but not limited to, believing
              that you have breached our General Terms.
            </p>
            <p>
              We may also take any other necessary action we deem reasonable in
              connection with a breach of these General Terms.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Complaints about our Website and Services</strong>
            </p>
            <p>
              If you object to anything on our website, please let us know by
              sending an email explaining your concern to{" "}
              <Nav.Link href="mailto:spetraindia@gmail.com">
                spetraindia@gmail.com
              </Nav.Link>{" "}
              and we will take whatever action we deem appropriate.
            </p>
          </div>
        </Modal.Body>
      </Modal>

      {/* privacy policy */}
      <Modal
        show={privacyModal}
        onHide={modelPrivacyHandleClose}
        className="TermsModalContent"
        scrollable={true}
      >
        <Modal.Header className="text-center">
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Personal data collection</strong>
            </p>
            <ul>
              <li>
                As a customer, prospective customer, or user of our Site your
                personal data will be accessible only to the admin, staff and
                employee of SPETRA.
              </li>
              <li>
                Registrants who do not give this consent (or do not have this
                consent provided on their behalf) cannot provide us with their
                personal information and cannot use the Site. You can however
                withdraw your consent at any time.
              </li>
              <li>
                We do not however collect any unnecessary personal information
                from Registrants (for instance, information about religious
                beliefs).
              </li>
              <li>
                Your data may be shared to provide access to the Site, or answer
                your enquiry if we are not able to identify you or collect your
                personal information.
              </li>
            </ul>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Data usage</strong>
            </p>
            <p>
              Your data is to provide access to our products and services, or to
              provide quotes or offers for, and updates about, our products and
              services. It can be used to send a confirmation notice to the
              Registrant of the registration. It is required to administer and
              manage accounts, including password resetting, responding to
              questions, trouble shooting and queries in regard to the App. The
              personal information may be used to send emails regarding service
              development, quality, research and services.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>How long we hold your data?</strong>
            </p>
            <p>
              We will retain the personal information that we collect for as
              long as it is needed to provide access to the App till they are
              registered. We take reasonable steps to delete the personal
              information to use our App lapses and you opt out of receiving
              further communications from us, or if you ask us to delete your
              information. We may however retain and use personal information as
              necessary to comply with our legal and regulatory obligations, to
              resolve disputes and to enforce our agreements, and we may retain
              and use anonymous and aggregated information for performance
              reporting, benchmarking and analytic purposes and for product and
              service improvement.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>How we protect your information</strong>
            </p>
            <p>
              We protect your personal information by using the appropriate
              technical measures such as encryption and password protection to
              secure your information and the systems they are held in. We also
              use operational measures to protect the information, for example
              by limiting the number of people who have access to the databases
              in which our booking information is held.
            </p>
            <p>
              We keep these security measures under review and refer to industry
              security standards to keep up to date with current best practice.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Updating and accessing your personal information</strong>
            </p>
            <p>
              Registrants have a right to request a copy of the personal
              information we hold about them, and Registrants can also update
              their personal information at any time, in each case by contacting
              us. We will get back to you shortly after we receive your email if
              there are any other information that requires action.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Revision of Privacy Policy</strong>
            </p>
            <p>
              SPETRA reserves the right to change, modify, or revise this
              Privacy Policy in order to comply with any applicable laws, to
              reflect changes in our processes or for any other reason at any
              time. Material changes in our Privacy Policy will be communicated
              to Registered Users. However, we also encourage our Registrants to
              check the Privacy Policy periodically to read about any changes,
              modifications, or revisions. Registrants may wish to check it each
              time they submit personal information. You will be deemed to have
              consented to such variation by your continued use of the Site
              following any changes being made.
            </p>
          </div>
          <div className="TermsSection">
            <p className="margin-bottom-5">
              <strong>Contact us</strong>
            </p>
            <p>
              All requests to access update and delete personal information as
              well as questions and concerns about your personal information and
              this Privacy Policy should be directed to{" "}
              <Nav.Link href="mailto:spetraindia@gmail.com">
                spetraindia@gmail.com
              </Nav.Link>
              . We will endeavour to respond to your query as quickly as
              possible.
            </p>
            <p>
              If you are not satisfied with the response you receive, you may
              have the right to contact the regulator responsible for privacy in
              your home country.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Modals;
