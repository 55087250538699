import { Navbar, Nav } from "react-bootstrap";
import { useState, useEffect } from "react";
import DashboardIcon from "./DashboardIcon";
import NameIcon from "./NameIcon";
import MediaMenuIcon from "./MediaMenuIcon";
import TestIcon from "./TestIcon";
import ResultsIcon from "./ResultsIcon";
import ReportIcon from "./ReportIcon";
import { Link, useLocation } from "react-router-dom";
import HelpIcon from "./HelpIcon";
import { useSelector } from "react-redux";
import Permissions from "../login/Permissions";


const SideNav = () => {
  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <div className="sidenav">
      <Navbar>
        <Nav className="flex-column">
        <Permissions role={[1,2,4]}>
          <Nav.Link
            as={Link}
            to="/dashboard"
            className={"menuActive" + (url === "/dashboard" ? " active" : "")}       
            >            
            <DashboardIcon />
            Dashboard
          </Nav.Link>
        </Permissions>
        <Permissions role={[1,2]}>
          <Nav.Link
            as={Link}
            to="/test"
            className={"menuActive" + (url === "/test" ? " active" : "")}             
          >
            <TestIcon />
            Test
          </Nav.Link>
          </Permissions>  
          <Permissions role={[1,2]}>
          <Nav.Link
            as={Link}
            to="/media"
            className={"menuActive" + (url === "/media" ? " active" : "")}
            // style ={ (links.includes("media") === false)? {display:"none"}:{} }
          >
            <MediaMenuIcon />
            Media
          </Nav.Link>
          </Permissions>
          <Permissions role={[1,2,4]}>
          <Nav.Link
            as={Link}
            to="/name"
            className={"menuActive" + (url === "/name" ? " active" : "")}
            // style ={ (links.includes("name") === false)? {display:"none"}:{} }
          >
            <NameIcon />
            Name
          </Nav.Link>
          </Permissions>
          <Permissions role={[1,2,4]}>
          <Nav.Link
            as={Link}
            to="/tests"
            className={"menuActive" + (url === "/tests" ? " active" : "")}
            // style ={ (links.includes("tests") === false)? {display:"none"}:{} }
          >
            <TestIcon />
            Tests
          </Nav.Link>
          </Permissions>
          <Permissions role={[1,2,4]}>
          <Nav.Link
            as={Link}
            to="/results"
            className={"menuActive" + (url === "/results" ? " active" : "")}
            // style ={ (links.includes("results") === false)? {display:"none"}:{} }
          >
            <ResultsIcon />
            Results
          </Nav.Link>
          </Permissions>
          <Permissions role={[1,2]}>
          <Nav.Link
            as={Link}
            to="/report"
            className={"menuActive" + (url === "/report" ? " active" : "")}
            // style ={ (links.includes("report") === false)? {display:"none"}:{} }
          >
            <ReportIcon />
            Report
          </Nav.Link>
          </Permissions>
          <Permissions role={[1,2]}>
          <Nav.Link
            as={Link}
            to="/users"
            className={"menuActive" + (url === "/users" ? " active" : "")}
            // style ={ (links.includes("users") === false)? {display:"none"}:{} }
          >
            <NameIcon />
            Users
          </Nav.Link>
          </Permissions>
          <Permissions role={[1,2,3,4]}>
          <Nav.Link
            as={Link}
            to="/help"
            className={"menuActive" + (url === "/help" ? " active" : "")}
            // style ={ (links.includes("help") === false)? {display:"none"}:{} }
          >
            <HelpIcon />
            Help
          </Nav.Link>
          </Permissions>

        </Nav>
      </Navbar>
    </div>
  );
};

export default SideNav;
