import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuestionsAsync } from "../../features/media/mediaSlice";
import Image from "react-bootstrap/Image";
import PlayButtonIcon from "../tests/PlayButtonIcon";
//import { setDatasets } from "react-chartjs-2/dist/utils";

export default function QuestionBank(props) {
  const dispatch = useDispatch();
  const q_questions = (state) => state.media.questions;
  const questions = useSelector(q_questions);
  const checkBx = useRef([]);

  const handleOnChange = (state, value) => {
    if (state) {
      props.saveQid(value, "add");
    } else {
      props.saveQid(value, "remove");
    }
  };

  useEffect(() => {
    dispatch(getAllQuestionsAsync());
    //console.log(drag);
  }, [dispatch]);

  return (
    <>
      <div className="testQuestionBank">
        <Container>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <div className="questionBankArea">
                {props?.levelName}
                <Row>
                  {questions.filter((item)=>item.qtype == props?.type).map((question, i) => {
                    return (
                      <>
                        {props?.type == 1 && (
                          <Col>
                            <div className="w-100 text-start p-1">
                              <Form.Check
                                type="checkbox"
                                id={question?.qid}
                                value={question?.qid}
                                ref={(el) => (checkBx.current[i] = el)}
                                className=""
                                onChange={(e) =>
                                  handleOnChange(
                                    e.target.checked,
                                    e.target.value
                                  )
                                }
                              />
                              <Image
                                style={{
                                  minHeight: "150px",
                                  minWidth: "150px",
                                  maxHeight: "150px",
                                  maxWidth: "150px",
                                }}
                                src={question.image}
                                id={"i_" + question?.qid}
                                alt="img-url"
                                onClick={(e) =>
                                  handleOnChange(
                                    (checkBx.current[i].checked =
                                      !checkBx.current[i].checked),
                                    String(question?.qid)
                                  )
                                }
                              />
                            </div>
                          </Col>
                        )}
                        {props?.type == 2 && (
                          <Col xxl={6} lg={6} md={12}>
                            <div className="audioOnyTestStart p-1">
                              <Row>
                                <Col xxl={1} lg={1} md={1}>
                                  <Form.Check
                                    type="checkbox"
                                    id={question?.qid}
                                    value={question?.qid}
                                    ref={(el) => (checkBx.current[i] = el)}
                                    className=""
                                    onChange={(e) =>
                                      handleOnChange(
                                        e.target.checked,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                                <Col xxl={11} lg={11} md={11}>
                                  <div className="border audioOnlyQuestionBankSection"  onClick={(e) =>
                                  handleOnChange(
                                    (checkBx.current[i].checked =
                                      !checkBx.current[i].checked),
                                    String(question?.qid)
                                  )
                                }>
                                    <PlayButtonIcon />
                                    {question?.audio_title}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        )}
                      </>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
