import { Table, Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeletePopupIcon from "../../global-images/DeletePopupIcon";
import { BsCheck2 } from "react-icons/bs";
import ViewIcon from "../../global-images/ViewIcon";
import DeleteIcon from "../../global-images/DeleteIcon";
import EditIcon from "../../global-images/EditIcon";
import { calculateAge } from "../../utils";
import {
  deleteProfileAsync,
  getAllPatientsAsync,
  CLEAR_MSG,
} from "../../features/patient/patientSlice";
const NameLists = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t_name = (state) => state.login.profile;
  const LoggedInUser = useSelector(t_name);

  const p_profiles = (state) => state.patient.patients;
  const patients = useSelector(p_profiles);
  const msg = (state) => state.patient.message;
  const message = useSelector(msg);

  const viewNameFunc = (id) => {
    const viewNamePath = "/viewName/" + id;
    navigate(viewNamePath);
  };

  const nameUpdateFunc = (id) => {
    const nameUpdatePath = "/updateName/" + id;
    navigate(nameUpdatePath);
  };
  const getCurrentPatients = (patients) => {
    return (LoggedInUser?.role == 1) ? patients : patients.filter(item => item.created_by == LoggedInUser?.email);
  }
  const [modalshow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => {
    setModalShow(true);
  };

  const [deletesucessmodalshow, setdeletesucesssetModalShow] = useState(false);
  const deletesucesshandleClose = () => setdeletesucesssetModalShow(false);
  const deletesucesshandleShow = () => setdeletesucesssetModalShow(true);

  const [deleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: "",
  });
  useEffect(() => {
    dispatch(getAllPatientsAsync());
    const timeId = setTimeout(() => {
      setdeletesucesssetModalShow(false);
      dispatch(CLEAR_MSG());
    }, 3000);

    if (deleteConfirm.status) {
      //alert(deleteConfirm.id)
      dispatch(deleteProfileAsync({ patient_id: deleteConfirm.id }));
    }

    return () => {
      clearTimeout(timeId);
      if (message === "delete successful") {
        setdeletesucesssetModalShow(true);
      }
    };
  }, [dispatch, deleteConfirm, message]);
  return (
    <>
      <Modal
        show={modalshow}
        onHide={handleClose}
        className="deleteName text-center"
        centered
      >
        <Modal.Body>
          <DeletePopupIcon />
          <div className="deletePopTextArea">
            <h2>Are you sure?</h2>
            <p>
              Do you really want to delete these records? This process cannot be
              undone.
            </p>
            <Button
              type="button"
              className="btn-name-delete-cancel"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn-name-delete"
              onClick={(e) => {
                //alert(deleteConfirm.id)
                setDeleteConfirm((deleteConfirm) => ({
                  ...deleteConfirm,
                  ...{ status: true },
                }));
                //deletesucesshandleShow();
                handleClose();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* sucess popup */}
      <Modal
        show={deletesucessmodalshow}
        onHide={deletesucesshandleClose}
        className="deleteNameSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="deletePopTextAreaOnSuccess">
            <p>Name has been deleted successfully</p>
          </div>
        </Modal.Body>
      </Modal>

      <div className="ExaminerNameListsTable">
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Name</th>
                  <th>Age</th>
                  <th>Phone</th>
                  <th>Name of Implant</th>
                  <th>Implanted Side</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(props?.searchTextValue ? getCurrentPatients(patients).filter(item => item?.name.toLowerCase().startsWith(props?.searchTextValue)) :getCurrentPatients(patients)).map((patient) => {
                  return (
                    <tr key={patient?.id}>
                      {/* <td>{patient?.id}</td> */}
                      <td>{patient?.name}</td>
                      <td>{calculateAge(patient?.dob)}</td>
                      <td>{patient?.phone}</td>
                      <td>{patient?.name_of_implant}</td>
                      <td>{patient?.implanted_side}</td>
                      <td className="tableAction">
                        <span onClick={(e) => viewNameFunc(patient?.id)}>
                          <ViewIcon />
                        </span>
                        <span onClick={(e) => nameUpdateFunc(patient?.id)}>
                          <EditIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            handleShow();
                            setDeleteConfirm((deleteConfirm) => ({
                              ...{ deleteConfirm },
                              ...{ id: patient?.id },
                            }));
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NameLists;
