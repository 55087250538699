import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import LeftSidebar from "../components/sidebar/LeftSidebar";
import { BsSearch } from "react-icons/bs";
import "../components/users/users.css";
import AddUserButton from "../components/users/AddUserButton";
import UserLists from "../components/users/UserLists";
import RequestLists from "../components/users/RequestsList";
import logo from "../global-images/spetra-logo.svg";
import ToggleMenu from "../global-images/ToggleMenu";
const Users = () => {
  const viewHeight = window.innerHeight;
  console.log(viewHeight);
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const[searchText, setSearchText] = useState("");
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };
  const contentStyle = {
    marginLeft: sidebarOpen ? "250px" : "0px",
    transition: "margin 0.2s ease",
    borderTopLeftRadius: sidebarOpen ? "40px" : "0px",
  };
  return (
    <>
      <div className="spetraLayout">
        <Container fluid>
          <Row>
            <div className="SideBar">
              <LeftSidebar
                isOpen={sidebarOpen}
                toggleSidebar={handleViewSidebar}
              />
            </div>
            <div className="main">
              <div className="mainContentboxshadow" style={contentStyle}>
                <div
                  className="usersBody usersTab"
                  style={{
                    minheight: viewHeight,
                    maxheight: viewHeight,
                  }}
                >
                  <Container fluid>
                    <Row>
                      <Col xxl={3} xl={3} lg={3} md={4}>
                        <span
                          onClick={handleViewSidebar}
                          className="toggleMenu"
                        >
                          <ToggleMenu />
                        </span>

                        {(() => {
                          if (!sidebarOpen) {
                            return (
                              <img
                                src={logo}
                                alt="spetra-logo"
                                className="sidebarOpenLogo"
                              />
                            );
                          } else {
                            return "";
                          }
                        })()}
                      </Col>
                    </Row>
                  </Container>
                  <Tabs defaultActiveKey="users" className="mb-6">
                    <Tab eventKey="users" title="Active Users">
                      <Container fluid>
                        <Row>
                          <Col xxl={3} xl={3} lg={3} md={4}>
                            <AddUserButton />
                          </Col>
                          <Col
                            xxl={6}
                            xl={6}
                            lg={6}
                            md={4}
                            className="text-center"
                          >
                            <h1 className="text-center fw-bold">Active Users</h1>
                          </Col>
                          <Col xxl={3} xl={3} lg={3} md={4}>
                            <InputGroup>
                              <Form.Control placeholder="Search" onChange={(e)=>setSearchText(e.target.value)}></Form.Control>
                              <InputGroup.Text>
                                <BsSearch />
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                          <Col xxl={12} xl={12} lg={12} md={12}>
                            <UserLists searchTextValue={searchText.toLowerCase()}/>
                          </Col>
                        </Row>
                      </Container>
                    </Tab>
                    <Tab eventKey="requests" title="Pending Requests">
                      <Container fluid>
                        <Row>
                          <Col
                            xxl={12}
                            xl={12}
                            lg={12}
                            md={12}
                            className="text-center"
                          >
                            <h1 className="text-center fw-bold"> Pending Requests (Inactive Users)</h1>
                          </Col>
                          {/* <div className="userReportSearch">
                          <Row>
                            <Col xxl={6} xl={6} lg={12} md={12} sm={12}></Col>
                            <Col xxl={3} xl={3} lg={12} md={12} sm={12}>
                              <Form role="form">
                                <Form.Select type="dropdown">
                                  <option>This Month</option>
                                  <option>Last 3 Months</option>
                                  <option>Last Year</option>
                                </Form.Select>
                              </Form>
                            </Col>
                            <Col xxl={3} xl={3} lg={12} md={12} sm={12}>
                              <InputGroup>
                                <Form.Control placeholder="Keyword"></Form.Control>
                                <InputGroup.Text>
                                  <BsSearch />
                                </InputGroup.Text>
                              </InputGroup>
                            </Col>
                          </Row>
                        </div> */}
                          <Col xxl={12} xl={12} lg={12} md={12}>
                            <RequestLists />
                          </Col>
                        </Row>
                      </Container>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Users;
