import { Row, Col, Form, Button, Modal, Image } from "react-bootstrap";
import { useRef, useState } from "react";
import {
  updateQuestionAsync,
  CLEAR_MSG,
} from "../../features/media/mediaSlice";
import { useDispatch, useSelector } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { BsCaretDownSquareFill } from "react-icons/bs";
import { useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import { createConcatChildrenFunction } from "rsuite/esm/Picker";
import { Navigate, useNavigate } from "react-router-dom";


//import {uuidv4} from 'uuidv4'
const UpdateAudioWithImage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const msg = (state) => state.media.message;
  const message = useSelector(msg);

  const qs = (state) => state.media.questions

  const questions = useSelector(qs)
  const quest = questions.filter(q=> parseInt(q.qid) === parseInt(props?.qid))[0]

  const imgPreview = useRef()
  let imgSrc = quest?.image
 
  const [question, setQuestion] = useState({
    qid:"",
    audio: null,
    image: null,
    audio_title: "",
    qtype: "",
  });
  //console.log(question)
  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);
  //const [addFields, setAddFields] = useState();
  // const addNewFields = () => {
  //    const id = fields.length ? fields[fields.length - 1].id + 1 : 1;
  //    const addField = { id };
  //    const addNewField = [...fields, addField];
  //    setFields(addNewField);

  // };

  const [validated, setValidated] = useState(false);

  function addMediaFunc(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    // if (imgPreview.current.src === "undefined") {
    //   return;
    // }
    // alert(1);
    //console.log(question)
    
    var frm = new FormData();
    frm.append("qid", question?.qid)
    frm.append("qtype", question?.qtype);    
  
    if(imgPreview.current.src ===  question.image){
      return;
      //  let imgSrc = question.image
      //  alert(imgSrc)
      //  imgPreview.current.src = imgSrc
      //  question.image = ""
      
    }
    
    frm.append("image", question?.image);  
   // frm.append("audio_title", question?.audio_title);
    //frm.append("audio", question.audioField);
   // console.log(JSON.stringify(frm));
  
    dispatch(updateQuestionAsync(frm));
    dispatch(CLEAR_MSG());
  }
  
  function cancel(){
    navigate("/media")
  }
  
  useEffect(() => {
   

    if (message === "update successful") {
      setaddsucesssetModalShow(true);
      setTimeout(() => {
        setaddsucesssetModalShow(false);
        dispatch(CLEAR_MSG());
        cancel()
      }, 3000);
    }
    if(question.qid === ""){
      setQuestion({
        qid: quest?.qid,
        audio: quest?.audio,
        image: quest?.image,
        audio_title: quest?.audio_title,
        qtype: quest?.qtype,
      })
    
    }
   
    return () => {
    
    };
  }, [dispatch, message, cancel]);

  console.log(question)

  // const deleteBtnHandler = (id) => {
  //   const deleteBtn = fields.filter((field) => field.id !== id);
  //   setFields(deleteBtn);
  // };

  // const fieldHandler = (e) => {
  //   e.preventDefault();
  //   addNewFields(addFields);
  //   setAddFields("");
  // };
  return (
    <>
      <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addNameSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addPopTextAreaOnSuccess">
            <p>Media has been updated sucessfully</p>
          </div>
        </Modal.Body>
      </Modal>

          <Form
            className="addMediaFileForm"
            onSubmit={addMediaFunc}
            noValidate
            validated={validated}
          >
            <Row>
              <Col  xxl={6} xl={6} lg={6} md={6} sm={6} className="m-auto">
                 <Form.Group className="margin-bottom-20 text-center">
                      <Image src={imgSrc} ref={imgPreview}  alt="" style={{width:"250px", height: "250px"}} /> 
                 </Form.Group>
                <Form.Group className="margin-bottom-20">
                  
              
                  <Form.Label>
                    Image<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    required
                  
                    accept="image/*"
                    onChange={(e) =>
                      setQuestion((question) => ({
                        ...question,
                        ...{ image: e.target.files[0] },
                      }))

                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Image is required
                  </Form.Control.Feedback>
                
                </Form.Group>
              
            
                {/* <Form.Group className="margin-bottom-20">
                  <Form.Label>
                    Audio Title<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={question.audio_title}
                    required
                    onChange={(e) =>
                      setQuestion((question) => ({
                        ...question,
                        ...{ audio_title: e.target.value },
                      }))
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Title is required
                  </Form.Control.Feedback>
                </Form.Group>
                */}
             
                <Form.Group className="margin-bottom-20">
                  <Form.Label>
                    Audio<span></span>
                   
                  </Form.Label>
                  <div className="text-center">
                  <ReactAudioPlayer src={question?.audio} controls />
                  </div>
                  {/* <Form.Control
                    type="file"
                    required
                    onChange={(e) =>
                      setQuestion((question) => ({
                        ...question,
                        ...{ audioField: e.target.files[0] },
                      }))
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Audio is required
                  </Form.Control.Feedback> */}
                </Form.Group>
                
                </Col>
              </Row>
              <Row>
              <Col  xxl={12} xl={12} lg={12} md={12} sm={12} >
                <Form.Group className="mt-2 text-center">
                  <Form.Label className="invisible">label</Form.Label>
                  <Button type="submit" className="btn-submit">
                    Update
                  </Button>
                  <span className="p-2"></span>
                  <Button type="button" className="btn-cancel" onClick={cancel}>
                    cancel
                  </Button>
                </Form.Group>

              </Col>
              {/* <Col xxl={1} xl={1} lg={1} md={1}>
                  <Form.Group>
                    <Form.Label className="invisible">label</Form.Label>
                    <Button
                      type="button"
                      className="btn-delete-icon"
                      onClick={() => deleteBtnHandler(field.id)}
                    >
                      <BsXLg />
                    </Button>
                  </Form.Group>
                </Col> */}
              {/* <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
                <Button type="submit" className="btn-submit">
                  Save
                </Button>
              </Col> */}
            </Row>
          </Form>
    
      {/* <Col xxl={12} xl={12} lg={12} md={12} className="text-center">
        <Button type="button" className="btn-add" onClick={fieldHandler}>
          <BsPlusLg />
          Add Field
        </Button>
      </Col> */}
    </>
  );
};

export default UpdateAudioWithImage;
