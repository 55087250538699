import {baseURL}  from "../../constants"

export function getAllPatients() {

  //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
 // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
  const requestOptions = {
          method: 'POST', 
          headers: {'Content-Type': 'application/json'},          
          body:null
      }
   
    return new Promise( (resolve, reject) =>{
        fetch(baseURL + "patient/getall/", requestOptions).then(response => {response.json()
          .then(
           (res) => {                 
              setTimeout(() => resolve({data : res}),500)
       })}
    ).catch((err) => reject({data: err}))})
      
  
}

  /*
   Params
    name:Robin milton
    patient_id:1
    age:24
    gaurdian_name:milton
    address:#18
    phone:9999
    gaurdian_type:friend
    name_of_implant:NA
    date_of_surgery:1997-12-16
    implanted_side:NA
    date_of_switch_on:1997-12-16
    age_of_implantation↵:NA
  
  */
export function addPatient(patientProfile= {}) {

    //myHeaders.append('Cookie', 'jwt=' + sessionStorage.getItem('accessToken'))
   // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
    const requestOptions = {
            method: 'POST', 
            headers: {},          
            body:patientProfile
        }
      console.log(JSON.stringify(requestOptions))
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "patient/register/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }

  /*
   Params
    name:Robin milton
    patient_id:1
    age:24
    gaurdian_name:milton
    address:#18
    phone:9999
    gaurdian_type:friend
    name_of_implant:NA
    date_of_surgery:1997-12-16
    implanted_side:NA
    date_of_switch_on:1997-12-16
    age_of_implantation↵:NA
  
  */
  export function updatePatient(patientProfile= {}) {
    const requestOptions = {
            method: 'POST',
            headers: {},
            body: patientProfile
            
        }
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "patient/update/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }

  export function deletePatient(patientProfile= {}) {
    const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(patientProfile)
            
        }
      return new Promise( (resolve, reject) =>{
          fetch(baseURL + "patient/delete/", requestOptions).then(response => {response.json()
            .then(
             (res) => {                 
                setTimeout(() => resolve({data : res}),500)
         })}
      ).catch((err) => reject({data: err}))})
        
    
  }

//params - patient_id
export function fetchPatient(patientProfile={}) {
    const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body:JSON.stringify(patientProfile)
        } 
      console.log(requestOptions)    
      return new Promise((resolve, reject) =>{
          fetch(baseURL + "patient/profile/",requestOptions).then(response => {
            response.json().then((res) => {
                setTimeout(() => resolve({data : res}),500)
         })}).catch((err) => reject({data: err}))})
  }

