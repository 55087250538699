const HelpIcon = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <path
          d="M39.7,76.7c0-1.6,0-3.2,0-4.8c0.1-0.3,0.1-0.5,0.2-0.8c0.3-1.9,0.5-3.9,1-5.7C45.4,49,60.3,38.5,77.3,39.8
	c10.1,0.8,18.3,5.4,24.5,13.4c4.2,5.4,6.6,11.6,7.1,18.4c0,0.6-0.1,1.1,0.8,1.4c18,5.6,26.1,25.8,17,42.4c-0.2,0.3-0.2,0.8-0.1,1.1
	c0.6,2.3,1.2,4.5,1.9,6.8c0.6,2.2,1.2,4.5,1.9,6.8c-0.3-0.1-0.5-0.1-0.6-0.1c-4.2-1.2-8.4-2.3-12.6-3.5c-0.6-0.2-1.2-0.1-1.8,0.1
	c-2.3,1-4.7,2-7.1,2.8c-1.5,0.5-3.2,0.6-4.8,0.9c-1.5,0-3,0-4.4,0c-0.2-0.1-0.5-0.1-0.7-0.2c-11.6-1.1-21.6-9.2-25.1-20.5
	c-0.2-0.6-0.5-0.9-1.1-0.9c-5.3-0.4-10.2-1.8-14.8-4.4c-0.3-0.2-0.7-0.2-1.1-0.1c-3.2,0.8-6.3,1.7-9.5,2.6c-2.2,0.6-4.5,1.2-6.8,1.9
	c0.1-0.4,0.1-0.7,0.2-1c1.4-4.9,2.7-9.9,4.1-14.8c0.2-0.8,0.2-1.4-0.2-2.1c-1.6-2.8-2.8-5.9-3.4-9.1C40.2,80.2,40,78.4,39.7,76.7z
	 M47.5,101.1c0.3-0.1,0.4-0.1,0.6-0.1c2.8-0.8,5.6-1.5,8.4-2.3c0.7-0.2,1.3-0.1,2,0.3c6.7,4.2,14,5.6,21.7,4.1
	c15.7-3.2,25.8-18.2,23-34c-3.1-17.2-20.9-28-37.6-22.7c-17.9,5.7-26,26.3-16.6,42.5c1,1.7,1.2,3.1,0.6,4.9
	C48.7,96.1,48.2,98.6,47.5,101.1z M122.7,122.6c-0.6-2.2-1.1-4.2-1.7-6.2c-0.3-0.9-0.2-1.6,0.4-2.4c7.3-11.5,3.7-26.6-8-33.6
	c-1.4-0.8-2.9-1.5-4.4-2.2c-3.3,17.3-13.4,27.4-30.5,30.6c1.4,3.9,3.6,7.3,6.7,10.1c8.2,7.3,19.8,8.1,29,2.2
	c0.7-0.5,1.4-0.6,2.2-0.3C118.4,121.5,120.4,122,122.7,122.6z M77,82.3c-1.8,0-3.5,0-5.3,0c0-2.1,0-4.1,0-6.1c0-0.3,0.3-0.6,0.6-0.8
	c1.9-1.8,3.9-3.5,5.8-5.4c1.6-1.6,2-3.9,1.1-6c-0.9-2-2.9-3.2-5.1-3.1c-2.5,0.1-4.6,2.1-5,4.5c0,0.2-0.1,0.5-0.1,0.7
	c-1.8,0-3.5,0-5.3,0c0.1-3.8,1.7-6.8,4.8-8.8c4.6-3,10.7-2,14.1,2.2c3.5,4.4,3.1,10.5-1,14.4c-1.3,1.3-2.8,2.5-4.1,3.8
	C77.2,78,77,78.5,77,79C76.9,80.1,77,81.2,77,82.3z M71.7,87.7c1.8,0,3.5,0,5.2,0c0,1.8,0,3.5,0,5.2c-1.8,0-3.5,0-5.2,0
	C71.7,91.2,71.7,89.5,71.7,87.7z"
        />
      </svg>
    </>
  );
};

export default HelpIcon;
