import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Image,
  Form,
  Stack,
} from "react-bootstrap";
import { BsCheck2 } from "react-icons/bs";
//import LeftSidebar from "../../components/sidebar/LeftSidebar";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlayButtonIcon from "../tests/PlayButtonIcon";
import {
  RESET_STATUS,
  testResponseAsync,
  beginTestAsync,
  testSubmitAsync,
  CLEAR_TEST_MSG,
} from "../../features/tests/testSlice";
import { s3URL } from "../../constants";
import ReactAudioPlayer from "react-audio-player";
import logo from "../../global-images/spetra-logo.svg";
import { useParams } from "react-router-dom";
import { shuffle } from "../../utils";

const AudioOnlytrailTests = () => {
  const testsBackBtn = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const t_name = (state) => state.login.profile?.name;
  const examiner = useSelector(t_name);

  const t_q = (state) => state.test.questions;
  const testQuestions = useSelector(t_q);
  const t_s = (state) => state.test.sessionId;
  const sessionId = useSelector(t_s);
  const p_patients = (state) => state.patient.patients;
  const patients = useSelector(p_patients);
  const t_details = (state) => state.test.currentDetails;
  const currentDetails = useSelector(t_details);
  const t_message = (state) => state.test.message;
  const message = useSelector(t_message);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAudio, setCurrentAudio] = useState("");
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentOptions, setCurrentOptions] = useState({
    qid: "",
    options: [],
    level_name: "",
  });

  const [qidResponse, setQidResponse] = useState("");
  const [shuffleArray, setShuffleArray] = useState([]);
  const [exitTest, setExitTest] = useState(false);

  // test submit popup
  const [testsubmitmodal, setTestSubmitModal] = useState(false);
  const testsubmithandleClose = () => setTestSubmitModal(false);
  // const testsubmithandleShow = () => setTestSubmitModal(true);
  const testsubmithandleShow = (e) => {
    e.preventDefault();
    // setQidResponse("");
    //last question answer submission on each level
    const resp = {
      session_id: sessionId,
      qid: qidResponse,
      qid_response: testAnswer[qidResponse] == "1" ? qidResponse : qidResponse + 1,
      level: currentLevel,
      comment: testComments[qidResponse],
    };
    if (qidResponse) {
      dispatch(testResponseAsync(resp));
    }
    setTestSubmitModal(true);
  };

  const [submitsucessmodalshow, setsubmitsucesssetModalShow] = useState(false);
  const submitsucesshandleClose = () => setsubmitsucesssetModalShow(false);
  const [submitConfirm, setSubmitConfirm] = useState(false);

  const testsBackBtnFunc = () => {
    dispatch(RESET_STATUS());
    const testsBackBtnPath = "/tests";
    testsBackBtn(testsBackBtnPath);
  };

  //test variables
  const [testComments, setTestComments] = useState([]);
  const [testAnswer, setTestAnswer] = useState([]);
  const [testAnswerSubmitted, setTestAnswerSubmitted] = useState([]);
  const [testAnswerError, setTestAnswerError] = useState([]);

  const PrepareLevels = () => {
    if (currentLevel === 1) {
      return testQuestions?.level1;
    } else if (currentLevel === 2) {
      return testQuestions?.level1;
    }
  };

  const fetchQuestions = useCallback(() => {
    try {
      if (
        testQuestions === "" ||
        testQuestions === "undefined" ||
        testQuestions === null
      )
        return;
      console.log("index: ", currentIndex);
      const q = testQuestions?.level1?.quesions.slice(0, 5);
      let opts = [];
      if (q === "undefined") return;
      Array.from(q).forEach((e) => {
        opts.push({
          id: e?.qid,
          image: s3URL + e?.image,
          audio: s3URL + e?.audio,
        });
      });
      // opts = shuffle(opts);

      if (currentIndex > opts.length) {
        setCurrentIndex(opts.length);
        return;
      }
      const qid = q[currentIndex].qid;
      const audio = s3URL + q[currentIndex].audio;

      const co = {
        qid: qid,
        options: opts,
        level_name: testQuestions?.level1?.level_name,
      };
      setCurrentOptions(co);
      setCurrentIndex(currentIndex);
      setCurrentAudio(audio.toString());
    } catch (ex) {
      console.log(ex);
    }
  }, [testQuestions, currentIndex]);

  function setResponsevalue(e, rq) {
    e.preventDefault();
    //remove, non submited values
    if (qidResponse) {
      setTestAnswerError(
        (error) => ({
          [qidResponse]: false,
        })
      );
      setTestAnswer((ans) => ({
        ...ans,
        [qidResponse]: ""
      }));
      setTestComments((cms) => ({
        ...cms,
        [qidResponse]: ""
      }));
    }
    setQidResponse(rq.id);
  };
  function handleNext(e) {
    e.preventDefault();
    if (!testAnswer[qidResponse]) {
      setTestAnswerError(
        (error) => ({
          [qidResponse]: true,
        })
      )
      return;
    }
    setTestAnswerError(
      (error) => ({
        [qidResponse]: false,
      })
    )
    setTestAnswerSubmitted((ans) => ({
        ...ans,
        [qidResponse]: true,
      }));
    const resp = {
      session_id: sessionId,
      qid: qidResponse,
      qid_response: testAnswer[qidResponse] == "1" ? qidResponse : qidResponse + 1,
      level: currentLevel,
      comment: testComments[qidResponse],
    };
    if (qidResponse) {
      dispatch(testResponseAsync(resp));
    }
  }
  const getCurrentOption = (options, currentIndex) => {
    if (!options || options?.length == 0) {
      return [];
    }
    //  console.log("options",options)
    let res1 = options.slice(currentIndex, currentIndex + 1);
    // console.log("currentIndex",currentIndex)
    // console.log("res",res1)
    let res2 = [...options].filter((item) => item.id != res1[0]?.id);
    let res3 = [...res1, ...shuffle(res2).slice(0, 4)];
    // let res4 = [...res3];
    // console.log("res3",res3)
    // console.log("shuffle(res4)",res4)
    return shuffle(res3);
  };
  useEffect(() => {
    if (params?.testID == 199) {
      setCurrentLevel(1);
    }
    // console.log(props?.testName)
    fetchQuestions();

    const SArray = getCurrentOption(currentOptions?.options, currentIndex);
    setShuffleArray(SArray);

    // console.log("testQuestions", testQuestions)
    // console.log("message", message)
    if (message?.message === "Test submitted successfully") {
      setsubmitsucesssetModalShow(true);
    }
    if (message?.message === "Response submitted successfully") {
      // setTestAnswer((ans) => ({
      //   ...ans,
      //   [qidResponse]: ""
      // }));
      // setTestComments((cms) => ({
      //   ...cms,
      //   [qidResponse]: ""
      // }));
      setQidResponse("");
    }
    const timeId = setTimeout(() => {
      if (
        message?.message === "Test submitted successfully" ||
        submitsucessmodalshow
      ) {
        setsubmitsucesssetModalShow(false);
        dispatch(CLEAR_TEST_MSG());
        testsBackBtnFunc();
      }
    }, 3000);

    if (submitConfirm) {
      // dispatch(testSubmitAsync({ session_id: sessionId }));
      setsubmitsucesssetModalShow(true);
      setSubmitConfirm(false);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [
    dispatch,
    fetchQuestions,
    currentDetails,
    submitConfirm,
    message,
    patients,
    currentLevel,
    params,
    currentIndex,
  ]);

  return (
    <>
      {/* submit popup */}
      <Modal
        show={testsubmitmodal}
        onHide={testsubmithandleClose}
        className="submitTest text-center"
        centered
      >
        <Modal.Body>
          <div className="submitPopUpTest">
            <h2>Are you sure?</h2>
            <p>
              Do you really want to {exitTest ? "exit" : "submit"} the{" "}
              {currentDetails?.testName} Trail Test?
            </p>
            <Button
              type="button"
              className="btn-cancel"
              onClick={testsubmithandleClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn-submit"
              onClick={() => {
                setSubmitConfirm(true);
                testsubmithandleClose();
              }}
            >
              {exitTest ? "Exit" : "Submit"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={submitsucessmodalshow}
        onHide={submitsucesshandleClose}
        className="submitTestSucess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="submitPopTextAreaOnSuccess">
            <p>Your Trial test is done!  Results will not be saved.</p>
          </div>
        </Modal.Body>
      </Modal>

      <div className="spetraLayout writeTestScreen">
        <Container fluid>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="mainContentboxshadow"
            >
              <div className="ContentMainBody TestsPage">
                <Container fluid>
                  <Row className="testsTopArea">
                    <Col
                      xxl={3}
                      xl={3}
                      lg={3}
                      md={4}
                      className="testsPatientName"
                    >
                      <img
                        src={logo}
                        alt="spetra-logo"
                        className="sidebarOpenLogo"
                      />
                      <span>
                        <h3>Name</h3>
                        <h2>
                          {
                            patients.filter(
                              (item) => item.id == currentDetails?.patient_id
                            )[0]?.name
                          }
                        </h2>
                      </span>
                    </Col>
                    <Col
                      xxl={6}
                      xl={6}
                      lg={6}
                      md={5}
                      className="text-center onGoingTestName"
                    >
                      <h3>{currentDetails?.testName} (Trail)</h3>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} className="text-end">
                      <Button
                        type="button"
                        className="btn-back text-right"
                        onClick={(e) => {
                          setExitTest(true);
                          testsubmithandleShow(e);
                        }}
                      >
                        <BsArrowLeft />
                        Exit
                      </Button>
                    </Col>
                  </Row>
                  <div className="SpeechTrailTest">
                    <Row>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                        {currentOptions?.options?.map((rq, index) => {
                          return (
                            <>
                              <Form className="mb-2 audioOnlyTestAlign">
                                <Row>
                                  <Col
                                    xxl={3}
                                    lg={4}
                                    md={4}
                                    className="speechTestPlayBtn"
                                  >
                                    <span>
                                      {index + 1 < 10
                                        ? "0" + (index + 1)
                                        : index + 1}
                                    </span>
                                    <span
                                      key={index}
                                    >
                                      <ReactAudioPlayer
                                        src={rq?.audio}
                                        controls
                                        c
                                        controlsList="nodownload"
                                        data-id="custom-data"
                                        onPlay={(e) => setResponsevalue(e, rq)}
                                      />
                                      {/* <PlayButtonIcon /> */}
                                    </span>
                                  </Col>
                                  <Col xxl={4} lg={3} md={3}>
                                    <Form.Group>
                                      <Form.Select
                                      className={(testAnswerError[rq.id] ? "audio-test-invalid-select-error" : "")}
                                       disabled={rq.id != qidResponse}
                                        value={testAnswer[rq.id] ? testAnswer[rq.id] : ""}
                                        onChange={(e) => {
                                          setTestAnswer((ans) => ({
                                            ...ans,
                                            [rq.id]: e.target.value
                                          })
                                          );
                                          setTestAnswerError(
                                            (error) => ({
                                              [rq.id]: false,
                                            })
                                          );
                                        }
                                         } >
                                        <option value="" disabled defaultValue>None</option>
                                        <option value={1}>Correct</option>
                                        <option value={0}>Wrong</option>
                                        {/* <option value={3}>Partially Correct</option> */}
                                      </Form.Select>
                                      {testAnswerError[rq.id] &&
                                          <div className="audio-test-invalid-select">
                                            Please Select answer
                                          </div>
                                        }
                                    </Form.Group>
                                  </Col>
                                  <Col xxl={4} lg={4} md={4}>
                                    <Form.Group>
                                      <Form.Control
                                        type="text"
                                        placeholder="Comments"
                                        disabled={rq.id != qidResponse}
                                        value={testComments[rq.id] ? testComments[rq.id] : ""}
                                        onChange={(e) =>
                                          setTestComments((ans) => ({
                                            ...ans,
                                            [rq.id]: e.target.value
                                          })
                                          )}
                                      ></Form.Control>
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    xxl={1}
                                    lg={1}
                                    md={1}
                                    className="padding-left-0"
                                  >
                                    <Button
                                      type="button"
                                      disabled={rq.id != qidResponse}
                                      className={"btn-submit-tests " +(testAnswerSubmitted[rq.id]? "submitted":"")}
                                      onClick={(e) => handleNext(e)}
                                    >
                                      Save
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            </>
                          );
                        })}
                      </Col>
                    </Row>
                  </div>

                  <div className="speechTestQuestionsSubmit">
                    <Row>
                      <Col xxl={12} lg={12} md={12} className="text-center">
                        {/* <Button
                          type="button"
                          className="btn-submit"
                        >
                          Next
                        </Button> */}
                        <Button
                          type="button"
                          className="btn-submit-tests"
                          onClick={(e) => testsubmithandleShow(e)}
                        >
                          Submit Test
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AudioOnlytrailTests;
