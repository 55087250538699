import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
const UserBackButton = () => {
  const userBackBtn = useNavigate();
  const userBackBtnFunc = () => {
    const userBackBtnPath = "/users";
    userBackBtn(userBackBtnPath);
  };
  return (
    <>
      <Button type="button" className="btn-back" onClick={userBackBtnFunc}>
        <BsArrowLeft />
        Back
      </Button>
    </>
  );
};

export default UserBackButton;
