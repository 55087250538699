import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./features/login/loginSlice";
import registerReducer from "./features/login/registerSlice";
import patientReducer from "./features/patient/patientSlice";
import mediaReducer from "./features/media/mediaSlice";
import testReducer from "./features/tests/testSlice";
import termsReducer from "./features/terms/termsSlice";
import registrationCountSlice from "./features/dashboard/RegistrationCountSlice";
import RegistrationApprovedCountSlice from "./features/dashboard/RegistrationApprovedCountSlice";
import RegistrationPendingCountSlice from "./features/dashboard/RegistrationPendingCountSlice";
import TestMasterCountSlice from "./features/dashboard/TestMasterCountSlice";
import RecentlyAddedUserSlice from  "./features/dashboard/RecentlyAddedUserSlice"
import RecentlyActiveUserSlice from "./features/dashboard/RecentlyActiveUserSlice";
import PatientAgeGraphSlice  from "./features/dashboard/PatientAgeGraphSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    register: registerReducer,
    patient: patientReducer,
    media: mediaReducer,
    test: testReducer,
    terms: termsReducer,
    registration: registrationCountSlice,
    registrationApproved: RegistrationApprovedCountSlice,
    registrationPending: RegistrationPendingCountSlice,
    testMasterCount: TestMasterCountSlice,
    recentlyAddedUser: RecentlyAddedUserSlice,
    recentlyActiveUser: RecentlyActiveUserSlice,
    patientAgeGraph:PatientAgeGraphSlice,

  },
});

