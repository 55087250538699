import { useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import LeftSidebar from "../components/sidebar/LeftSidebar";
import logo from "../global-images/spetra-logo.svg";
import ToggleMenu from "../global-images/ToggleMenu";
const Help = () => {
  const viewHeight = window.innerHeight;
  console.log(viewHeight);
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };
  const contentStyle = {
    marginLeft: sidebarOpen ? "250px" : "0px",
    transition: "margin 0.2s ease",
    borderTopLeftRadius: sidebarOpen ? "40px" : "0px",
  };
  return (
    <>
      <div className="spetraLayout">
        <Container fluid>
          <Row>
            <div className="SideBar">
              <LeftSidebar
                isOpen={sidebarOpen}
                toggleSidebar={handleViewSidebar}
              />
            </div>
            <div className="name">
              <div className="mainContentboxshadow" style={contentStyle}>
                <div
                  className="ContentMainBody spetraHelp"
                  style={{
                    minheight: viewHeight,
                    maxheight: viewHeight,
                  }}
                >
                  <Container fluid>
                    <Row>
                      <Col xxl={3} xl={3} lg={3} md={4}>
                        <span
                          onClick={handleViewSidebar}
                          className="toggleMenu"
                        >
                          <ToggleMenu />
                        </span>

                        {(() => {
                          if (!sidebarOpen) {
                            return (
                              <img
                                src={logo}
                                alt="spetra-logo"
                                className="sidebarOpenLogo"
                              />
                            );
                          } else {
                            return "";
                          }
                        })()}
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={4} className="text-center">
                        <h1>Help</h1>
                      </Col>
                      <Col xxl={3} xl={3} lg={3} md={4}></Col>
                      <Col xxl={12} xl={12} lg={12} md={12}>
                        <div className="userManual">
                          <h3>Spetra (User Manual)</h3>
                          <p>
                            Perform a speech perception test on your tab/laptop.
                          </p>
                        </div>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12}>
                        <div className="HelpAccordionArea">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                The App provides 3 speech perception tests
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  Early speech perception test (ESPT) and
                                  picture identification test (PIT) and sentence
                                  perception test. The tests determine the
                                  speech perception ability of children.
                                </p>
                                <p>
                                  Login into the app by entering the username
                                  and password. Once login, click the dashboard,
                                  enter the participants data and save the
                                  details. select the test page, where it shows
                                  3 tests on the screen. select the appropriate
                                  test.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                              <Accordion.Header>Test setup</Accordion.Header>
                              <Accordion.Body>
                                Use a commercially available speaker (ensure the
                                speaker connected with the tab or laptop) and
                                place speaker in front of the child at one meter
                                distance. Each child is made to sit in a
                                quiet/sound treated room 0&deg; azimuth.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                How To Perform Espt?
                              </Accordion.Header>
                              <Accordion.Body>
                                When ESPT test is selected, 3 subtests will be
                                displayed. Each subtest shows 12 pictures in a
                                template. The child will be instructed to select
                                the appropriate picture, when they hear the
                                words. Scores will be calculated automatically
                                for each subtest and will be displayed on the
                                results section of the app.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                How To Perform PIT?
                              </Accordion.Header>
                              <Accordion.Body>
                                Each template shows 4 pictures, one picture is
                                correct out of 4. The child will be instructed
                                to select the appropriate picture out of the 4,
                                when they hear the words. Scores will be
                                calculated automatically for each correct
                                response and will be displayed on the results
                                section of the app.
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                How To Perform Sentence Perception Test?
                              </Accordion.Header>
                              <Accordion.Body>
                                Sentences will be presented and correct answers
                                will be marked as correct or incorrect. Scores
                                will be calculated automatically for each
                                correct response and will be displayed on the
                                results section of the app.
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Help;
