import { useState } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Pagination,
} from "react-bootstrap";
import LeftSidebar from "../components/sidebar/LeftSidebar";
import ResultsLists from "../components/results/ResultsList";
import "../components/results/results.css";
import { BsSearch } from "react-icons/bs";
import logo from "../global-images/spetra-logo.svg";
import ToggleMenu from "../global-images/ToggleMenu";
const Results = () => {
  const viewHeight = window.innerHeight;
  console.log(viewHeight);
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const[searchText, setSearchText] = useState("");
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };
  const contentStyle = {
    marginLeft: sidebarOpen ? "250px" : "0px",
    transition: "margin 0.2s ease",
    borderTopLeftRadius: sidebarOpen ? "40px" : "0px",
  };
  return (
    <>
      <div className="spetraLayout">
        <Container fluid>
          <Row>
            <div className="SideBar">
              <LeftSidebar
                isOpen={sidebarOpen}
                toggleSidebar={handleViewSidebar}
              />
            </div>
            <div className="main">
              <div className="mainContentboxshadow" style={contentStyle}>
                <div
                  className="ContentMainBody"
                  style={{
                    minheight: viewHeight,
                    maxheight: viewHeight,
                  }}
                >
                  <Container fluid>
                    <Row>
                      <Col xxl={3} xl={3} lg={3} md={12}>
                        <span
                          onClick={handleViewSidebar}
                          className="toggleMenu"
                        >
                          <ToggleMenu />
                        </span>

                        {(() => {
                          if (!sidebarOpen) {
                            return (
                              <img
                                src={logo}
                                alt="spetra-logo"
                                className="sidebarOpenLogo"
                              />
                            );
                          } else {
                            return "";
                          }
                        })()}
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} className="text-center">
                        <h1 className="text-center">Results</h1>
                      </Col>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={3}
                        md={6}
                        className="header-page-search"
                      >
                        <InputGroup>
                          <Form.Control placeholder="Search by Name,Test" onChange={(e)=>setSearchText(e.target.value)}></Form.Control>
                          <InputGroup.Text>
                            <BsSearch />
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Row>
                    <div className="resultsTableList">
                      <ResultsLists searchTextValue={searchText.toLowerCase()}/>
                    </div>
                    {/* <Row className="mt-4">
                    <Col xxl={3} xl={3} lg={3} md={12}></Col>
                    <Col xxl={6} xl={6} lg={6} md={12}>
                      <Pagination className="justify-content-center">
                        <Pagination.Prev></Pagination.Prev>
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Next></Pagination.Next>
                      </Pagination>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={12} className="text-center">
                      <p className="fw-bold">Page 1/5</p>
                    </Col>
                  </Row> */}
                  </Container>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Results;
