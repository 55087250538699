const TestIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
      <path
        id="Path_24_1_"
        d="M105.8,56L90,40.2c-0.3-0.3-0.8-0.5-1.3-0.5H41.5c-1,0-1.8,0.8-1.8,1.8l0,0v87c0,1,0.8,1.8,1.8,1.8h0h62.9
	c1,0,1.8-0.8,1.8-1.8l0,0V57.3l0,0C106.3,56.8,106.1,56.3,105.8,56z M90.6,46l9.4,9.4h-9.4V46z M102.6,126.7H43.4V43.4h43.5v13.9
	c0,1,0.8,1.8,1.8,1.8l0,0h13.9L102.6,126.7z M126.7,83.2v28.7h-2.8v-50l0,0c0-0.2,0-0.5-0.1-0.7l-4.6-11.1c-0.4-0.9-1.5-1.4-2.4-1
	c-0.5,0.2-0.8,0.5-1,1l-4.6,11.1c-0.1,0.2-0.1,0.5-0.1,0.7l0,0v66.6c0,1,0.8,1.8,1.8,1.8h9.3c1,0,1.8-0.8,1.8-1.8v-13h4.6
	c1,0,1.8-0.8,1.8-1.8V83.2H126.7z M117.4,55.6l1.8,4.4h-3.7L117.4,55.6z M120.2,126.7h-5.6v-11.1h5.6V126.7z M120.2,111.9h-5.6V63.7
	h5.6V111.9z M62.8,64.7h-9.3c-1,0-1.8,0.8-1.8,1.8v9.3c0,1,0.8,1.8,1.8,1.8h9.3c1,0,1.8-0.8,1.8-1.8v-9.3
	C64.7,65.5,63.8,64.7,62.8,64.7z M61,73.9h-5.6v-5.6H61V73.9z M62.8,85h-9.3c-1,0-1.8,0.8-1.8,1.8v9.3c0,1,0.8,1.8,1.8,1.8h9.3
	c1,0,1.8-0.8,1.8-1.8v-9.3C64.7,85.9,63.8,85,62.8,85z M61,94.3h-5.6v-5.6H61V94.3z M62.8,105.4h-9.3c-1,0-1.8,0.8-1.8,1.8v9.3
	c0,1,0.8,1.8,1.8,1.8h9.3c1,0,1.8-0.8,1.8-1.8v-9.3C64.7,106.2,63.8,105.4,62.8,105.4z M61,114.7h-5.6v-5.6H61V114.7z M67.9,65.9H76
	v4h-8.1V65.9z M67.9,74h24.2v4H67.9V74z M67.9,86H76v4h-8.1V86z M67.9,94.1h24.2v4H67.9V94.1z M67.9,106.2H76v4h-8.1V106.2z
	 M67.9,114.3h24.2v4H67.9V114.3z"
      />
    </svg>
  );
};

export default TestIcon;
