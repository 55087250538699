import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
const TestBackButton = () => {
  const testBtnBack = useNavigate();
  const testBtnBackFunc = () => {
    const testBtnUrl = "/test";
    testBtnBack(testBtnUrl);
  };
  return (
    <>
      <Button type="button" className="btn-back" onClick={testBtnBackFunc}>
        <BsArrowLeft />
        Back
      </Button>
    </>
  );
};

export default TestBackButton;
