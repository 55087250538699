import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { createPath, useNavigate } from "react-router-dom";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { userRole } from "../../constants";
import {
  registerAsync,
  CLEAR_USR_MSG,
} from "../../features/login/registerSlice";
import profilePic from "../../global-images/profilePic.svg";

const AddUserForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const msg = (state) => state.register.message;
  const message = useSelector(msg);

  const userCancelBtnFunc = () => {
    const userCancelBtnPath = "/users";
    navigate(userCancelBtnPath);
  };

  const [addsucessmodalshow, setaddsucesssetModalShow] = useState(false);
  const addsucesshandleClose = () => setaddsucesssetModalShow(false);
  // const addsucesshandleShow = () => setaddsucesssetModalShow(true);

  const [userProfile, setUserProfile] = useState({
    name: "",
    email: "",
    gender: null,
    phone: "",
    password: "",
    address: null,
    role: 2,
    dp: "",
    comments: null,
    status: 1,
  });

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);

  function addUserFunc(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (
      userProfile.name == "" ||
      userProfile.email == "" ||
      userProfile.phone == "" ||
      userProfile.phone.length != 10 ||
      userProfile.gender == "" ||
      userProfile.password == ""
    ) {
      return;
    }
    console.log(userProfile);
    var frm = new FormData();
    frm.append("name", userProfile.name);
    frm.append("email", userProfile.email);
    frm.append("gender", userProfile.gender);
    frm.append("phone", userProfile.phone);
    frm.append("password", userProfile.password);
    frm.append("address", userProfile.address);
    frm.append("role", parseInt(userProfile.role));
    frm.append("dp", userProfile.dp);
    frm.append("comments", userProfile.comments);
    frm.append("status", userProfile.status);

    //console.log(JSON.stringify(frm));

    //userProfile.role = parseInt(userProfile.role);
    dispatch(registerAsync(frm));
    dispatch(CLEAR_USR_MSG());
  }

  useEffect(() => {
    console.log(message);
    //dispatch(CLEAR_USR_MSG());
    //setErrMsg("")
    const timeId = setTimeout(() => {
      if (message === "Registeration_Successful") {
        setaddsucesssetModalShow(false);
        dispatch(CLEAR_USR_MSG());
        userCancelBtnFunc();
      }
    }, 2000);

    if (message === "Registeration_Successful") {
      setaddsucesssetModalShow(true);
      setShow(false);
    } else if (message === "Registeration_Failed") {
      setShow(true);
    }

    return () => {
      clearTimeout(timeId);
      //setShow(false)
      //dispatch(CLEAR_USR_MSG());
    };
  }, [dispatch, message, addsucessmodalshow]);

  //form validation
  return (
    <>
      <Modal
        show={addsucessmodalshow}
        onHide={addsucesshandleClose}
        className="addUserSuccess text-center"
        centered
      >
        <Modal.Body>
          <BsCheck2 />
          <div className="addUserPopTextAreaOnSuccess">
            <p>User has been added successfully</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="text-center p-2">
        <Alert show={show} variant="danger" className="text-center">
          <p className="mt-2">Registration Failed! Email Already exists</p>
        </Alert>
      </div>
      <div className="addUserFormField">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={3}>
            <img src={profilePic} className="img-fluid" alt="profile-pic" />
            <Form.Group>
              <Form.Label className="text-center d-block fw-bold">
                User Profile Picture
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) =>
                  setUserProfile((userProfile) => ({
                    ...userProfile,
                    ...{ dp: e.target.files[0] },
                  }))
                }
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xxl={9} xl={9} lg={9} md={9}>
            <Form
              role="form"
              onSubmit={(e) => addUserFunc(e)}
              noValidate
              validated={validated}
            >
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Name<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ name: e.target.value },
                        }))
                      }
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Name is required
                    </Form.Control.Feedback>
                    <div id="userNameerrorMessage"></div>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Role<span>*</span>
                    </Form.Label>
                    <Form.Select
                      type="text"
                      value={userProfile?.role}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ role: e.target.value },
                        }))
                      }
                    >
                      {userRole.map((el, index) => {
                        return <option value={index}>{el}</option>;
                      })}
                      {/* <option value={"0"}>null</option>
                  <option value={"1"}>Super Admin</option>
                  <option value={"2"}>Admin</option>
                  <option value={"3"}>Support</option>
                  <option value={"4"}>Audiologist</option> */}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Email ID<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      value={userProfile.email}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ email: e.target.value },
                        }))
                      }
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Email is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Phone<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      value={userProfile?.phone}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ phone: e.target.value.replace(/[^0-9.]/g, "") },
                        }))
                      }
                      required
                      pattern="^[123456789]\d{9,9}$"
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Enter the Valid 10 digit Mobile Number
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Gender<span>*</span>
                    </Form.Label>
                    <Form.Select
                      type="dropdown"
                      required
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ gender: e.target.value },
                        }))
                      }
                    >
                      <option></option>
                      <option value={"M"}>Male</option>
                      <option value={"F"}>Female</option>
                      <option value={"O"}>Others</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Gender is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>
                      Password<span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ password: e.target.value },
                        }))
                      }
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Password is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* <Col xxl={6} xl={6} lg={6} md={6}>
                <Form.Group className="margin-bottom-20">
                  <Form.Label>User Profile Picture</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      setUserProfile((userProfile) => ({
                        ...userProfile,
                        ...{ dp: e.target.files[0] },
                      }))
                    }
                  ></Form.Control>
                </Form.Group>
              </Col> */}
                <Col xxl={12} xl={12} lg={12} md={12}>
                  <Form.Group className="margin-bottom-20">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      onChange={(e) =>
                        setUserProfile((userProfile) => ({
                          ...userProfile,
                          ...{ address: e.target.value },
                        }))
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} className="text-end">
                  <Button
                    type="button"
                    className="btn-cancel"
                    onClick={userCancelBtnFunc}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6}>
                  <Button type="submit" className="btn-submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddUserForm;
