import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addPatient,
  updatePatient,
  deletePatient,
  fetchPatient,
  getAllPatients,
} from "./patientAPI";

const patientInitState = {
  profile: {},
  patients: [],
  currentPatient: {},
  status: false,
  message: "",
};

export const addProfileAsync = createAsyncThunk(
  "patient/addPatient",
  async (patientProfile) => {
    const response = await addPatient(patientProfile);
    return response.data;
  }
);

export const getAllPatientsAsync = createAsyncThunk(
  "patient/getAllPatients",
  async () => {
    console.log("Fetch All Patients Profiles");
    const response = await getAllPatients();
    return response.data;
  }
);

export const getPatientAsync = createAsyncThunk(
  "patient/getPatient",
  async (patientProfile) => {
    console.log("Fetch Patient");
    const response = await fetchPatient(patientProfile);
    return response.data;
  }
);
export const updateProfileAsync = createAsyncThunk(
  "patient/updatePatient",
  async (patientProfile) => {
    const response = await updatePatient(patientProfile);
    return response.data;
  }
);
export const deleteProfileAsync = createAsyncThunk(
  "patient/deletePatient",
  async (patientProfile) => {
    const response = await deletePatient(patientProfile);
    return response.data;
  }
);

const patientSlice = createSlice({
  name: "patient",
  initialState: patientInitState,
  reducers: {
    FETCH_PATIENT: (state) => {
      return { ...state.patient.profile };
    },
    CLEAR_MSG: (state) => {
      state.message = "";
    },
  },
  extraReducers: {
    [addProfileAsync.fulfilled]: (state, action) => {
      let data = action.payload;
      state.message = data;
      getAllPatientsAsync();
    },
    [deleteProfileAsync.fulfilled]: (state, action) => {
      let data = action.payload;
      state.message = data?.message;
    },
    [getAllPatientsAsync.fulfilled]: (state, action) => {
      let data = action.payload;
      console.log("Patients: ", data);
      state.patients = data;
    },
    [getPatientAsync.fulfilled]: (state, action) => {
      let data = action.payload;
      console.log("Patient: ", data);
      state.currentPatient = data?.profile;
    },
    [updateProfileAsync.fulfilled]: (state, action) => {
      let data = action.payload;
      state.message = data?.message;
      console.log("Patient Updated: ", data);
    },
    [updateProfileAsync.rejected]: (state, action) => {
      let data = action.payload;
      state.message = data?.message;
      console.log("Patient Update failed: ", data);
    },
  },
});

export const { FETCH_PATIENT, CLEAR_MSG } = patientSlice.actions;
export default patientSlice.reducer;
