import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BsConeStriped } from "react-icons/bs";
import { setTranslate3d } from "rsuite/esm/List/helper/utils";
import {
  activateUser,
  deleteUser,
  getAllUsers,
  getUser,
  loginUser,
  updateUser,
} from "./loginAPI";

const initialState = {
  profile: {},
  users: [],
  role: 0,
  token: "",
  status: false,
  message: "",
  id:""
};

export const loginAsync = createAsyncThunk(
  "login/loginUser",
  async (credentials) => {
    const response = await loginUser(credentials);
    // The value we return becomes the `fulfilled` action payload
    console.log("here::");
    console.log(JSON.stringify(response.data));
    return response.data;
  }
);
export const getAllUsersAsync = createAsyncThunk(
  "login/getAllUsers",
  async () => {
    const response = await getAllUsers();
    return response.data;
  }
);
export const getUserAsync = createAsyncThunk(
  "login/getUser",
  async (userProfile) => {
    const response = await getUser(userProfile);
    return response.data;
  }
);

export const updateUserAsync = createAsyncThunk(
  "login/updateUser",
  async (userProfile) => {
    const response = await updateUser(userProfile);
    return response.data;
  }
);

export const deleteUserAsync = createAsyncThunk(
  "login/deleteUser",
  async (userProfile) => {
    const response = await deleteUser(userProfile);
    return response.data;
  }
);

export const activateUserAsync = createAsyncThunk(
  "login/activate",
  async (userProfile) => {
    const response = await activateUser(userProfile);
    return response.data;
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    LOG_OUT: (state) => {
      console.log("log out action");
      sessionStorage.removeItem("accessToken");
      state.status = false;
    },
    CLEAR_USR_MSG: (state) => {
      state.message = "";
    }
  },
  extraReducers: {
    [loginAsync.pending]: (state) => {
      state.status = false;
    },
    [loginAsync.fulfilled]: (state, action) => {
      console.log("fetch user fulfilled");
      const data = action.payload;
      //state.profile = {id : data?.id, userName: data?.username, email: data?.email }
      //state.role = data?.roles[0]
      state.token = data?.token;
      console.log("token", state.token);
      if (state.token === undefined || null || "") {
        state.status = false;
        //let k = Object.keys(data)[0]
        state.message = Object?.values(data)[0];
        state.role = 0

      } else {
        state.status = true;
        state.message = "";
        sessionStorage.setItem("accessToken", state.token);
        document.cookie = "jwt=" + state.token + "";
        state.id = data?.id
        state.role = parseInt(data?.role)
	state.mail = data?.email
      }
    },
    [getAllUsersAsync.fulfilled]: (state, action) => {
      const data = action.payload;
      state.users = data;
    },
    [getUserAsync.fulfilled]: (state, action) => {
      const data = action.payload;
      state.profile = data;
    },
    [updateUserAsync.fulfilled]: (state, action) => {
      const data = action.payload;
      state.message = data?.message;
    },
    [deleteUserAsync.fulfilled]: (state, action) => {
      const data = action.payload;
      state.message = data;
    },
    [deleteUserAsync.rejected]: (state, action) => {
      const data = action.payload;
      state.message = Object.values(data)[0];
    },
    [activateUserAsync.fulfilled]: (state, action) => {
      
      const data = action.payload;
      state.message = data?.message;
      console.log(state.message )
    },
  },
});

export const { LOG_OUT, CLEAR_USR_MSG } = loginSlice.actions;

export default loginSlice.reducer;
