import { Row, Col, Table, Modal, Button } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { testResultCommentsAsync } from "../../features/tests/testSlice";
import { format } from "date-fns";

//print,pdf
import Pdf from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import PdfIcon from "../../global-images/PdfIcon";
import PrintIcon from "../../global-images/PrintIcon";
import logo from "../../global-images/spetra-logo.svg";

function AudioCommentsModal(props) {
    const dispatch = useDispatch();
    const t_r_c = (state) => state.test.resultsComments;
    const testResultComments = useSelector(t_r_c);

    //pdf.print
    const componentRef = useRef();
    const pageStyle = `@page {
        size: A4 portriat;
      }`;
    const handlePrint = useReactToPrint({
        // bodyClass: "viewReportContent img-fluid",
        content: () => componentRef.current,
        pageStyle: pageStyle,
        removeAfterPrint: true,
        documentTitle: "Sentence Perception Test",
        // onAfterPrint: () => setShowHeader(false),

    });

    const PdfOptions = {
        orientation: "portriat",
        // title: "VIEW REPORT",
        useCORS: true,
        // width:"100%",
        // putOnlyUsedFonts:true
        // unit: 'in',
        // format: [4,2],
    };


    useEffect(() => {
        dispatch(testResultCommentsAsync());
    }, []);
    return (
        <Modal
            show={props.show}
            onHide={props.hide}
            className="commentsPopup"
            scrollable={true}
        >
            <Modal.Header className="justify-content-end">
                <Pdf
                    targetRef={componentRef}
                    filename="audio_comments-report.pdf"
                    options={PdfOptions}
                    x={5} y={10} scale={0.8}
                >
                    {({ toPdf }) => (
                        <Button className="btn-pdf" onClick={() => {
                            toPdf();
                        }}>
                            <PdfIcon />
                            PDF
                        </Button>
                    )}
                </Pdf>
                <Button className="btn-print" onClick={() => {
                    // setShowHeader(true);
                    handlePrint();
                }}>
                    <PrintIcon />
                    Print
                </Button>
            </Modal.Header>
            <Modal.Body ref={componentRef}>
                <div className="commentsPopupTitle">
                    <Row>
                        <Table className="">
                            <tr>
                                <td className="align-top"> 
                                    <img src={logo} alt="spetra-logo" className="" />
                                </td>
                                <td>
                                    <table>
                                        <tr>
                                            <td>                                              
                                                 <div>Name: {props.currentAudioTest?.patient_name}</div>
                                                <div>Date of Test: {props.currentAudioTest?.date_of_test && format(new Date(props.currentAudioTest?.date_of_test), "dd MMM yyyy")} </div>                            
                                                <div>Test: {props.currentAudioTest?.test}</div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </Table>
                            {/* <Col xxl={3} xl={3} lg={3} md={3}>
                            <img src={logo} alt="spetra-logo" className="" />
                            </Col>
                        
                            <Col xxl={3} xl={3} lg={3} md={3}>
                                <h3>Name</h3>
                                <h2>{props.currentAudioTest?.patient_name}</h2>
                            </Col>
                            <Col xxl={3} xl={3} lg={3} md={3}>
                                <h3>Date of Test</h3>
                                <h2>{props.currentAudioTest?.date_of_test && format(new Date(props.currentAudioTest?.date_of_test), "dd MMM yyyy")}</h2>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6}>
                                <h3>Test</h3>
                                <h2>{props.currentAudioTest?.test}</h2>
                            </Col> */}
                    </Row>
                </div>

                {
                    (props.currentAudioTest?.session_id ? testResultComments?.filter(item => item.session_id == props.currentAudioTest?.session_id)[0]?.answer : []).map((audioTest, index) => {
                        return (
                            <div className="commentsListArea" key={index + "__" + audioTest?.audio_title}>
                                <Row>
                                    <Col md={12}>
                                        <h2>{audioTest?.audio_title}</h2>
                                    </Col>
                                    <Col xxl={3} xl={3} lg={3} md={3}>
                                        <h3>{audioTest?.is_responded ? (audioTest?.is_correct ? "Correct":"Wrong"):"None"}</h3>
                                    </Col>
                                    <Col xxl={9} xl={9} lg={9} md={9}>
                                        <h3>{audioTest?.comment}</h3>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }

            </Modal.Body>
        </Modal>
    );
}

export default AudioCommentsModal;