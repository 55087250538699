//calculate age from given input date
// export const setAge = () => {
//   let doB = document.getElementById("NameDob").value;
//   let age = new Date(doB);
//   let diff = Date.now() - age.getTime();
//   let ageDate = new Date(diff);
//   let calculatedAge = Math.floor(ageDate.getUTCFullYear() - 1970);
//   return calculatedAge;
// };

//maximum date today
export const maxDate = () => {
  let max = new Date().toISOString().split("T")[0];
  return max;
};

export const calculateAge = (ageInput) => {
  let age = new Date(ageInput);
  let diff = Date.now() - age.getTime();
  let ageDate = new Date(diff);
  let calculatedAge = Math.floor(ageDate.getUTCFullYear() - 1970);
  return calculatedAge;
};
//shuffle array
//https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const emailValidator = (email) => {
  if (!email) {
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    return "Incorrect email format";
  }
};
