const ToggleMenu = () => {
  return (
    <>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 170.1 170.1">
        <path
          d="M130.4,58.7c-0.6,1.8-1.6,3.2-3.6,3.6c-0.4,0.1-0.8,0.1-1.2,0.1c-19.5,0-38.9,0-58.4,0c-2.4,0-4.2-1.4-4.7-3.6
	c-0.5-2,0.6-4.2,2.5-5c0.8-0.3,1.7-0.4,2.5-0.5c12.5,0,25.1,0,37.6,0c6.5,0,13.1,0.1,19.6,0c2.8-0.1,4.8,0.9,5.8,3.6
	C130.4,57.5,130.4,58.1,130.4,58.7z M130.4,85.9c-0.4,0.7-0.7,1.5-1.3,2.2c-0.8,1-1.9,1.4-3.2,1.5c-0.4,0-0.8,0-1.2,0
	c-26.6,0-53.2,0-79.8,0c-0.8,0-1.5-0.1-2.3-0.3c-2-0.6-3.2-2.6-3-4.7c0.2-2.1,1.8-3.7,3.9-4c0.5-0.1,1-0.1,1.5-0.1
	c26.5,0,53.1,0,79.6,0c2.8,0,4.8,0.9,5.7,3.7C130.4,84.8,130.4,85.4,130.4,85.9z M130.4,113.2c-0.3,0.5-0.5,1.1-0.8,1.6
	c-0.9,1.3-2.2,2-3.8,2c-4,0-8,0-11.9,0c-9.5,0-19.1,0-28.6,0c-2.5,0-4.3-1.5-4.7-3.8c-0.4-2.7,1.6-5.1,4.3-5.2c1.5,0,3,0,4.4,0
	c11.8,0,23.7,0,35.5,0c3.2,0,4.3,0.7,5.5,3.7C130.4,112,130.4,112.6,130.4,113.2z"
        />
      </svg>
    </>
  );
};

export default ToggleMenu;
